import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProject } from "../../../redux/packageSlice";
import { TextField, FormControl, InputLabel, Select, MenuItem, Box, Typography } from '@mui/material';
import { FaApple, FaGoogle, FaFacebook, FaTwitter, FaAmazon } from "react-icons/fa";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
  // console.log('Projet sélectionné :', projectData);

  const [selectedFontTitle, setSelectedFontTitle] = useState(projectData.fontTitle || "");
  const [selectedTitle, setSelectedTitle] = useState(projectData.title || "");
  const [selectedSlogan, setSelectedSlogan] = useState(projectData.slogan || "");
  const [selectedFontSlogan, setSelectedFontSlogan] = useState(projectData.fontSlogan || "");
  const [selectedColorForeground, setSelectedColorForeground] = useState(projectData.colorForeground || "");
  const [selectedColorBackground, setSelectedColorBackground] = useState(projectData.colorBackground || "");

  const [selectedIcon, setSelectedIcon] = useState(projectData.icon || "");

  useEffect(() => {
    setSelectedTitle(projectData.title || '');
    setSelectedSlogan(projectData.slogan || '');
    setSelectedFontTitle(projectData.fontTitle || '');
    setSelectedFontSlogan(projectData.fontSlogan || '');
    setSelectedIcon(projectData.icon || '');
    setSelectedColorForeground(projectData.colorForeground || '');
    setSelectedColorBackground(projectData.colorBackground || '');
}, [projectData]);


  const icons = [
    { name: "fa-apple", component: <FaApple /> },
    { name: "fa-google", component: <FaGoogle /> },
    { name: "fa-facebook", component: <FaFacebook /> },
    { name: "fa-twitter", component: <FaTwitter /> },
    { name: "fa-amazon", component: <FaAmazon /> },
  ];

  const colors = [
    { name: "White", code: "#f0f0f0" },       
    { name: "Red", code: "#FF5733" },         
    { name: "Green", code: "#33FF57" },        
    { name: "Blue", code: "#3357FF" },         
    { name: "Pink", code: "#FF33A1" },     
    { name: "Yellow", code: "#FFD700" },     
    { name: "Purple", code: "#800080" },     
    { name: "Cyan", code: "#00FFFF" },       
    { name: "Orange", code: "#FFA500" },      
    { name: "Dark Green", code: "#008000" },   
    { name: "Dark Blue", code: "#0000FF" },    
];


  const handleForegroundColorClick = (color) => {
    setSelectedColorForeground(color.code);
   
};

const handleBackgroundColorClick = (color) => {
    setSelectedColorBackground(color.code);

};




  const handleIconClick = (icon) => {
    setSelectedIcon(icon.name);
    console.log(icon.name)
   
};
const handleTitleChange = (e) => {
  setSelectedTitle(e.target.value);
};

const handleSloganChange = (e) => {
  setSelectedSlogan(e.target.value);
};

const handleFontTitleChange = (e) => {
  setSelectedFontTitle(e.target.value);
};

const handleFontSloganChange = (e) => {
  setSelectedFontSlogan(e.target.value);
};
const handleValidate = () => {
  dispatch(setProject(
    { projectId,
      data: {
        logo: {
          logoToCreate: {
            fontTitle: selectedFontTitle,
            title: selectedTitle,
            slogan: selectedSlogan,
            fontSlogan: selectedFontSlogan,
            colorForeground: selectedColorForeground,
            colorBackground: selectedColorBackground,
            icon: selectedIcon,            }
        }
    }
  })) 
   
};


  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
        <div className="d-flex mb-4" >
                                                    <Link to={`/package-com4N/${projectId}`}
                                                            style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                            className="d-flex justify-content-around">
                                                        <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                                    </Link>
                                                    <Link to="#"
                                                            style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                            className="d-flex justify-content-center">
                                        
                                                        <Typography component="span" className="d-flex justify-content-center text-center"> 5/15</Typography>
                                                    </Link>
                                                    <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                </div>
          
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">
              Sélectionnez une icône, indiquez un nom et un slogan (optionnel)
            </h3>
          </div>
          <div className="row mt-5">
            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">Catalogue d’icônes (avec les prix)</h5>
                  <div className="d-flex justify-content-around">
                    {icons.map((icon) => (
                      <div
                      key={icon.name}
                      onClick={() => handleIconClick(icon)}
                      style={{
                          cursor: "pointer",
                          border: selectedIcon === icon.name ? "2px solid green" : "none",
                      }}
                     >
                      {icon.component}
                  </div>
                  
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <h5 className="card-title">Couleur de premier plan</h5>
                <div className="d-flex justify-content-around">
                  {colors.map((color) => (
                    <button
                      key={color.name}
                      className="color-swatch"
                      style={{
                        backgroundColor: color.code,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                          selectedColorForeground === color.code
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleForegroundColorClick(color)}
                    />
                  ))}
                </div>
                {/* <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedColorForeground || "Aucune"}</strong>
                </p> */}

              
             </div>
            </div>

            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <h5 className="card-title">Couleur d'arrière-plan</h5>
                <div className="d-flex justify-content-around">
                  {colors.map((color) => (
                    <button
                      key={color.name}
                      className="color-swatch"
                      style={{
                        backgroundColor: color.code,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                          selectedColorBackground === color.code
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleBackgroundColorClick(color)}
                    />
                  ))}
                </div>
                {/* <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedColorBackground || "Aucune"}</strong>
                </p> */}
              </div>
            </div>

            <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label="Nom"
          placeholder="Entrez un nom"
          variant="outlined"
          value={selectedTitle}
          onChange={handleTitleChange}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label="Slogan (optionnel)"
          placeholder="Entrez un slogan"
          variant="outlined"
          value={selectedSlogan}
          onChange={handleSloganChange}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="font-title-select-label">Police de titre</InputLabel>
          <Select
            labelId="font-title-select-label"
            id="font-title-select"
            value={selectedFontTitle}
            onChange={handleFontTitleChange}
            label="Police de titre"
          >
            <MenuItem value="">-- Sélectionnez une police --</MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Mistrully">Mistrully</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Tahoma">Tahoma</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {selectedFontTitle && (
        <Box sx={{ mb: 4 }}>
          <Box 
            sx={{ 
              p: 4, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              fontFamily: selectedFontTitle 
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: '600', mb: 3 }} style={{ fontFamily: selectedFontTitle }}>
              Donnez un style unique à votre entreprise !
            </Typography>
          </Box>
        </Box>
      )}

      <Box sx={{ mb: 4 }}>
        <FormControl fullWidth>
          <InputLabel id="font-slogan-select-label">Police de slogan</InputLabel>
          <Select
            labelId="font-slogan-select-label"
            id="font-slogan-select"
            value={selectedFontSlogan}
            onChange={handleFontSloganChange}
            label="Police de slogan"
          >
            <MenuItem value="">-- Sélectionnez une police --</MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Mistrully">Mistrully</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Tahoma">Tahoma</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {selectedFontSlogan && (
        <Box sx={{ mb: 4 }}>
          <Box 
            sx={{ 
              p: 4, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              fontFamily: selectedFontSlogan 
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: '600', mb: 3 }} style={{ fontFamily: selectedFontSlogan }}>
              Donnez un style unique à votre entreprise !
            </Typography>
          </Box>
        </Box>
      )}  
                            
                        <div className="col-12 mb-4">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4N2/${projectId}`} className="d-block" onClick={handleValidate}>
                                    Validez
                                </Link>
                            </button>
                        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
