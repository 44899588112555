import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { getWebpackageOrders } from '../../../../../api/Services/webPackageService';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import CustomButton from '../../../../../layouts/CustomButton';

const CreateProjectModal = ({
  open,
  handleClose,
  createPrjectWithoutProjectId,
  initAccountancyProjectConfig,
  fetchProjects
}) => {
  const [customProjectName, setCustomProjectName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projectsWebpackage, setProjectsWebpackage] = useState([]);
  const [loading,setLoading]=useState(false);
 useEffect(() => {
    const fetchProjectsWebPro = async () => {
      try {
        const response = await getWebpackageOrders();
        setProjectsWebpackage(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectsWebPro();

  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (customProjectName) {
        await createPrjectWithoutProjectId(customProjectName);
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Projet créé avec succès.',
        });
        await fetchProjects(null);
    } else if (selectedProjectId) {
        const selectedProject = projectsWebpackage.find(
          (project) => project.projectId === selectedProjectId
        );
        await initAccountancyProjectConfig(selectedProject.projectName, selectedProjectId);
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Projet initialisé avec succès.',
        });
        await fetchProjects(null);
    } else {
        Swal.fire({
          icon: 'warning',
          title: 'Attention',
          text: 'Veuillez renseigner un projet ou en sélectionner un.',
        });
      }
      handleClose();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue. Veuillez réessayer.',
      });
    }
  };
  

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '57%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 6,
          borderRadius: '10px',
        }}
      >
        <h4 className="d-flex flex-column align-items-center mb-4">
          Créer un nouveau projet
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-md-12">
              <input
                type="text"
                className="form-control"
                placeholder="Renseigner un nom de projet"
                value={customProjectName}
                onChange={(e) => setCustomProjectName(e.target.value)}
              />
            </div>
          </div>
          <h4 className="d-flex flex-column align-items-center mb-4">ou</h4>
          <div className="row mb-4">
            <div className="col-md-12">
              <select
                className="form-control"
                value={selectedProjectId}
                onChange={(e) => setSelectedProjectId(e.target.value)}
              >
                <option value="" disabled hidden>
                  Choisissez un projet Pharos WebPro ou Legal
                </option>
                {projectsWebpackage.map((project, index) => (
                  <option key={index} value={project.projectId}>
                    {project.projectName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center mb-4">
            <div className="button-group d-inline-flex col-4 align-items-center justify-content-center ">
              <button
                type="button"
                className="text-reset me-3 card_dash"
                onClick={handleClose}
              >
                Annuler
              </button>
              <CustomButton  onClick={handleSubmit}
              disabled={!customProjectName && !selectedProjectId}
              style={{ width: '178px' }}
              sx={{
                
                '&:disabled': {
                  backgroundColor: '#b0b0b0',
                  color: '#fff',
                  cursor: 'not-allowed',
                  opacity: 0.6,
                 
                },
              
              }}>
                Ajouter
            </CustomButton >
              
             

            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateProjectModal;
