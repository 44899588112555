import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {


  return (
    <>
      <section className="candidates-profile bg-color pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80">
        <div className="container">
          <div className="text-center"></div>
          <div className="row mt-5">
            <div className="col-12 mb-4">
              <div className="card">
                <div className="card-header bg-success text-white text-center">
                  <h5 className="mb-0" style={{ color: 'white' }}>Paiement réussi</h5>
                </div>
                <div className="card-body d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                  <span style={{ fontSize: '1.2rem' }}>Votre paiement a bien été effectué. Retrouvez votre achat sur votre compte Pharos pour compléter et valider votre dossier.</span>
                </div>
              </div>
            </div>

            <div className="col-12 text-center mt-20">
                <CustomButton  to="/" >
                 Retour à la page d’accueil
                </CustomButton >
              
             
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
