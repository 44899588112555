import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'; 
import { getWebpackageOrder, upsertWebpackageOrder } from "../../../api/Services/webPackageService";
import Swal from "sweetalert2";
import {Typography} from "@mui/material";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const navigate=useNavigate();
  const [project,setProject]=useState();
  const dispatch = useDispatch();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
  console.log("projectData",projectData);

  const {
    domainName,
    mailBoxEmailAddresses,
    address,
    phone,
    hours,
    socialNetworks,
    services,
    fontFamily,
    backgroundColor,
    textColor,
    icon,
    colorForeground,
    colorBackground,
    title,
    slogan,
    fontTitle,
    gallery,
    fontSlogan,
    s3ImageKey,
    imgMagasin,
    companyName

  } = projectData;  

  const siteModelImage = "/assets/images/blog/njt.png";

  useEffect (() => {
    const fetchProjectData = async () => {
      try {
        const response = await getWebpackageOrder(projectId);
        console.log("GraphQL response:", response);
        const projectData = response.data.getWebpackageOrder; 
        setProject(projectData);
        console.log("dfrgrt", projectData.projectName);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
    

    fetchProjectData();
  }, [projectId, dispatch]);


  const handleSaveChanges = async () => {
    try {
      const formatHours = (hours) => {
        const formattedHours = {};
        Object.entries(hours).forEach(([day, values]) => {
          if (values.every(val => val === "")) {
            formattedHours[day] = [];
          } else {
            const formattedValues = [];
            if (values[0] && values[1]) {
              formattedValues.push(`${values[0]}-${values[1]}`);
            }
            if (values[2] && values[3]) {
              formattedValues.push(`${values[2]}-${values[3]}`);
            }
            formattedHours[day] = formattedValues;
          }
        });
        return formattedHours;
      };
  
      const formattedHours = formatHours(hours);
  
      const input = {
        input: {
          projectId: projectId,
          projectName: project.projectName,
          logo: {
            logoType: "TO_CREATE",
            logoToCreate: {
              colorBackground: projectData.colorBackground,
              colorForeground: projectData.colorForeground,
              fontSlogan: projectData.fontSlogan,
              fontTitle: projectData.fontTitle,
              icon: projectData.icon,
              s3ImageKey: projectData.s3ImageKey,
              slogan: projectData.slogan,
              title: projectData.title,
            },
          },
          domainName: projectData.domainName,
          mailBoxEmailAddresses: projectData.mailBoxEmailAddresses,
          website: {
            globalDetails: {
              address: projectData.address,
              backgroundColor: projectData.backgroundColor,
              companyName: projectData.companyName,
              textColor: projectData.textColor,
              email: `contact@${projectData.domainName}`,
              fontFamily: projectData.fontFamily,
              language: "FR",
              phoneNumber: projectData.phone,
              socialNetworks: projectData.socialNetworks,
              days: formattedHours,
            },
            header: {
              s3Key: projectData.s3ImageKey,
              subtitle: "Réaliser",
              title: "title",
            },
            navbar: {
              backgroundColor: "cyan",
              textColor: "cyan",
              items: ["about", "gallery", "services", "contact"],
            },
            services: projectData.services,
            gallery: projectData.gallery,
            about: {
              paragraph: projectData.paragraph,
              s3Key: projectData.imgMagasin,
              why: ["Reason 1", "Reason 2"],
            },
            footer: {
              backgroundColor: "cyan",
              textColor: "cyan",
            },
          },
        },
      };
  
      console.log("Input sent to API:", {input});
  
      const response = await upsertWebpackageOrder({input}); 
  
      if (!response.data) {
        throw new Error("No data returned from the API");
      }
     const resultString = response.data.upsertWebpackageOrder;
      const statusMatch = resultString.match(/status=(\w+)/);
      const messageMatch = resultString.match(/message=([^}]+)/);
  
      const status = statusMatch ? statusMatch[1] : null;
      const message = messageMatch ? messageMatch[1] : "No message provided";
  
      if (status === "SUBMITTED") {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: message,
        });
        navigate(`/info-projet/${projectId}`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: message,
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: error.message || "Une erreur s'est produite lors de l'envoi des données.",
      });
    }
  };
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '135px', paddingBottom: '30px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >CRÉATION PACKAGE COMMERCIAL</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
        <div className="d-flex mb-3">
                                                                  <Link to={`/package-com11/${projectId}`}
                                                                          style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                          className="d-flex justify-content-around">
                                                                      <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                                                  </Link>
                                                                  <Link to="#"
                                                                          style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                                          className="d-flex justify-content-center">
                                                      
                                                                      <Typography component="span" className="d-flex justify-content-center text-center">15/15</Typography>
                                                                  </Link>
                                                                  <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                               </div>
        
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">Visualisez votre site vitrine</h3>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="card mb-4">
                <img src={siteModelImage} className="card-img-top" alt="Modèle du site" />
                <div className="card-body">
                  <h5 className="card-title text-center">Modèle du site</h5>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <button
                type="submit"
                className="btn-eleven fw-500 tran3s d-block mt-20"
                onClick={handleSaveChanges}
              >
                Enregistrez les modifications
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to={`/package-com1/${projectId}`} className="d-block">
                  Recommencez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
