import React, { useEffect, useState } from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { createPrjectWithoutProjectId, getAccountancyProjectConfigs, initAccountancyProjectConfig } from '../../../../api/Services/invoiceService';
import PaginationComponent from '../../../pagination/pagination';
import { setProjet } from '../../../../redux/invoice';
import { useDispatch } from 'react-redux';
import { Modal, Box } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import CreateProjectModal from './info-generale/CreateProjectModal ';

const FacturationIndex = ({ setIsOpenSidebar }) => {
  const dispatch = useDispatch();

  const [projects, setProjects] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const limit = 10;

  const fetchProjects = async (token) => {
    setIsLoading(true); 
    try {
      const response = await getAccountancyProjectConfigs(100, token);

      if (!response) {
        console.error('Received null response from the server.');
        setProjects([]);
        return;
      }

      if (response.items && Array.isArray(response.items)) {
        setProjects(response.items);
        setNextToken(response.nextToken);
        setTotalCount(response.items.length);

        response.items.forEach((project) => {
          dispatch(setProjet({ id: project.projectId, ...project }));
        });
      } else {
        console.error('Invalid response structure:', response);
        setProjects([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des projets :', error);
      setProjects([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects(nextToken);
  }, [nextToken]);

  const handleRefreshProjects = async () => {
    await fetchProjects(null);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const tokenForPage = (page - 1) * limit;
    fetchProjects(tokenForPage);
  };

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title text-center h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
            <div className="col-12">
              {isLoading ? ( 
                <div className="d-flex justify-content-center">
                  <ClipLoader height="50" width="50" color="#19AF78" />
                </div>
              ) : (
                <>
                  {projects.map((project) => (
                     <Link key={project.projectId} to={`/ma-facturation/${project.projectId}`} className="d-block">
                                    <button 
                                         
                                        type="submit" 
                                        className="btn-eleven fw-500 tran3s d-block mt-20"
                                    >
                                        {project.projectName}
                                    </button>
                                </Link>
                  
                  ))}
                  <button
                    type="button"
                    className="btn-eleven fw-500 tran3s d-block mt-40"
                  >
                    <Link onClick={handleOpen}>Créer un nouveau projet</Link>
                  </button>
                </>
              )}
              <CreateProjectModal
                open={open}
                handleClose={handleClose}
                createPrjectWithoutProjectId={createPrjectWithoutProjectId}
                initAccountancyProjectConfig={initAccountancyProjectConfig}
                fetchProjects={handleRefreshProjects}
              />
            </div>
          </div>
          {/* <PaginationComponent
            count={Math.ceil(totalCount / limit)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default FacturationIndex;
