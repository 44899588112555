import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchDownloafile } from "../../../api/Services/webPackageService";
import { useSelector } from "react-redux";
import { TextField, Button, Grid, Typography, Container, Box } from "@mui/material";

import "@fortawesome/fontawesome-free/css/all.min.css";
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {
    const { projectId } = useParams();
    
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
    const {
        s3ImageKey,
        title,
        slogan,
        fontTitle,
        fontSlogan,
        colorForeground,
        colorBackground,
        icon,
    } = projectData;
    console.log("colorBackground",colorBackground);
    console.log("colorForeground",colorForeground);

    const [logoUrl, setLogoUrl] = useState("");

    useEffect(() => {
        const getLogoUrl = async () => {
            if (s3ImageKey) {
                try {
                    const response = await fetchDownloafile(s3ImageKey);
                    setLogoUrl(response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url);
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
                }
            }
        };

        getLogoUrl();
    }, [s3ImageKey]);

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                <div className="d-flex mb-1">
                                            <Link to={`/package-com4Y/${projectId}`}
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center">6/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                         </div>
                  
                    <div className="title-one text-center mb-1 lg-mb-2

 " style={{marginBottom:" 46px",marginTop: "44px"}} >
                        <h3 className="fw-600">Validez votre logo</h3>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <div className="card p-4 border rounded shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">LOGO Visualisation</h5>
                                    <p className="card-text">
                                        Voici un aperçu de votre logo. Vérifiez les détails et confirmez s'il vous plaît.
                                    </p>
                                    <div
                                        className="logo-preview mt-3 d-flex justify-content-center align-items-center"
                                        
                                    >
                                        {logoUrl ? (
                                            <img
                                                src={logoUrl}
                                                alt="Logo Preview"
                                                className="img-fluid"
                                                style={{ maxWidth: "300px", maxHeight: "200px" }}
                                            />
                                        ) : (
                                            <div
                                                className="logo-container"
                                                
                                            >
                                                <div className="logo-content" >
                                                    <i className={`fab ${icon}`} style={{ fontSize: "100px", marginBottom: "20px" }}></i>
                                                    <h1 >{title}</h1>
                                                    <p>{slogan}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-20">
                            <CustomButton to={`/package-com6/${projectId}`}>
                            Validez
                            </CustomButton >
                            
                            </div>

                                        <div className="col-12 text-center mt-20">
                            <CustomButton to={`/package-com4N1/${projectId}`}>
                            Recommencez
                            </CustomButton >
                            
                            </div>
                            <div className="col-12 text-center mt-20 mb-4">
                            <CustomButton to={`/package-com4N1V1/${projectId}`}>
                            Pas convaincu ? On vous met à disposition un designer ?
                            </CustomButton >
                            
                            </div>
                        
                    </div>
                </div>
                <style>
                    {`
    

      .logo-container {
          position: relative; 
          display: table;
          width: 100%;
          height: 100%;
          text-align: center;
          color: ${colorForeground};
          background-color:${colorBackground} ;
      }

      .logo-container i {
          font-size: 450px;
          margin-bottom: 20px;
      }

      .logo-container h1 {
          font-size: 150px;
          margin: 0;
          margin-bottom: 10px;
          font-family: ${fontTitle};
      }

      .logo-container p {
          font-size: 80px;
          margin: 0;
          font-family: ${fontSlogan};
      }

      .logo-container::after {
          content: "Pharos WebPro"; 
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg); 
          font-size: 100px;
          color: rgba(0, 0, 0, 0.1); 
          font-family: Arial, sans-serif;
          letter-spacing: 5px;
          font-weight: bold;
          pointer-events: none; 
          z-index: 1;
      }

    
                    `}
                </style>
            </section>
        </>
    );
};

export default CandidateV3Area;
