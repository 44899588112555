import React from 'react';
import DashboardHeader from '../header';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Facturation = ({setIsOpenSidebar}) => {
   const {projectId}= useParams();
   const projet = useSelector((state) => state.invoice.projets[projectId]); 
   console.log("project",projet);

  return ( 
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className="main-title text-center h2-titel">Pharos Compta (Ma facturation) &gt; {' '}{projet.projectName}

        </h2>
        
        <div className="bg-white card-box border-20">
            <div className="row justify-content-center">
                <h4 className="text-center">Information générale</h4>
                
                <div className="logo-container col-6 d-flex align-items-center justify-content-center">
                  <div className="logo-frame">
                      <img src="/assets/images/logo/logo-pharos.png" alt="Exemple de logo" className="logo-image" />
                  </div>
                </div>  
                <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
                    <Link to={`/ma-facturation-information-generale/${projectId}`} className="dash-btn-two tran3s me-3 card_dash ma_facturation_card text-center p-3">
                        Modifier
                    </Link>
                    </div>
               
            </div>
        </div>


        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes factures</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link  to={`/ma-facturation-factures/${projectId}`} className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card p-3">Historique des factures</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to={`/ma-facturation-factures-create/${projectId}`} className="dash-btn-two tran3s me-3 card_dash ma_facturation_card p-3">Créer une facture</Link>          
            </div>               
          </div>
        </div>

        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes devis</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to={`/ma-facturation-devis/${projectId}`} className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card p-3">Historique des devis</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to={`/ma-facturation-devis-create/${projectId}`} className="dash-btn-two tran3s me-3 card_dash ma_facturation_card p-3">Créer un devis</Link>          
            </div>               
          </div>
        </div>

        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes clients / fournisseurs</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-clients-fournisseurs" className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card p-3">Liste des clients / fournisseurs</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-clients-fournisseurs" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card p-3">Ajouter un nouveau client / fournisseur</Link>          
            </div>               
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturation;
