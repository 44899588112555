import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // Importation de la locale française

// Enregistrement de la langue française
registerLocale("fr", fr);

const CandidateV3Area = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "169px", paddingBottom: "5px" }}
          >
            <div className="navigation-step">
              <Link to="/rendez-vous5" className="text-decoration-none">
                <span className="precedent-text">{"< Précédent"}</span>
              </Link>
              <span>5/5</span>
              <Link to="/rendez-vous7" className="text-decoration-none">
                <span className="precedent-text">{"Suivant >"}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section
        className="candidates-profile pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="container">
          <h2 className="text-center" >
            Choisissez la date et l’heure de rendez-vous
          </h2>
          <div className="row mt-5 justify-content-center">
            <div className="col-12  text-center">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  locale="fr" 
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="form-control custom-datepicker"
                  placeholderText="Sélectionnez une date et une heure"
                  style={{ height: "50px", fontSize: "18px" }} 

                />
             <div className="col-12 text-center mt-4">
                          <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                            <Link to="/rendez-vous7" className="text-white">
                            Valider →
                            </Link>
                          </button>
                        </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
