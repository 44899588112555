import React from 'react';
import { FaEllipsisV ,FaFile} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ActionMenu = ({ item, projectId, onRename, onCopy, onMove, onShare, onDownload, onDelete }) => {
  return (
    <div className="action-dots float-end" style={{ top: '0', right: '0' }} onClick={(e) => e.stopPropagation()}>
      <button
        className="action-btn dropdown-toggle position-absolute"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ top: '0px', right: '0px' }}
      >
        <FaEllipsisV />
      </button>
      <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onRename(item)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/pencil.png" alt="Renommer" className="lazy-img" /> Renommer
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onCopy(projectId, item.id)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/copy.png" alt="Créer une copie" className="lazy-img" /> Créer une copie
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onMove(item.id)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/move.png" alt="Déplacer" className="lazy-img" /> Déplacer
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onShare(item.id)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/share.png" alt="Partager" className="lazy-img" /> Partager
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onDownload(projectId, item.id)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/download.png" alt="Télécharger" className="lazy-img" /> Télécharger
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="#" onClick={() => onDelete(projectId, item.id)}>
            <img src="https://img.icons8.com/ios-glyphs/24/000000/delete.png" alt="Supprimer" className="lazy-img" /> Supprimer
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ActionMenu;