import React, { useState } from "react";
import EmployAside from "../aside";
import IndexGed from "./ged-index";

const EmployDashboardSubmitJobPage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className="main-page-wrapper">
    <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />

    <IndexGed setIsOpenSidebar={setIsOpenSidebar} />
    </div>    
  );
};

export default EmployDashboardSubmitJobPage;
