"use client"
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { telechargerUrl } from '../../../../../api/Services/invoiceService';

import { listAccountancyEstimates } from '../../../../../api/Services/EstimatesService';
import { ClipLoader } from 'react-spinners';
import { Alert } from 'react-bootstrap';


const FacturesIndex = ({setIsOpenSidebar}) => {
const {projectId}= useParams();
 const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
const [estimates, setEstimates] = useState([]);
const [error, setError] = useState(null);
const [isLoading, setIsLoading] = useState(true);
   const projet = useSelector((state) => state.invoice.projets[projectId]); 
   console.log("project",projet);

   useEffect(() => {
    const fetchEstimates = async () => {
      try {
        setIsLoading(true);
        const result = await listAccountancyEstimates(projectId, 10, null);
        console.log(result);
  
        if (result.items && Array.isArray(result.items)) {
          const estimatesWithUrls = await Promise.all(
            result.items.map(async (invoice) => {
              const urlInvoice = await telechargerUrl(invoice.pdfS3Key);
              console.log("urlInvoice:", urlInvoice);
              return { ...invoice, urlInvoice };
            })
          );
  
          setEstimates(estimatesWithUrls); 
        } else {
          setEstimates([]); 
        }
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des factures.');
      } finally {
        setIsLoading(false);
      }
    };
    
  
    if (projectId) fetchEstimates();
  }, [projectId]);

  const handlePdfClick = async (pdfS3Key) => {
    try {
      const response = await telechargerUrl(pdfS3Key);
      if (response.url) {
        setPdfUrl(response.url);
        setShowPdf(true);
      } else {
        console.error("URL du PDF non valide");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF:", error);
    }
  };
  const handleDownloadClick = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";
      link.click();
    }
  };
  
 
  

 
  
  return (
    <div className="dashboard-body">
    <div className="position-relative">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
  
      <h2 className="main-title h2-titel  text-center">Ma facturation &gt; {''}{projet.projectName}{' '} &gt;Liste des devis</h2>
  
      <div className="bg-white card-box border-20">
        
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader height="50" width="50" color="#007bff" />
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : estimates.length > 0 ? (
          <div className="table-responsive mb-5" id="liste-table">
            <table className="table job-alert-table">
              <thead>
                <tr>
                <th scope="col" className='text-center'>Devis</th>
                  <th scope="col" className='text-center'>Client</th>
                  <th scope="col" className='text-center'>Status</th>
                  <th scope="col" className='text-center'>Date</th>
                  <th scope="col" className='text-center'>Montant</th> 
                  <th scope="col" className='text-center'>Devis PDF</th>
                </tr>
              </thead>
              <tbody className="border-0">
              {estimates.map((estimate, index) => (
                <tr key={index}>
                  <td className="text-center">{estimate.numberId || '--'}</td>
                  <td className="text-center">{estimate.accountancyClient?.companyName || '--'}</td>
                  <td className="text-center">
                      {estimate.status === "VALIDATED" ? "Validé" : "Brouillon"}
                    </td>                  
                     <td className="text-center">{estimate.creationDate || '--'}</td>
                  <td className="text-center">
                    {estimate.products?.reduce((total, product) => {
                      return total + product.quantity * product.unitPrice;
                    }, 0).toFixed(2) || '0.00'}{' '}
                    {estimate.products?.[0]?.currency || '€'}
                  </td>
                  <td className="text-center">
                    <span onClick={() => handlePdfClick(estimate.pdfS3Key)}>
                      {estimate.status === "VALIDATED" ? (
                        <i className="fas fa-file-alt text-success" title="Validé"></i>
                      ): (
                        <i className="fas fa-file-alt text-danger" title="Autre"></i>
                      )}
                    </span>
                  </td>
                </tr>
              ))}

              </tbody>
            </table>
          </div>
          
        ) : (
          <div className="text-center mt-4">
           <Alert type="info" > Aucune donnée disponible pour le moment. </Alert>
              
          
          </div>
        )}
        <div className="d-flex justify-content-between mb-4">
       <Link
            to={`/ma-facturation-factures-create/${projet.projectId}`}
            className="dash-btn-two tran3s"
          >
            Créer un devis
          </Link>
        </div>
  
  
        {showPdf && pdfUrl && (
          <div className="pdf-viewer">
            <div className="viewer-header">
              <button onClick={() => setShowPdf(false)} className="close-button">
                x
              </button>
              <button onClick={handleDownloadClick} className="download-button">
                Télécharger
              </button>
            </div>
            <iframe
              src={pdfUrl}
              title="PDF Viewer"
              width="100%"
              height="100%"
              type="application/pdf"
            ></iframe>
          </div>
        )}
      </div>
      <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
      `}</style>
    </div>
  </div>
  
    
  );
};

export default FacturesIndex;