import axiosInstance from '../axiosConfig';

// list factures
export const listAccountancyEstimates = async (projectId,limit,nextToken) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: 
          `
            query listAccountancyEstimates($projectId: String!, $limit: Int, $nextToken: String){
    listAccountancyEstimates(projectId: $projectId, limit: $limit, nextToken: $nextToken){
        items {
            logoS3Key
            title
            identifier
            numberId
            creationDate
            accountancyIssuer {
                companyName
                socialStatus
                siret
                contactName
                address
                phoneNumber
                email
                vatNumber
                nafNumber
                capitalStockAmount
                capitalStockCurrency
                iban
                bicSwift
            }
            accountancyClient {
                companyName
                siret
                contactName
                address
                phoneNumber
                email
            }
            products {
                reference
                designation
                quantity
                unitPrice
                vatInPercentage
                amountExcludingTax
                currency
                image
            }
            status
            pdfS3Key
        }
        nextToken
    }
}  
          `,
          variables: {
            projectId,limit,nextToken
          },
      });
      return response.data.data.listAccountancyEstimates;
    } catch (error) {
      console.error('Erreur lors de recccurepartion de liste facture :', error);
      throw error;
    }
  };