// import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// const FicheDirigeant = ({ partnerData, onChange }) => {
//   const [partners, setPartners] = useState([]);

//   useEffect(() => {
//     if (partnerData) {
//       setPartners(partnerData);
//     }
//   }, [partnerData]);

//   const handlePartnerChange = (e, partnerId) => {
//     const { id, value } = e.target;
//     const updatedPartners = partners.map((partner) => {
//       if (partner.partnerId === partnerId) {
//         if (id.startsWith('spouse')) {
//           const spouseField = id.replace('spouse', '');
//           const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);
  
//           console.log('fieldKey:', fieldKey);
  
//           return {
//             ...partner,
//             spouseIdentity: {
//               ...(partner.spouseIdentity || {}),
//               [fieldKey]: value,
//             },
//           };
//         }
//         return { ...partner, [id]: value };
//       }
//       return partner;
//     });
  
//     setPartners(updatedPartners);
//     onChange(updatedPartners);
//     console.log("updatedPartners",updatedPartners);
//   };
  

//   const handleNewPartnerChange = (e) => {
//     const { id, value } = e.target;
//     const newPartner = partners.find((p) => p.partnerId === 'newPartner') || {
//       partnerId: 'newPartner',
//       isMainLeader: true,
//       spouseIdentity: {},
//     };

//     const updatedPartners = [
//       ...partners.filter((p) => p.partnerId !== 'newPartner'),
//       { ...newPartner, [id]: value },
//     ];

//     setPartners(updatedPartners);
//     onChange(updatedPartners);
//   };

//   const renderSpouseFields = (leader) => {
//     return [
//       { label: 'Prénom', id: 'spouseFirstNames', placeholder: 'Prénom' },
//       { label: 'Nom', id: 'spouseName', placeholder: 'Nom' },
//       { label: 'Nom de jeune fille', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
//       { label: 'Lieu de naissance', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
//       { label: 'Date de naissance', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
//       { label: 'Nationalité', id: 'spouseNationality', placeholder: 'Nationalité' },
//     ].map((field) => (
//       <div key={field.id} className="mb-4">
//         <TextField
//           label={field.label}
//           fullWidth
//           variant="outlined"
//           value={leader.spouseIdentity?.[field.id.replace('spouse', '').charAt(0).toLowerCase() + field.id.replace('spouse', '').slice(1)] || ''}
//           placeholder={field.placeholder}
//           onChange={(e) => handlePartnerChange(e, leader.partnerId)}
//           id={field.id}
//           type={field.type || 'text'}
//           InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
//         />
//       </div>
//     ));
//   };
  

//   return (
//     <div className="row">
//       <div style={{ cursor: "pointer" }}>
//         <Accordion>
//           <AccordionSummary>
//             <h4>
//               <ExpandMoreIcon style={{ marginRight: "8px" }} />
//               Fiche dirigeant
//             </h4>
//           </AccordionSummary>
//           <AccordionDetails>
//             <div className="row">
//               {partners.some((partner) => partner.isMainLeader) ? (
//                 partners
//                   .filter((partner) => partner.isMainLeader)
//                   .map((leader) => (
//                     <React.Fragment key={leader.partnerId}>
//                       <div className="col-md-6">
//                         <Accordion style={{ boxShadow: 'none', border: 'none' }}>
//                           <AccordionSummary>
//                             <h4>
//                               <ExpandMoreIcon style={{ marginRight: '8px' }} /> Dirigeant
//                             </h4>
//                           </AccordionSummary>
//                           <AccordionDetails>
//                             {[
//                               { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
//                               { label: 'Nom', id: 'name', placeholder: 'Nom' },
//                               { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
//                               { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
//                               { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
//                               { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
//                               { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
//                             ].map((field) => (
//                               <div key={field.id} className="mb-4">
//                                 <TextField
//                                   label={field.label}
//                                   fullWidth
//                                   variant="outlined"
//                                   value={leader[field.id] || ''}
//                                   placeholder={field.placeholder}
//                                   onChange={(e) => handlePartnerChange(e, leader.partnerId)}
//                                   id={field.id}
//                                   type={field.type || 'text'}
//                                   InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
//                                 />
//                               </div>
//                             ))}
//                           </AccordionDetails>
//                         </Accordion>
//                       </div>

//                       <div className="col-md-6">
//                         <Accordion style={{ boxShadow: 'none', border: 'none' }}>
//                           <AccordionSummary>
//                             <h4>
//                               <ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)
//                             </h4>
//                           </AccordionSummary>
//                           <AccordionDetails>
//                             {renderSpouseFields(leader)}
//                           </AccordionDetails>
//                         </Accordion>
//                       </div>
//                     </React.Fragment>
//                   ))
//               ) : (
//                 <React.Fragment>
//                   <div className="col-md-6">
//                     <Accordion style={{ boxShadow: 'none', border: 'none' }}>
//                       <AccordionSummary>
//                         <h4>
//                           <ExpandMoreIcon style={{ marginRight: '8px' }} /> Nouveau Dirigeant
//                         </h4>
//                       </AccordionSummary>
                      
//                       <AccordionDetails>
//                         {renderSpouseFields({ spouseIdentity: {} })}
//                       </AccordionDetails>
//                     </Accordion>
                    
//                   </div>
//                 </React.Fragment>
//               )}
//             </div>
//           </AccordionDetails>
//         </Accordion>
//       </div>
//     </div>
//   );
// };

// export default FicheDirigeant;

import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FicheDirigeant = ({ partnerData, onChange }) => {
  const [partners, setPartners] = useState([]);
  const [index, setIndex] = useState(1); // Initialisation de l'index pour l'incrémentation

  useEffect(() => {
    if (partnerData && partnerData.length > 0) {
      // Si des données existent, les utiliser avec un index correct
      const initializedPartners = partnerData.map((partner, idx) => ({
        ...partner,
        partnerId: `partnerId${idx + 1}`,
      }));
      setPartners(initializedPartners);
      setIndex(partnerData.length + 1);
    } else {
      // Sinon, initialiser avec un dirigeant par défaut
      setPartners([{
        partnerId: `partnerId${index}`,
        isMainLeader: true,
        spouseIdentity: {},
      }]);
      setIndex(index + 1);
    }
  }, [partnerData]);

  const handlePartnerChange = (e, partnerId) => {
    const { id, value } = e.target;
    const updatedPartners = partners.map((partner) => {
      if (partner.partnerId === partnerId) {
        if (id.startsWith('spouse')) {
          const spouseField = id.replace('spouse', '');
          const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);

          return {
            ...partner,
            spouseIdentity: {
              ...(partner.spouseIdentity || {}),
              [fieldKey]: value,
            },
          };
        }
        return { ...partner, [id]: value };
      }
      return partner;
    });

    setPartners(updatedPartners);
    onChange(updatedPartners);
  };

  const renderSpouseFields = (leader) => {
    return [
      { label: 'Prénom', id: 'spouseFirstNames', placeholder: 'Prénom' },
      { label: 'Nom', id: 'spouseName', placeholder: 'Nom' },
      { label: 'Nom de jeune fille', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
      { label: 'Lieu de naissance', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
      { label: 'Date de naissance', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
      { label: 'Nationalité', id: 'spouseNationality', placeholder: 'Nationalité' },
    ].map((field) => (
      <div key={field.id} className="mb-4">
        <TextField
          label={field.label}
          fullWidth
          variant="outlined"
          value={leader.spouseIdentity?.[field.id.replace('spouse', '').charAt(0).toLowerCase() + field.id.replace('spouse', '').slice(1)] || ''}
          placeholder={field.placeholder}
          onChange={(e) => handlePartnerChange(e, leader.partnerId)}
          id={field.id}
          type={field.type || 'text'}
          InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
        />
      </div>
    ));
  };

  return (
    <div className="row">
      <div style={{ cursor: "pointer" }}>
        <Accordion>
          <AccordionSummary>
            <h4>
              <ExpandMoreIcon style={{ marginRight: "8px" }} />
              Fiche dirigeant
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {partners
                .filter((partner) => partner.isMainLeader)
                .map((leader) => (
                  <React.Fragment key={leader.partnerId}>
                    <div className="col-md-6">
                      <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                        <AccordionSummary>
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: '8px' }} /> Dirigeant
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          {[
                            { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                            { label: 'Nom', id: 'name', placeholder: 'Nom' },
                            { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                            { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                            { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                          ].map((field) => (
                            <div key={field.id} className="mb-4">
                              <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={leader[field.id] || ''}
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)}
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-md-6">
                      <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                        <AccordionSummary>
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          {renderSpouseFields(leader)}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FicheDirigeant;
