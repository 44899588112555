import React,{useState} from 'react';
import EmployAside from '../../aside';
import AchatsIndex from './achats';

const Achats = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <AchatsIndex setIsOpenSidebar={setIsOpenSidebar}/>
    </div>    
  );
};

export default Achats;