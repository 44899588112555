import React from 'react';
import Header from "../Header";
import Banner from '../Banner';
import Blog from '../Blog';
import Feature from '../Feature';
import HowItWorksTwo from '../how-it-works';
import Footer from '../Footer';
import ScrollToTopButton from '../../Components/Theme/Theme';
import Creeat_societe from '../../Components/frontend/activite-statut/act-statutP2C-area';



function Act_statut() {
    return (
     <div className="page-container">
<Header></Header>
<main className="main-content">
    <Creeat_societe></Creeat_societe>
</main>
<Footer></Footer>
<ScrollToTopButton></ScrollToTopButton>
</div>
    );
}

export default Act_statut;
