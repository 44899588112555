import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >Titre</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 text-center">
              <h2>Entretien avec un expert : comment ça marche</h2>
            </div>
            <div className="col-12 d-flex justify-content-around align-items-start mt-4">
              <div className="text-center">
                <i className="bi bi-calendar3" style={{ fontSize: "2rem" }}></i>
                <p>Réserver une date pour le RDV (coût 150 euros)</p>
                <i className="bi bi-chat-square-dots" style={{ fontSize: "2rem" }}></i>
                <p>L’expert vous répond et conseille dans vos démarches</p>
              </div>
              <div className="text-center">
                <i className="bi bi-question-circle" style={{ fontSize: "2rem" }}></i>
                <p>Posez toutes vos questions lors du RDV</p>
                <i className="bi bi-file-earmark-check" style={{ fontSize: "2rem" }}></i>
                <p>À la fin du RDV vous aurez tous les éléments pour créer votre société</p>
              </div>
            </div>
            <div className="col-12 text-center mt-3">
              <CustomButton to="/rendez-vous2" >
                      Prendre rendez-vous avec un expert
              </CustomButton>
           </div>

          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
