"use client";
import React, { useState } from "react";
import { FaUserAlt, FaUserFriends } from 'react-icons/fa';
import {Link} from 'react-router-dom';





const CandidateFilterModal = () => (
    <div className="candidate-filter-modal">
       
    </div>
);

const CandidateV3Area = () => {
    

    return (
        <>

<div className="hero-banner-two position-relative">
    <div className="container">
        <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
            <div className="row">
                <div className="col-lg-12 col-md-8">
                    <h2 className="text-center">Choisir son statut</h2> 
                </div>
            </div>
        </div>
    </div>
</div>

<section className="blog-section-two">
    <div className="container">
        <div className="d-flex mb-1">
            <Link to="/"
                  style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                  className="d-flex justify-content-around">
                <span className="precedent-text">{"< Précédent"}</span>
            </Link>
            <Link to="#"
                  style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                  className="d-flex justify-content-center">
                <span className="d-flex justify-content-center text-center">1/5</span>
            </Link>
            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around">
                <span className="precedent-text">&nbsp;</span>
            </a>
        </div>

        <div className="text-center">
            <div className="title-one text-center mb-1 lg-mb-2" style={{ paddingTop: '3px', paddingBottom: '1px' }}>
                <h3 className="fw-600 ">Lancez-vous votre activité :</h3> 
            </div>
        </div>  

        <div className="d-flex justify-content-center gap-2 mb-4"
>
            <Link 
                type="button" 
                className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 me-2" 
                style={{ height: "177px" }}
                to="/activite-statut-s"
            >
                <FaUserAlt className="mb-3" size={30} /> 
                <span>Seul</span>
            </Link>

            <Link 
                type="button" 
                className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4" 
                style={{ height: "177px" }}
                to="/activite-statut-p"
            >
                <FaUserFriends className="mb-3" size={30} /> 
                <span>À plusieurs</span>
            </Link>
        </div>
    </div>
</section>

<CandidateFilterModal />
        </>
    );
};

export default CandidateV3Area;
