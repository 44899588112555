import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, List, ListItem } from "@mui/material";
import axiosInstance from "../../../api/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {setProject } from "../../../redux/packageSlice";
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const navigate=useNavigate();

  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
  console.log("Project data:", projectData);

  const [companyNameInput, setCompanyNameInput] = useState(projectData.companyName || "");
  const [selectedDomain, setSelectedDomain] = useState(projectData.domainName || "");
  const [savedDomain, setSavedDomain] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setCompanyNameInput(projectData.companyName || "");
    setSelectedDomain(projectData.domainName || "");
}, [projectData]);

 
  const handleCompanyNameChange = (event) => {
    const companyName = event.target.value;
    setCompanyNameInput(companyName);

    dispatch(setProject({
        projectId,
        data: {
        companyName, 
        },
    }));
};

const handleDomainChange = (event) => {
    const domainName = event.target.value;
    setSelectedDomain(domainName);

    dispatch(setProject({
        projectId,
        data: {
            domainName, 
        },
    }));
};


const checkDomainAvailability = async (domain) => {
    if (domain.trim()) {
      setLoading(true);
      setError("");
      setSavedDomain("");

      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query checkDomainName($domainName: String!) {
              checkDomainName(domainName: $domainName) {
                availability
                suggestions
              }
            }
          `,
          variables: { domainName: domain },
        });
        const data = response.data.data.checkDomainName;

        if (data.availability) {
          setSavedDomain(domain);
          setSuggestions([]);
        //   console.log()
        //   dispatch(setProject({
        //     projectId,
        //     data: {
        //       domain, 
        //     },
        // }));
         return true;
        } else {
          setSavedDomain("");
          setSuggestions(data.suggestions);
          return false;
        }
      } catch (err) {
        setError("Une erreur s'est produite lors de la vérification.");
        setSavedDomain("");
        setSuggestions([]);
        return false;
      } finally {
        setLoading(false);
      }
    }
    return false;
  };

  const handleContinue = async (event) => {
    event.preventDefault();

    if (!savedDomain) {
      const isAvailable = await checkDomainAvailability(selectedDomain);
      if (!isAvailable) {
        setError("Veuillez vérifier un nom de domaine valide avant de continuer.");
        return;
      }
    }

  navigate( `/package-com2/${projectId}`);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-12">
                <h2 className=" text-center" >
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
          <div className="text-center">
            <div className="btn-group">
              <span className="mx-3">1/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">
              Veuillez entrer un nom complet pour votre entreprise
            </h3>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <div style={{ padding: "20px" }}>
                  <TextField
                    label="Nom complet de l'entreprise"
                    variant="outlined"
                    fullWidth
                    value={companyNameInput}
                    onChange={handleCompanyNameChange}
                    className="mb-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">
              Choisissez un nom pour votre marque ou société pour le nom de domaine.
              Nous vérifions si celui-ci est disponible, sinon nous vous proposerons des alternatives.
            </h3>
            <p className="fw-600">Nom de domaine : définition + exemple</p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <div style={{ padding: "20px" }}>
                  <TextField
                    label="Nom de domaine"
                    variant="outlined"
                    fullWidth
                    value={selectedDomain}
                    onChange={handleDomainChange}
                    className="mb-3"
                  />
                  <Button
                    className="btn-eleven fw-500 tran3s d-block mt-20"
                    variant="contained"
                    fullWidth
                    onClick={() => checkDomainAvailability(selectedDomain)}
                    disabled={loading}
                  >
                    {loading ? "Vérification..." : "Vérifier"}
                  </Button>

                  {error && (
                    <Typography color="error" className="mt-3">
                      {error}
                    </Typography>
                  )}

                  {savedDomain && (
                    <Typography color="success" className="mt-3">
                      Le domaine "{savedDomain}" est disponible
                    </Typography>
                  )}

                  {suggestions.length > 0 && (
                    <>
                      <Typography variant="h6" className="mt-4">
                        Ce domaine n'est pas disponible. Voici des suggestions :
                      </Typography>
                      <List>
                        {suggestions.map((suggestion, index) => (
                          <ListItem key={index}>{suggestion}</ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-4">
            <div className="col-12 text-center mt-20">
              <CustomButton onClick={handleContinue}>
                    Continuer
              </CustomButton >
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
