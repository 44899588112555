"use client"
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {  telechargerUrl } from '../../../../../api/Services/invoiceService';
import { ClipLoader } from 'react-spinners';
import { fetchClients } from '../../../../../api/Services/clientService';

const ClientsFournisseursIndex = ({setIsOpenSidebar}) => {
    const [clients, setClients] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const projectId = '';

   useEffect(() => {
    const fetchClient = async () => {
      try {
        setIsLoading(true);
        const result = await fetchClients(projectId, 100, null);
        console.log(result);
  
        if (result.items && Array.isArray(result.items)) {
          const clientsWithUrls = await Promise.all(
            result.items.map(async (invoice) => {
              const urlInvoice = await telechargerUrl(invoice.pdfS3Key);
              console.log("urlInvoice:", urlInvoice);
              return { ...invoice, urlInvoice };
            })
          );
  
          setClients(clientsWithUrls); 
        } else {
          setClients([]); 
        }
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des factures.');
      } finally {
        setIsLoading(false);
      }
    };
    
  
     fetchClient();
  }, []);

return (
    <div className="dashboard-body">
    <div className="position-relative">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
      <h2 className="main-title h2-titel  text-center">Ma facturation &gt; {''}Projet nom &gt;Liste des fournisseurs/clients</h2>
  
      <div className="bg-white card-box border-20">
        
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader height="50" width="50" color="#19AF78" />
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : clients.length > 0 ? (
          <div className="table-responsive mb-5" id="liste-table">
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className="text-center">Nom de la société</th>
                  <th scope="col" className="text-center">Siret</th>
                  <th scope="col" className="text-center">Nom du contact</th>
                  <th scope="col" className="text-center">Code postal </th>
                  <th scope="col" className="text-center">Telephone </th>
                  <th scope="col" className="text-center">Email</th>

                </tr>
              </thead>
              <tbody className="border-0">
                {clients.map((Client, index) => (
                  <tr key={index}>
                    <td className="text-center"> <Link to={`/ma-facturation-client-fournisseur-modifier`} className="hover-green">{Client.companyName || "--"}</Link></td>
                    <td className="text-center">
                      {Client.siret|| "--"}
                    </td>
                    <td className="text-center">
                       {Client.contactName }
                    </td>
                   
                                       
                    <td className="text-center">{Client.address || "--"}</td>
                    <td className="text-center">{Client.phoneNumber || "--"}</td>
                    <td className="text-center">{Client.email || "--"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
        ) : (
          <div className="text-center mt-4">
            <p className="text-muted">
              Aucune donnée disponible pour le moment.
            </p>
          </div>
        )}
        <div className="d-flex justify-content-between mb-4">
       <Link
            to={`/ma-facturation-client-fournisseur-create`}
            className="dash-btn-two tran3s "
          >
            Créer un fournisseur/client
          </Link>
        </div>
  
  
       
      </div>
    
    </div>
  </div>
  
    
  );
};

export default ClientsFournisseursIndex;