'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import ModifierClientFournisseur from './Modifier_client_fournisseur';

const ModifierCl = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (
    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <ModifierClientFournisseur setIsOpenSidebar={setIsOpenSidebar}/>
    </div>   
  );
};

export default ModifierCl;