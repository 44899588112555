import React ,{useEffect, useState} from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getWebpackageOrders } from '../../../../api/Services/webPackageService';
import { Alert, Box } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import CustomButton from '../../../../layouts/CustomButton';

const IndexGed = ({setIsOpenSidebar}) => {
  const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading]= useState(false);
  

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await getWebpackageOrders();
        setProjects(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchProjects();
    console.log(fetchProjects())

  }, []);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

          <h2 className="main-title h2-title text-center">GED</h2>          

        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
            
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                <ClipLoader size={50} color="#19AF78" />
              </div>
            ) : projects.length === 0 ? (
              <Box mt={3}>
                <Alert severity="info">Aucune projet disponible.</Alert>
              </Box>
            ) : (
              projects.map((project) => (
                <Link to={`/ged/${project.projectId}`} className="d-block">
                <button 
                    key={project.projectId} 
                    type="submit" 
                    className="btn-eleven fw-500 tran3s d-block mt-20"
                >
                    {project.projectName}
                </button>
            </Link>
            
            )))}
            
            
            

                <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                    <Link to="/package-com">Commander un nouveau projet WebPro</Link>
                </button>
               </div>
          
        </div>
      </div>      
    </div>
  );
};

export default IndexGed;