import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddAssocie from "../../../../../layouts/AddAssocie";

const FicheAssocies = ({ partnerData, onChange }) => {
  const [partners, setPartners] = useState([]);
  const [index, setIndex] = useState(1);
  const [showModalAddAssocie, setShowModalAddAssocie] = useState(false);
 const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
  
  const handleIconClick1 = (iconClass) => {
    if (iconClass.includes("text-success")) {
      setDrawerOpen(true);
    }
  };
  useEffect(() => {
    console.log("partnerData received:", partnerData);

    if (partnerData && partnerData.length > 0) {
      const initializedPartners = partnerData.map((partner, idx) => ({
        ...partner,
        partnerId: partner.partnerId || `partnerId${idx + 1}`,
        isMainLeader: partner.isMainLeader || false,
      }));

      const hasAssocie = initializedPartners.some((partner) => !partner.isMainLeader);

      if (!hasAssocie) {
        initializedPartners.push({
          partnerId: `partnerId${initializedPartners.length + 1}`,
          isMainLeader: false,
          firstNames: [""],
          name: "",
          dateOfBirthday: "",
          placeOfBirthday: "",
          nationality: "",
          professionalSituation: "",
          socialSecurityNumber: "",
          spouseIdentity: {
            name: "",
            maidenName: "",
            firstNames: [""],
            dateOfBirthday: "",
            placeOfBirthday: "",
            nationality: "",
          },
        });
      }

      setPartners(initializedPartners);
      setIndex(initializedPartners.length + 1);
    } else {
      setPartners([
        {
          partnerId: `partnerId${index}`,
          isMainLeader: false,
          firstNames: [""],
          name: "",
          dateOfBirthday: "",
          placeOfBirthday: "",
          nationality: "",
          professionalSituation: "",
          socialSecurityNumber: "",
          spouseIdentity: {
            name: "",
            maidenName: "",
            firstNames: [""],
            dateOfBirthday: "",
            placeOfBirthday: "",
            nationality: "",
          },
        },
      ]);
      setIndex(index + 1);
    }
  }, [partnerData]);

  const handlePartnerChange = (e, partnerId) => {
    const { id, value } = e.target;
    const updatedPartners = partners.map((partner) => {
      if (partner.partnerId === partnerId) {
        if (id.startsWith("spouse")) {
          const spouseField = id.replace("spouse", "");
          const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);

          return {
            ...partner,
            spouseIdentity: {
              ...(partner.spouseIdentity || {}),
              [fieldKey]: value,
            },
          };
        }
        return { ...partner, [id]: value };
      }
      return partner;
    });

    setPartners(updatedPartners);
    onChange(updatedPartners);
  };

  const renderSpouseFields = (partner) => {
    return [
      { label: "Prénom", id: "spouseFirstNames", placeholder: "Prénom" },
      { label: "Nom", id: "spouseName", placeholder: "Nom" },
      { label: "Nom de jeune fille", id: "spouseMaidenName", placeholder: "Nom de jeune fille" },
      { label: "Lieu de naissance", id: "spousePlaceOfBirthday", placeholder: "Lieu de naissance" },
      { label: "Date de naissance", id: "spouseDateOfBirthday", placeholder: "Date de naissance", type: "date" },
      { label: "Nationalité", id: "spouseNationality", placeholder: "Nationalité" },
    ].map((field) => (
      <div key={field.id} className="mb-4">
        <TextField
          label={field.label}
          fullWidth
          variant="outlined"
          value={partner.spouseIdentity?.[field.id.replace("spouse", "").charAt(0).toLowerCase() + field.id.replace("spouse", "").slice(1)] || ""}
          placeholder={field.placeholder}
          onChange={(e) => handlePartnerChange(e, partner.partnerId)}
          id={field.id}
          type={field.type || "text"}
          InputLabelProps={field.type === "date" ? { shrink: true } : {}}
        />
      </div>
    ));
  };

  const handleOpenAddAssocie = () => setShowModalAddAssocie(true);
  const handleCloseAddAssocie = () => setShowModalAddAssocie(false);

  const handleAddAssocie = (newAssocie) => {
    const newPartner = {
      ...newAssocie,
      partnerId: `partnerId${index}`,
      isMainLeader: false,
    };
    setPartners([...partners, newPartner]);
    setIndex(index + 1);
    onChange([...partners, newPartner]); // Propagation des modifications au composant parent
    handleCloseAddAssocie();
  };

  return (
    <div className="row">
      <div style={{ cursor: "pointer" }}>
        <Accordion>
          <AccordionSummary>
            <h4>
              <ExpandMoreIcon style={{ marginRight: "8px" }} />
              Fiche Associés
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {partners
                .filter((partner) => !partner.isMainLeader)
                .map((associe, idx) => {
                  console.log("Rendering associé:", associe);
                  return (
                    <React.Fragment key={associe.partnerId}>
                      <div className="col-md-6">
                        <Accordion style={{ boxShadow: "none", border: "none" }}>
                          <AccordionSummary>
                            <h4>
                              <ExpandMoreIcon style={{ marginRight: "8px" }} /> Associé {idx + 1}
                            </h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            {[
                              { label: "Prénom", id: "firstNames", placeholder: "Prénom" },
                              { label: "Nom", id: "name", placeholder: "Nom" },
                              { label: "Lieu de naissance", id: "placeOfBirthday", placeholder: "Lieu de naissance" },
                              { label: "Date de naissance", id: "dateOfBirthday", placeholder: "Date de naissance", type: "date" },
                              { label: "Situation professionnelle", id: "professionalSituation", placeholder: "Situation professionnelle" },
                              { label: "Numéro de sécurité sociale", id: "socialSecurityNumber", placeholder: "Numéro de sécurité sociale" },
                              { label: "Nationalité", id: "nationality", placeholder: "Nationalité" },
                            ].map((field) => (
                              <div key={field.id} className="mb-4">
                                <TextField
                                  label={field.label}
                                  fullWidth
                                  variant="outlined"
                                  value={associe[field.id] || ""}
                                  placeholder={field.placeholder}
                                  onChange={(e) => handlePartnerChange(e, associe.partnerId)}
                                  id={field.id}
                                  type={field.type || "text"}
                                  InputLabelProps={field.type === "date" ? { shrink: true } : {}}
                                />
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </div>

                      <div className="col-md-6">
                        <Accordion style={{ boxShadow: "none", border: "none" }}>
                          <AccordionSummary>
                            <h4>
                              <ExpandMoreIcon style={{ marginRight: "8px" }} /> Conjoint(e) {idx + 1}
                            </h4>
                          </AccordionSummary>
                          <AccordionDetails>{renderSpouseFields(associe)}</AccordionDetails>
                        </Accordion>
                      </div>
                      <div className="table-responsive mt-20 mb-20" id="liste-table">
                    <table className="table job-alert-table">
                      <tbody>
                        <tr>
                          <td className="text-left p-2">nom pièce 1</td>
                          <td className="text-center p-2">
                            <i className="fas fa-file-alt text-success fs-4" onClick={() => handleIconClick1("text-success")}></i>
                          </td>
                          <td className="text-center p-2">En attente/validé/rejeté</td>
                          <td className="text-center p-2">message du cabinet comptable</td>
                          <td className="text-center p-2">
                            <button onClick={() => setShowModal1(true)}>Charger</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left p-2">nom pièce 2</td>
                          <td className="text-center p-2">
                            <i className="fas fa-file-alt text-danger fs-4"></i>
                          </td>
                          <td className="text-center p-2">En attente/validé/rejeté</td>
                          <td className="text-center p-2">message du cabinet comptable</td>
                          <td className="text-center p-2">
                            <button onClick={() => setShowModal1(true)}>Charger</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                    </React.Fragment>
                  );
                })}
              <div className="d-flex justify-content-start mt-4 mb-3">
                <button className="dash-btn-two tran3s me-3" onClick={handleOpenAddAssocie}>
                  Ajouter un associé
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <AddAssocie show={showModalAddAssocie} handleClose={handleCloseAddAssocie} handleAddAssocie={handleAddAssocie} />
    </div>
  );
};

export default FicheAssocies;