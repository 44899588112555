"use client";
import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for routing
import { FaUserAlt, FaUserFriends, FaSlack } from 'react-icons/fa';

const CandidateV3Area = () => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="text-center" >Choisir son statut</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two">
        <div className="container">
          {/* Alignement centré uniquement sur les petits écrans */}
          <div className="d-flex flex-column flex-sm-row mb-1">
            <Link
              to="/activite-statut"
              style={{ margin: '10px auto', width: 'auto', paddingLeft: '65px' }}
              className="d-flex justify-content-around justify-content-sm-start"
            >
              <span className="precedent-text">{"< Précédent"}</span>
            </Link>
            <Link
              to="#"
              style={{ margin: '10px auto', width: 'auto', paddingRight: '150px' }}
              className="d-flex justify-content-center"
            >
              <span className="d-flex justify-content-center text-center">5/5</span>
            </Link>
            <a style={{ margin: '10px auto', width: 'auto' }} className="d-flex justify-content-around">
              <span className="precedent-text">&nbsp;</span>
            </a>
          </div>

          <div className="text-center">
            <div className="title-one text-center mb-1 lg-mb-2" style={{ paddingTop: '3px', paddingBottom: '1px' }}>
              <h3 className="fw-600">
                Quels sont les modes de rémunérations souhaités ?
              </h3>
            </div>
          </div>

          {/* Boutons responsive */}
          <div className="d-flex flex-column flex-sm-row justify-content-center gap-2 mb-4">
            <Link
              type="button"
              className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 mb-2 mb-sm-0"
              style={{ height: "177px", width: "100%", maxWidth: "300px" }}
              to="/activite-statut-s3p2"
            >
              <FaUserAlt className="mb-2" size={30} />
              Versement d’un salaire
            </Link>

            <Link
              type="button"
              className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 mb-2 mb-sm-0"
              style={{ height: "177px", width: "100%", maxWidth: "300px" }}
              to="/activite-statut-s3p3"
            >
              <FaUserFriends className="mb-2" size={30} />
              Versement de dividendes
            </Link>

            <Link
              type="button"
              className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 mb-2 mb-sm-0"
              style={{ height: "177px", width: "100%", maxWidth: "300px" }}
              to="/activite-statut-s3p2"
            >
              <FaSlack className="mb-2" size={30} />
              Les deux
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;