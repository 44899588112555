import React from "react";
import ContactForm from '../contact/contact-form'; // Adjust path if necessary

const ContactArea = () => {
    return (
        <div>
            <div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >Contact</h2>
                                {/* Optional subtitle */}
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30 fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <section className="contact-us-section pt-0 lg-pt-0">
                <div className="container">
                    <div className="border-bottom pb-150 lg-pb-80">
                        {/* Optional title section */}
                        {/* <div className="title-one text-center mb-70 lg-mb-40"></div> */}

                        {/* Uncomment if you have address blocks */}
                        {/* <div className="row">
                            <div className="col-xl-10 m-auto">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="address-block-one text-center mb-40 ">
                                            <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                                                <img src={icon_1} alt="Notre Adresse" />
                                            </div>
                                            <h5 className="title">Notre Adresse</h5>
                                            <p>
                                                Bass Hill Plaza Medical Centre <br />
                                                Sydney, Australie
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="address-block-one text-center mb-40 ">
                                            <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                                                <img src={icon_2} alt="Infos Contact" />
                                            </div>
                                            <h5 className="title">Infos Contact</h5>
                                            <p>
                                                Ouvrez un chat ou appelez-nous au <br />
                                                <a href="tel:310.841.5500" className="call">
                                                    310.841.5500
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="address-block-one text-center mb-40 ">
                                            <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                                                <img src={icon_3} alt="Support en Direct" />
                                            </div>
                                            <h5 className="title">Support en Direct</h5>
                                            <p>
                                                service de chat en direct <br />
                                                <a href="#" className="webaddress">
                                                    www.jobilivechat.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-xl-9 m-auto">
                                <div className="form-style-one mt-85 lg-mt-50 ">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactArea;
