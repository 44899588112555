import React, { useMemo, useState } from "react";
import { UserPurchaseS3 } from "../../../../api/Services/achatService";
import { Link } from "react-router-dom";

const CandidateItem = ({ id,nom, date, price, invoiceId }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);

  const handlePdfClick = async () => {
    try {
      console.log(id, invoiceId);
      const response = await UserPurchaseS3(id, invoiceId);
      if (response.url) {
        setPdfUrl(response.url);
        setShowPdf(true);
      } else {
        console.error("URL du PDF non valide");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF:", error);
    }
  };
  const formattedDate = useMemo(
    () => new Date(date).toLocaleString("fr-FR", { dateStyle: "medium", timeStyle: "short" }),
    [date]
  );
  const handleDownloadClick = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";
      link.click();
    }
  };

  return (
    <>
      <tr>
        <td className='text-center'>
          <div>{nom}</div>
        </td>
        <td className='text-center'>
          <div>{formattedDate}</div>
        </td>
        <td className='text-center'>
          <div>{price}</div>
        </td>
        <td className='text-center'>
          <div className="job-type fw-500">
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={handlePdfClick}
            >
              <img
                src="/assets/images/icon/pdf.svg"
                alt="PDF icon"
                width="40"
              />
            </Link>
          </div>
        </td>
      </tr>
      {showPdf && pdfUrl && (
        <div className="pdf-viewer">
          <div className="viewer-header">
            <button onClick={() => setShowPdf(false)} className="close-button">
              x
            </button>
            <button onClick={handleDownloadClick} className="download-button">
              Télécharger
            </button>
          </div>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}

      <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
      `}</style>

    </>
  );
};

export default CandidateItem;