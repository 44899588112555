
import React,{useState,useEffect} from 'react';
import DashboardHeader from '../../header';

import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography, Box,CircularProgress, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {Link} from "react-router-dom";
import { fetchDownloafile, getWebpackageOrder } from '../../../../../api/Services/webPackageService';
import { useParams } from 'react-router-dom'; 
import { useDispatch } from 'react-redux';
import { setProject } from '../../../../../redux/packageSlice';
import { ClipLoader } from 'react-spinners';
import CustomButton from '../../../../../layouts/CustomButton';

const Info = ({ setIsOpenSidebar }) => {
  const [projects, setProjects] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useParams(); 
  const [logoUrl, setLogoUrl] = useState("");
  const [galleryUrls, setGalleryUrls] = useState([]);

  const dispatch= useDispatch();


  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getWebpackageOrder(projectId); 
        setProjects(response?.data?.getWebpackageOrder || {});
        const data = response?.data?.getWebpackageOrder || {};
        console.log("data dispatch",data);
        dispatch(setProject({ projectId, data })); 
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProject();
  }, [projectId ,dispatch]); 
  

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesToDownload = [];

        const logoKey = projects.logo?.logoToCreate?.s3ImageKey;
        if (logoKey) filesToDownload.push(logoKey);

        const galleryKeys = projects.website?.gallery?.map((image) => image.s3Key) || [];
        filesToDownload.push(...galleryKeys);

        const urls = await Promise.all(
          filesToDownload.map(async (key) => {
            const response = await fetchDownloafile(key);
            return response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url;
          })
        );

        if (logoKey) setLogoUrl(urls[0]); 
        setGalleryUrls(urls.slice(logoKey ? 1 : 0)); 
      } catch (error) {
        console.error("Erreur lors du téléchargement des fichiers :", error);
      }
    };
  
    
    if (projects.logo || projects.website?.gallery?.length > 0) {
      fetchFiles();
    }
  }, [projects]);
  

  
 
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
    

        <h2 className="main-title text-center h2-titel"> Pharos WebPro &gt; {projects.projectName}</h2>
          <div className="bg-white card-box border-20 col-12">
          {isLoading ?( 
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                            <ClipLoader size={50} color="#19AF78" />
                      </div>
                     ) :(
                      <>
                      <Accordion>
                        <AccordionSummary>
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: "8px" }} />
                            Informations générales
                          </h4>
                        </AccordionSummary>
                          <AccordionDetails>
           
                          <div className="mb-30">
                            <div className="mt-30">
                              <div className="row">
                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Nom de domaine
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      value={projects.domainName || ""}
                                      placeholder="Nom de domaine"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Nom société
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      value={projects.website?.globalDetails?.companyName || ""}
                                      placeholder="Nom société"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Adresse
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      value={projects.website?.globalDetails?.address || ""}
                                      placeholder="Adresse"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Numéro de téléphone
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      value={projects.website?.globalDetails?.phoneNumber || ""}
                                      placeholder="Téléphone"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-4">
                                  <div className="col-md-12">
                                    <Typography variant="h5" gutterBottom>
                                      Horaires
                                    </Typography>
                                    <Box className="horaires-wrapper" sx={{ border: '1px solid #ccc', borderRadius: '10px', p: 3 }}>
                                      <div className="row mb-2">
                                        <div className="col"></div>
                                        <div className="col text-center">Début matinée</div>
                                        <div className="col text-center">Fin matinée</div>
                                        <div className="col text-center">Début après-midi</div>
                                        <div className="col text-center">Fin après-midi</div>
                                      </div>
                                      {['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'].map((dayKey, index) => {
                                        const times = projects.website?.globalDetails?.days?.[dayKey] || [];
                                        const startMorning = times[0]?.split('-')[0] || '';
                                        const endMorning = times[0]?.split('-')[1] || '';
                                        const startAfternoon = times[1]?.split('-')[0] || '';
                                        const endAfternoon = times[1]?.split('-')[1] || '';

                                        const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

                                        return (
                                          <div className="row mb-2 align-items-center" key={dayKey}>
                                            <div className="col" style={{ width: '100px' }}>{daysOfWeek[index]}</div>
                                            <div className="col">
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={startMorning}
                                                placeholder="Début matinée"
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                            <div className="col">
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={endMorning}
                                                placeholder="Fin matinée"
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                            <div className="col">
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={startAfternoon}
                                                placeholder="Début après-midi"
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                            <div className="col">
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={endAfternoon}
                                                placeholder="Fin après-midi"
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </Box>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <Typography variant="h5" gutterBottom>
                                      A propos
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      multiline
                                      rows={4}
                                      value={projects.website?.about?.paragraph || ""}
                                      placeholder="A propos"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
               </AccordionDetails>
                                </Accordion>
                   

                                <Accordion className='mt-3'>
                        <AccordionSummary>
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: "8px" }} />
                            Logo
                          </h4>
                        </AccordionSummary>
                          <AccordionDetails>

                                  {logoUrl ? (
                                    <Box display="flex" justifyContent="center" mt={3}>
                                      <img
                                        src={logoUrl}
                                        alt="logo"
                                        style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "contain" }}
                                      />
                                    </Box>
                                  ) : (
                                    <Box display="flex" justifyContent="center" mt={3}>
                                      <CircularProgress />
                                      <Typography ml={2}>Chargement de l'aperçu du logo...</Typography>
                                    </Box>
                                  )}

                      
                     
                                  
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion className='mt-3'>
                        <AccordionSummary>
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: "8px" }} />
                            Galleries images
                          </h4>
                        </AccordionSummary>
                          <AccordionDetails>

                              <Box textAlign="center" mb={3}>
                                {galleryUrls.length > 0 ? (
                                  <Grid container justifyContent="center" spacing={3} mt={3}>
                                    {galleryUrls.map((url, index) => (
                                      <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Box 
                                          className="gallery-item-card" 
                                          display="flex" 
                                          justifyContent="center" 
                                          alignItems="center"
                                        >
                                          <img
                                            src={url}
                                            alt={`Image ${index + 1}`}
                                            style={{ width: "100%", paddingTop: "30%", objectFit: "contain" }}
                                          />
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Grid>
                                ) : (
                                  <Typography>Aucune image disponible pour cette galerie.</Typography>
                                )}
                              </Box>

                     
                                  
                                  </AccordionDetails>
                                </Accordion>
                   



                  <div className="d-flex justify-content-center mt-4">
                    <CustomButton  to={`/package-com1/${projectId}`}>
                    Modifier le package
                    </CustomButton >
                     
                    </div>
                    </>
                       )}
                  </div>
            </div>
    </div>
  );
};

export default Info;









