import React from 'react';
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import CustomButton from './CustomButton';

const blog_data = [
    {
        id: 4,
        img: "/assets/images/blog/Creer_ma_societe.png",
        tags: ['Design', 'Work'],
        title: "Nous accompagnons les futurs entrepreneurs dans toutes les démarches administratives liées à la création de leur entreprise. De la définition du statut juridique à l'enregistrement officiel, nous offrons un soutien personnalisé. Grâce à notre expertise, nous simplifions chaque étape pour garantir une mise en place rapide et conforme.",
        author: 'Martin Cooley',
        date: 'Créer ma société',
        desc: 'This response is important for our ability to learn from mistakes, but words',
        blog: 'blog-two',
        price: 'A partir de xxx € HT',
        link: 'statut-juridique',
        link_two: 'statut-juridique',
    },
    {
        id: 5,
        img: '/assets/images/blog/Creer_mon_paquet_web.png',
        tags: ['Fashion', 'Work'],
        title: "Nous offrons une solution en ligne qui vous permet de créer en quelques minutes un paquet web complet. Celui-ci comprend un nom de domaine, un site web statique, une adresse mail professionnelle et un gestionnaire électronique de documents (GED). Grâce à notre service, tout est prêt pour que votre entreprise soit rapidement opérationnelle sur le web.",
        author: 'Martin Cooley',
        date: 'Créer mon paquet Web',
        desc: 'This response is important for our ability to learn from mistakes, but words',
        blog: 'blog-two',
        price: 'A partir de xxx € HT par mois',
        link: 'package-com',
        link_two: 'package-com',
    },
    {
        id: 6,
        img: '/assets/images/blog/Gerer_ma_societe.png',
        tags: ['Art', 'Work'],
        title: "Avec notre application, les utilisateurs peuvent bénéficier d'outils de gestion complets pour leur entreprise. Elle inclut des fonctionnalités de facturation, de création de devis, et bien d'autres outils essentiels. Cette solution facilite la gestion quotidienne et permet aux entrepreneurs de se concentrer sur le développement de leur activité.",
        author: 'Martin Cooley',
        date: 'Gérer ma société',
        desc: 'This response is important for our ability to learn from mistakes, but words',
        blog: 'blog-two',
        price: 'Gratuit',
        link: 'ma-facturation-index',
        link_two: 'ma-facturation-index',
    },
]
function Blog() {
    const blog_items = blog_data.filter((b) => b.blog === "blog-two");
    return (
        <>
            <section className="blog-section-two" style={{ paddingTop: '32px', paddingBottom: '80px' }}>
                <div className="container">
                    <div className="position-relative">
                        <div className="title-one text-center mb-30">
                            <h2 className="fw-600">Les solutions <span className="pharos">Pharos</span></h2>
                        </div>

                        
                       <div className="row gx-xxl-5">
                            {blog_items.map((item) => (
                            <div key={item.id} className="col-lg-4 col-md-6 mt-4"> {/* Ajout de mt-4 ici */}
                                <article className="blog-meta-one mt-35  card_solution">
                                    <figure className="post-img m0">
                                        <img
                                            src={item.img}
                                            alt="blog-img"
                                            className="lazy-img w-100 tran4s blog-img"
                                        />
                                    </figure>
                                    <div className="post-data mt-30 lg-mt-20">
                                        <div className='text-center'>
                                            <Link to={`/${item.link_two}`} className="date text-uppercase">
                                                <h4 className="tran3s blog-title">{item.date}</h4>
                                            </Link>
                                        </div>
                                        {item.title}
                                        <div className="text-center text-success mt-10 mb-25 lg-mb-20 fw-bold fs-5">
                                            {item.price}
                                        </div>
                                        <div className="text-center">
                                        <CustomButton to={`/${item.link}`} >
                                        En savoir plus
                                        </CustomButton >   
                                            
                                       </div>
                                    </div>
                                </article>
                            </div>
                            ))}
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;
