import React, { useEffect, useState } from "react";
import DashboardHeader from "../header";
import { getUserInfo } from "../../../../api/Services/userService";
import { ClipLoader } from "react-spinners";
import { Grid, TextField } from "@mui/material";

const EmployProfileArea = ({ setIsOpenSidebar }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfo();
        if (response) {
          setUserInfo({
            firstName: response.firstName || "",
            name: response.name || "",
            email: response.email || "",
            phoneNumber: response.phoneNumber || "",
          });
        }
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des informations utilisateur :",
          err
        );
        setError("Impossible de récupérer les informations utilisateur.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h3 className="d-flex flex-column align-items-center">
          Mes informations personnelles
        </h3>

        <div className="bg-white card-box border-20 p-4">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "150px" }}
            >
              <ClipLoader size={50} color="#19AF78" />
            </div>
          ) : userInfo ? (
            <form>
               <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mon prénom"
                variant="outlined"
                value={userInfo.firstName}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mon nom"
                variant="outlined"
                value={userInfo.name}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mon adresse mail"
                variant="outlined"
                value={userInfo.email}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mon numéro de téléphone"
                variant="outlined"
                value={userInfo.phoneNumber}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
            </form>
          ) : (
            <div className="text-center w-100">
              <p>Aucune information utilisateur disponible.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployProfileArea;
