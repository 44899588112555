import React from 'react';
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import CustomButton from './CustomButton';
function Banner() {
    return (
        <>
            <div className="hero-banner-two position-relative background_section">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">              
                                <h2 className=" text-center title_banner" >Créez et gérez votre société avec <span className='pharos'>Pharos</span></h2>
                                <div>
                                    <p className="text-center  text_banner" data-wow-delay="0.4s">
                                        La solution <span className='pharos'>Pharos</span>  vous accompagne dans la création et la gestion de votre société, tout en vous aidant à concevoir votre site web et votre logo. <br />
                                        <span className='pharos'>Pharos</span> vous facilite les tâches administratives et techniques afin de vous consacrer pleinement sur le coeur d'activité de votre entreprise.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="position-relative ">
                            <div className="row">
                                <div className="d-flex justify-content-center">
                                    <div className="job-search-two position-relative me-xxl-5 " data-wow-delay="0.5s">
                                    <form className="d-flex align-items-center justify-content-between">  
                                        <CustomButton to="#" >
                                            Découvrez
                                        </CustomButton >   
                                                         
                                    </form>                  
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
        </>
    );
}

export default Banner;
