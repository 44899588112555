

import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextField, Select, MenuItem, Button, TextareaAutosize, InputLabel, FormControl } from '@mui/material';
import { getAccountancyIssuerConfig, getLogoUpload, putAccountancyIssuerConfig, uploadLogoToS3,VisualiserLogo, telechargerUrl } from '../../../../../api/Services/invoiceService';
import Swal from 'sweetalert2';
import CustomButton from '../../../../../layouts/CustomButton';

const InformationGenerale = ({ setIsOpenSidebar }) => {
  const { projectId } = useParams();
  const projet = useSelector((state) => state.invoice.projets[projectId]);
  //  console.log("projet",projet);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [logoFile, setLogoFile] = useState(null); 
   const [logoUrl, setLogoUrl] = useState(''); 



  const [configData, setConfigData] = useState({
    projectId: projectId,
    companyName: '',
    socialStatus: '',
    siret: '',
    contactName: '',
    address: '',
    phoneNumber: '',
    email: '',
    vatNumber: '',
    nafNumber: '',
    capitalStockAmount: '',
    capitalStockCurrency: '',
    iban: '',
    bicSwift: '',
    logoS3Key: '',
    generalCondition :'',
    bankName:'',
    bankAddress:'',
    bankAccountName:'',

  });
  
  const fetchData = async () => {
    try {
      const data = await getAccountancyIssuerConfig(projectId);
      // console.log("data",data);
      setConfigData(data || {});
      if (projectId) {
        const s3key = await VisualiserLogo(projectId);
        const url = await telechargerUrl(s3key);
        console.log(url);
        setLogoUrl(url); 
      }
    } catch (error) {
      // console.error('Erreur lors de la récupération des données:', error);
    }
  };
  

  useEffect(() => {
   
    fetchData();
  }, [projectId]);
 

  const handleChange = (field, value) => {
    let formattedValue = value;
  
    if (field === 'phoneNumber') {
      const cleanedValue = value.replace(/\D/g, '');
  
      if (cleanedValue.startsWith('0') && cleanedValue.length === 10) {
        formattedValue = `+33${cleanedValue.slice(1)}`;
      } else {
        formattedValue = cleanedValue; 
      }
    }
  
    setConfigData((prevState) => ({
      ...prevState,
      [field]: formattedValue,
    }));
  };

  const handleLogoChange = (e) => {
    console.log("dfgh");
    const file = e.target.files[0]; 
    setLogoFile(file); 
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoUrl(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      console.log("configData",configData);
      if(!projectId){
        alert("erreur projectid nexiste aps ");
      }
      const dataInput = {
      
        projectId: projectId,
        companyName: configData.companyName || "",
        socialStatus: configData.socialStatus || "",
        siret: configData.siret || "",
        contactName: configData.contactName|| "",
        address: configData.address || "",
        phoneNumber: configData.phoneNumber || "",
        email: configData.email || "",
        vatNumber: configData.vatNumber || "",
        nafNumber: configData.nafNumber || "",
        capitalStockAmount: configData.capitalStockAmount || "",
        capitalStockCurrency: configData.capitalStockCurrency || "",
        iban: configData.iban || "",
        bicSwift: configData.bicSwift || "",
        generalCondition: configData.generalCondition || "",
        bankName: configData.bankName || "",
        bankAddress: configData.bankAddress || "",
        bankAccountName: configData.bankAccountName || "",
   
    };
    
    console.log("dataInput", dataInput);

  
      const response = await putAccountancyIssuerConfig(dataInput);
      
      console.log("Réponse brute du serveur:", response.data);
    
      if (response?.data?.errors?.length > 0) {
        throw new Error(response.data.errors[0].message);
      }
    
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Les informations ont été mises à jour avec succès !',
        confirmButtonText: 'OK',
      });
      await fetchData();
    } catch (error) {
      console.error("Erreur lors de la mise à jour :", error);
    
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: error.message || "Une erreur s'est produite lors de la mise à jour.",
        confirmButtonText: 'OK',
      });
   
    }
      
  //   }else{
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Erreur',
  //     text: 'Une erreur est survenue lors de la mise à jour.',
  //     confirmButtonText: 'OK',
  //   });
  // }
  //   } catch (error) {
  //     console.error('Erreur lors de la mise à jour des données :', error);
    
  //   }
  
  if (logoFile) {
    try {
      await uploadLogoToS3(projectId, logoFile);
      Swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Logo téléversé avec succès.',
        confirmButtonText: 'OK',
      });
      fetchData(); 
    } catch (error) {
      console.error('Erreur lors du téléversement du logo:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue lors du téléversement du logo.',
        confirmButtonText: 'OK',
      });
    }
  }
  
    setIsSubmitting(false); 

  };
  
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> Ma facturation &gt; {projet.projectName} &gt;Information générale</h2>

        <div className="bg-white card-box border-20 col-12">
        <form>
            <h4 className="mb-4"><u>Votre logo</u></h4>
            <div className="d-flex flex-column align-items-center mb-40">
            <div className="user-avatar-setting d-flex flex-column align-items-center mb-30">
                <img src={logoUrl} alt="" width="100" />
                <Button variant="contained" component="label" className="mt-3" sx={{
                  backgroundColor: 'rgb(25, 175, 120)',
                  textTransform: 'none',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgb(20, 140, 90)', 
                    color: 'white', 
                  },
                }}>
                  Charger Logo
                  <input type="file" hidden onChange={handleLogoChange} />
                </Button>
              </div>
            </div>

            <h4 className="mb-4"><u>Vos Informations</u></h4>
            <div className="row mb-4">
              <div className="col-md-6">
                <TextField fullWidth label="Adresse" variant="outlined" value={configData.address || ''} onChange={(e) => handleChange('address', e.target.value)} />
              </div>
              <div className="col-md-6">
                <TextField fullWidth label="Nom d'entreprise" variant="outlined" value={configData.companyName || ''} onChange={(e) => handleChange('companyName', e.target.value)} />
              </div>
            </div>
            
            <div className="row mb-4">
              
               <div className=" col-md-6 mb-3">
                              <TextField
                                select
                                label="Statut legal du projet"
                                fullWidth
                                variant="outlined"
                                id="legalStatus"
                                value={configData.socialStatus}
                                onChange={(e) => handleChange('socialStatus', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem value="SAS">SAS</MenuItem>
                                <MenuItem value="SASU">SASU</MenuItem>
                                <MenuItem value="SARL">SARL</MenuItem>
                                <MenuItem value="EURL">EURL</MenuItem>
                                <MenuItem value="ASSOCIATION">Association</MenuItem>
                                <MenuItem value="AUTO_ENTREPRISE">Auto-Entreprise</MenuItem>
                              </TextField>
                            </div>
              <div className="col-md-6">
                <TextField fullWidth label="Nom du contact" variant="outlined" value={configData.contactName || ''} onChange={(e) => handleChange('contactName', e.target.value)} />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <TextField fullWidth label="Adresse mail" variant="outlined" value={configData.email || ''} onChange={(e) => handleChange('email', e.target.value)}  />
              </div>
              <div className="col-md-6">
                <TextField fullWidth label="Numéro de téléphone" variant="outlined" value={configData.phoneNumber || ''} onChange={(e) => handleChange('phoneNumber', e.target.value)} />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <TextField fullWidth label="Capital social" variant="outlined" value={configData.capitalStockAmount || ''}onChange={(e) => handleChange('capitalStockAmount', e.target.value)}/>
              </div>
              <div className="col-md-6">
                <TextField fullWidth label="Siret" variant="outlined"  value={configData.siret || ''}
                onChange={(e) => handleChange('siret', e.target.value)}  />
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-6">
              <TextField fullWidth label="NAF" variant="outlined" value={configData.nafNumber || ''} 
              onChange={(e) => handleChange('nafNumber', e.target.value)}/>
            </div>
            <div className="col-md-6">
              <TextField fullWidth label="N° TVA Intracommunautaire" variant="outlined"  value={configData.vatNumber || ''} 
              onChange={(e) => handleChange('vatNumber', e.target.value)} />
            </div>
          </div>
          <div className="row mb-4">
          <div className="col-md-6">
            <TextField fullWidth label="Devise du capital social" variant="outlined"value={configData.capitalStockCurrency || ''}
            onChange={(e) => handleChange('capitalStockCurrency', e.target.value)}/>
          </div>
        
        </div>
          
            
            <h4 className="mb-4"><u>Vos conditions générales</u></h4>
            <div className="row mb-4">
              <div className="col-md-12">
                <TextareaAutosize minRows={2} className="form-control" value={configData.generalCondition || ''} onChange={(e) => handleChange('generalCondition', e.target.value)} />
              </div>
            </div>
            
            <h4 className="mb-4"><u>Vos coordonnées bancaires</u></h4>
            <div className="row mb-4">
              <div className="col-md-6">
                <TextField fullWidth label="Nom du compte" variant="outlined" value={configData.bankAccountName || ''} onChange={(e) => handleChange('bankAccountName', e.target.value)} />
              </div>
              <div className="col-md-6">
                <TextField fullWidth label="Nom de la banque" variant="outlined" value={configData.bankName || ''} onChange={(e) => handleChange('bankName', e.target.value)} />
              </div>
            </div>
            
            <div className="row mb-4">
              <div className="col-md-12">
                <TextField fullWidth label="IBAN" variant="outlined" value={configData.iban || ''} onChange={(e) => handleChange('iban', e.target.value)} />
              </div>
            </div>
            
            <div className="row mb-4">
              <div className="col-md-6">
                <TextField fullWidth label="BIC/SWIFT" variant="outlined" value={configData.bicSwift || ''} onChange={(e) => handleChange('bicSwift', e.target.value)} />
              </div>
              <div className="col-md-6">
                <TextField fullWidth label="Adresse de la banque" variant="outlined" value={configData.bankAddress || ''} onChange={(e) => handleChange('bankAddress', e.target.value)} />
              </div>
            </div>
            
            <div className="d-md-flex justify-content-md-center">
            <CustomButton  
             onClick={handleSubmit}
             disabled={isSubmitting}> 
            {isSubmitting ? 'En cours...' : 'Enregistrer'}              
                 
             </CustomButton>
            
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InformationGenerale;

