import axiosInstance from '../axiosConfig';

export const fetchClients = async (projectId, limit, nextToken) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
                query listAccountancyClients($projectId: String!, $limit: Int, $nextToken: String){
                listAccountancyClients(projectId: $projectId, limit: $limit, nextToken: $nextToken){
                    items {
                        companyName
                        siret
                        contactName
                        address
                        phoneNumber
                        email
                    }
                    nextToken
                }
            }
          `
        ,
        variables: {
            projectId, 
            limit,
            nextToken,
        }
  
      });
  
      // console.log(response?.data?.data?.listAccountancyClients);
      return response?.data?.data?.listAccountancyClients;
    } catch (error) {
      console.error("error reuerations des achats:", error);
      throw error;
    }
};
export const UpdateClient = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
              mutation putAccountancyClientConfig($input: AccountancyClientInput) {
            putAccountancyClientConfig(input: $input)
        }
        `
      ,
      variables: {
         input
      }

    });

    console.log(response?.data?.data?.putAccountancyClientConfig);
    return response?.data?.data?.putAccountancyClientConfig;
  } catch (error) {
    console.error("error lors du sauvegarde du client:", error);
    throw error;
  }
};