import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
const menu_data = [
    // {
    //   id:1,
    //   link:'/',
    //   title:'Accueil',
    //   sub_menus:[
    //     {link:'/',title:'Home 1'},        
    //   ]
    // },
    {
      id:2,
      link:'/statut-juridique',
      title:"Créer ma société",
      
    },
    {
      id:3,
      link:'/package-com',
      title:"Gérer ma communication",
     
    },   
    {
      id:5,
      link:'/dashboard',
      title:'Gérer ma société'
    },
   
  ]
function Header() {

  // const token = useSelector((state)=>state.auth.token);
  const token = localStorage.getItem("authToken");
  // console.log(token,'token');
  const navigate=useNavigate();
  const handleMenuClick = (link) => {
    if (!token && link === "/dashboard") {
      navigate("/login");
    } else {
      navigate(link);
    }
  };
  
    return (
        <>       
                <header className="theme-main-menu menu-overlay sticky-menu">
                <div className="inner-content position-relative">
                    <div className="top-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="logo">
                                <Link to="/" className="d-flex align-items-center">
                                    <img className='logo_img' src="/assets/images/logo/logo-pharos.png" alt="logo" />
                                </Link>
                            </div>

                            <nav className="navbar navbar-expand-lg p0 mx-auto" style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <button
                                    
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        {menu_data.map((menu) => (
                                            <li key={menu.id} className={`nav-item dropdown ${menu.title === 'Dashboard' ? 'dashboard-menu' : ''}`}>
                                                <Link to={menu.link} className="nav-link custom-pointer" role="button" onClick={() => handleMenuClick(menu.link)}>
                                                    {menu.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>

                            <nav className="navbar navbar-expand-lg p0">
                                <button
                                    className="navbar-toggler d-block d-lg-none"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNavRight"
                                    aria-controls="navbarNavRight"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNavRight">
                                   <ul className="navbar-nav d-block d-lg-none">
                                            <li className="nav-item dropdown">
                                                <Link to="/statut-juridique" className="nav-link custom-pointer">
                                                    Créer ma société
                                                </Link>
                                            </li>
                                    </ul>
                                    <ul className="navbar-nav d-block d-lg-none">
                                            <li className="nav-item dropdown">
                                                <Link to="/package-com" className="nav-link custom-pointer">
                                                    Gérer ma communication
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul className="navbar-nav d-block d-lg-none">
                                            <li className="nav-item dropdown">
                                                <Link to="/dashboard" className="nav-link custom-pointer">
                                                Gérer ma société
                                                </Link>
                                            </li>
                                        </ul>
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            {token ? (
                                                <Link to="/profile" className="nav-link custom-pointer">
                                                    Mon Compte
                                                </Link>
                                            ) : (
                                                <Link to="/login" className="nav-link custom-pointer">
                                                    Se Connecter
                                                </Link>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            
            <div
                className="modal fade"
                id="loginModal"
                tabIndex={-1}
                aria-hidden="true"
                >
                <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                    <div className="container">
                    <div className="user-data-form modal-content">
                        <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                        <div className="text-center">
                        <h2>Salut, Bon Retour !</h2>
                        <p>Still don't have an account? <Link to="signup.html">Sign up</Link></p>
                        </div>
                        <div className="form-wrapper m-auto">
                        <form action="#" className="mt-10">
                            <div className="row">
                                <div className="col-12">
                                    <div className="input-group-meta position-relative mb-25">
                                        <label>Email*</label>
                                        <input type="email" placeholder="rshdkabir@gmail.com" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-group-meta position-relative mb-20">
                                        <label>Password*</label>
                                        <input type="password" placeholder="Enter Password" className="pass_log_id" />
                                        <span className="placeholder_icon"><span className="passVicon"><img src="images/icon/icon_60.svg" alt="" /></span></span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="agreement-checkbox d-flex justify-content-between align-items-center">
                                        <div>
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">Keep me logged in</label>
                                        </div>
                                        <Link to="#">Forget Password?</Link>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn-eleven fw-500 tran3s d-block mt-20">Login</button>
                                </div>
                            </div>
                        </form>
                        <div className="d-flex align-items-center mt-30 mb-10">
                            <div className="line"></div>                            
                            <span className="pe-3 ps-3">Ou se connecter avec</span>
                            <div className="line"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <Link
                                to="#"
                                className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                            >
                                <img src="images/icon/google.png" alt="" />
                                <span className="ps-2">Connexion avec Google</span>
                            </Link>
                            </div>
                            <div className="col-md-6">
                            <Link
                                to="#"
                                className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                            >
                                <img src="images/icon/facebook.png" alt="" />
                                <span className="ps-2">Connexion avec Microsoft</span>
                            </Link>
                            </div>
                        </div>
                        <p className="text-center mt-10">Don't have an account? <a href="/register" className="fw-500">Sign up</a></p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


        </>
    );
}
  
export default Header;
  