"use client";
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { listAccountancyInvoices, telechargerUrl } from '../../../../../api/Services/invoiceService';
import { ClipLoader } from 'react-spinners';
import { fetchAchats, UserPurchaseS3 } from '../../../../../api/Services/achatService';
import { fetchServicePice } from '../../../../../api/Services/paymentSevice';

const AchatsIndex = ({ setIsOpenSidebar }) => {
  const { projectId } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const projet = useSelector((state) => state.invoice.projets[projectId]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setIsLoading(true);
        const result = await listAccountancyInvoices(projectId, 10, null);
        if (result.items && Array.isArray(result.items)) {
          const invoicesWithUrls = await Promise.all(
            result.items.map(async (invoice) => {
              const urlInvoice = await telechargerUrl(invoice.pdfS3Key);
              return { ...invoice, urlInvoice };
            })
          );
          setInvoices(invoicesWithUrls);
        } else {
          setInvoices([]);
        }
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des factures.');
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) fetchInvoices();
  }, [projectId]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const [purchasesResponse, pricesResponse] = await Promise.all([
          fetchAchats(),
          fetchServicePice(),
        ]);

        const purchases = purchasesResponse.data?.getUserPurchases?.items || [];
        const prices = pricesResponse?.items || [];

        const priceMap = prices.reduce((map, price) => {
          map[price.serviceType] = price;
          return map;
        }, {});

        const mappedData = purchases.map((purchase) => ({
          id: purchase.projectId,
          name: purchase.projectName,
          date: new Date(purchase.creationDate).toLocaleString("fr-FR", { dateStyle: "medium", timeStyle: "short" }),
          price: `${(purchase.services || []).reduce((sum, service) => sum + (priceMap[service]?.amount || 0), 0)} €`,
          location: purchase.invoiceId,
        }));

        setCandidateData(mappedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handlePdfClick = async (id, location) => {
    try {
      const response = await UserPurchaseS3(id, location);
      if (response.url) {
        setPdfUrl(response.url);
        setShowPdf(true);
      } else {
        console.error("URL du PDF non valide");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF:", error);
    }
  };
  const handleDownloadClick = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";
      link.click();
    }
  };
  return (
    <div className="dashboard-body">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
      <h2 className="main-title h2-titel  text-center">Ma facturation &gt; {''}{projet.projectName}{' '} &gt;Liste des factures</h2>

      <div className="bg-white card-box border-20">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader color="#19AF78" />
          </div>
        ) : error ?  (
          <p className="text-danger">{error}</p>
        ) : candidateData.length > 0 ? (
          <div className="table-responsive">
            <table className="table job-alert-table" id="liste-table">
              <thead>
                <tr>
                  <th className="text-center">Projet</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Montant</th>
                  <th className="text-center">Achat PDF</th>
                </tr>
              </thead>
              <tbody>
                {candidateData.map((achat, index) => (
                  <tr key={index}>
                    <td className="text-center">{achat.name || "--"}</td>
                    <td className="text-center">{achat.date || "--"}</td>
                    <td className="text-center">{achat.price || "--"}</td>
                    
                    <td className="text-center">
                      <span
                        className="btn-pdf"
                        onClick={() => handlePdfClick(achat.id, achat.location)}
                      >
                        <i className="fas fa-file-alt text-success" title="PDF"></i>
                        
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

       ) : (
                <div className="text-center mt-4">
                  <p className="text-muted">
                    Aucune donnée disponible pour le moment.
                  </p>
                </div>
              )}
              <div className="d-flex justify-content-between mb-4">
             <Link
                  to={`/ma-facturation-factures-create/${projet.projectId}`}
                  className="dash-btn-two tran3s "
                >
                  Créer une facture
                </Link>
              </div>
      </div>
 
  
      {showPdf && pdfUrl && (
          <div className="pdf-viewer">
            <div className="viewer-header">
              <button onClick={() => setShowPdf(false)} className="close-button">
                x
              </button>
              <button onClick={handleDownloadClick} className="download-button">
                Télécharger
              </button>
            </div>
            <iframe
              src={pdfUrl}
              title="PDF Viewer"
              width="100%"
              height="100%"
              type="application/pdf"
            ></iframe>
          </div>
        )}
       <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
          
      `}</style>
    </div>
  );
};

export default AchatsIndex;
