import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import { Link } from 'react-router-dom';

const AddAssocie = ({ show, handleClose, handleAddAssocie }) => {
  const [formData, setFormData] = useState({
    firstNames: [""],
    name: "",
    dateOfBirthday: "",
    placeOfBirthday: "",
    nationality: "",
    professionalSituation: "",
    socialSecurityNumber: "",
    spouseIdentity: {
      name: "",
      maidenName: "",
      firstNames: [""],
      dateOfBirthday: "",
      placeOfBirthday: "",
      nationality: "",
    },
  });

  const handleChange = (e, isSpouse = false) => {
    const { id, value } = e.target;
    if (isSpouse) {
      const fieldKey = id.replace("spouse", "").charAt(0).toLowerCase() + id.replace("spouse", "").slice(1);
      setFormData((prev) => ({
        ...prev,
        spouseIdentity: {
          ...prev.spouseIdentity,
          [fieldKey]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const handleSubmit = () => {
    handleAddAssocie(formData); 
    handleClose(); 
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un associé</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-md-6">
              <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary>
                  <h4>Associé</h4>
                </AccordionSummary>
                <AccordionDetails>
                  {[
                    { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                    { label: 'Nom', id: 'name', placeholder: 'Nom' },
                    { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                    { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                    { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                    { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                    { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                  ].map((field) => (
                    <div key={field.id} className="mb-4">
                      <TextField
                        label={field.label}
                        fullWidth
                        variant="outlined"
                        value={formData[field.id] || ""}
                        placeholder={field.placeholder}
                        onChange={(e) => handleChange(e)}
                        id={field.id}
                        type={field.type || 'text'}
                        InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                      />
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="col-md-6">
              <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary>
                  <h4>Conjoint(e)</h4>
                </AccordionSummary>
                <AccordionDetails>
                  {[
                    { label: 'Prénom', id: 'spouseFirstNames', placeholder: 'Prénom' },
                    { label: 'Nom', id: 'spouseName', placeholder: 'Nom' },
                    { label: 'Nom de jeune fille', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                    { label: 'Lieu de naissance', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                    { label: 'Date de naissance', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                    { label: 'Nationalité', id: 'spouseNationality', placeholder: 'Nationalité' },
                  ].map((field) => (
                    <div key={field.id} className="mb-4">
                      <TextField
                        label={field.label}
                        fullWidth
                        variant="outlined"
                        value={formData.spouseIdentity[field.id.replace("spouse", "").charAt(0).toLowerCase() + field.id.replace("spouse", "").slice(1)] || ""}
                        placeholder={field.placeholder}
                        onChange={(e) => handleChange(e, true)}
                        id={field.id}
                        type={field.type || 'text'}
                        InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                      />
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
      <Link className="text-reset" to="#" onClick={handleClose} style={{ textDecoration: 'none' }}>
                Annuler
              </Link>
              
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                // disabled={!formData}
              >
                Ajouter
              </Button>
      
      </Modal.Footer>
    </Modal>
  );
};

export default AddAssocie;