import React from 'react';
import DashboardHeader from '../../header';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Typography, Card, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { getStatusTranslation } from '../../../../../Constantes/Constante';

const DemarchesCreate = ({ setIsOpenSidebar }) => {
  const {projectId}= useParams();
 


    const project = useSelector((state) => state.projet_communication?.projets?.[projectId]);
    console.log("project",project);
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <Box align="center" className="main-title h2-title" sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            Démarche récapitulatif
          </Typography>
          <Typography
            variant="h5"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            {project?.projectName || ''}
          </Typography>
        </Box>


        {project && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <Card
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                p: 3,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
             
            <Stack direction="row" alignItems="center" sx={{ mt: 2, flexWrap: 'wrap' }}>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
              >
                Statut du projet :
              </Typography>
              <Typography
                variant="subtitle1"
                color="success.main"
                sx={{
                  ml: 1,
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  wordBreak: 'break-word', 
                  overflowWrap: 'break-word',
                }}
              >
                {getStatusTranslation(project?.projectStatus) ||''}
              </Typography>
            </Stack>


            </Card>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Button
              component={Link}
              to="/rdv"
              variant="contained"
              sx={{
                backgroundColor: 'rgb(25, 175, 120)',
                color: '#fff',
                borderRadius: '20px',
                padding: '10px 20px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgb(20, 140, 90)',
                  color: '#fff',
                },
              }}
            >
              Prendre rdv avec nos experts
            </Button>
          </Card>

          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
          <Button
            component={Link}
            to={`/dashboard-mes-demarches-info-societe/${projectId}`}
            state={{ project }} 
            variant="contained"
            sx={{
              backgroundColor: 'rgb(25, 175, 120)',
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgb(20, 140, 90)',
                color: '#fff',
              },
            }}
          >
            Informations sur la société
          </Button>

          </Card>


          <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Button
              component={Link}
              to="/mes-demarches-document-a-signer"
              variant="contained"
              sx={{
                backgroundColor: 'rgb(25, 175, 120)',
                color: '#fff',
                borderRadius: '20px',
                padding: '10px 20px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgb(20, 140, 90)',
                  color: '#fff',
                },
              }}
            >
              Documents à signer
            </Button>
          </Card>
        </Box>
      </div>
    </div>
  );
};

export default DemarchesCreate;
