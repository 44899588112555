import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { TextField, Radio, FormControlLabel, Button } from '@mui/material';
import { searchEntreprise } from '../../../../../api/Services/searchEnrepriseService';
import { useDispatch } from 'react-redux';
import { setEntreprise } from '../../../../../redux/entrepriseSlice';

const RechecherSociete = ({ ShowSearchModal, handleCloseModalSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntreprise, setSelectedEntreprise] = useState(null);
  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (searchQuery.length > 3) {
        setLoading(true);
        try {
          const results = await searchEntreprise(searchQuery);
          setSearchResults(results?.items || []);
        } catch (error) {
          console.error("Erreur lors de la recherche d'entreprise:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults([]);
      }
    }
  };

  const handleSelectEntreprise = () => {
    if (selectedEntreprise) {
      dispatch(setEntreprise(selectedEntreprise));
      handleCloseModalSearch();
    }
  };

  return (
    <Modal show={ShowSearchModal} onHide={handleCloseModalSearch} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Recherche avancée</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>
            A partir du registre de l’état, notre outil peut retrouver vos clients en fonction d’une partie du nom, du numéro de SIRET ou du nom du dirigeant.
          </p>

          <div className='row justify-content-center'>
            <div className="mb-3 col-md-12">
              <TextField
                fullWidth
                label="Nom d'entreprise, dirigeant ou N° Siret"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>

          {loading && <p>🔄 Recherche en cours...</p>}

          {searchResults.length > 0 && (
            <ul className="list-group mb-3">
              {searchResults.map((enterprise, index) => (
                <div className="text-start mb-3" key={index}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEntreprise?.siren === enterprise.siren}
                        onChange={() => setSelectedEntreprise(enterprise)}
                      />
                    }
                    label={
                      <>
                        <strong>{enterprise.companyName}</strong><br />
                        {enterprise.siren}<br />
                        {enterprise.name} {enterprise.firstName}<br />
                        {enterprise.address}
                      </>
                    }
                  />
                </div>
              ))}
            </ul>
          )}

          <div className='d-flex flex-column align-items-center mb-3'>
            <div className="d-inline-flex col-4 align-items-center justify-content-center" style={{ gap: '30px' }}>
              <Link className="text-reset" to="#" onClick={handleCloseModalSearch} style={{ textDecoration: 'none' }}>
                Annuler
              </Link>
              <Button
                variant="contained"
                color="success"
                onClick={handleSelectEntreprise}
                disabled={!selectedEntreprise}
              >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RechecherSociete;
