'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import CreateClientFournisseur from './Create_client_fournisseur';

const CreateCl = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (
    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <CreateClientFournisseur setIsOpenSidebar={setIsOpenSidebar}/>
    </div>   
  );
};

export default CreateCl;