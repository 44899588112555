import React, { useState ,useEffect} from 'react';
import Select from "react-select";

import { FaFolder, FaTrash, FaEllipsisV, FaFile,FaChevronDown, FaChevronUp, FaArrowLeft  } from 'react-icons/fa';
import { Modal, Button, Card ,Form} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DashboardHeader from '../header';
import { getWebpackageOrder } from '../../../../api/Services/webPackageService';
import { copyDocument, createFoler, deleteDocument, downloadDocument, fetchSearchDocument, getDMSDocuments, renameDocument } from '../../../../api/Services/gedService';
import Swal from 'sweetalert2';
import UploadFileModal from './Modals/add-file';
import PartageDocument from './Modals/PartageDocument';
import DeplacerDocument from './Modals/DeplacerDocument';
import RenameDocument from './Modals/RenameDocument';
import { Pagination, styled } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import ActionMenu from './ActionMenu';

const Ged = ({ setIsOpenSidebar }) => {
  const{projectId}=useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModalFolder, setShowModalFolder] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowModalFolder = () => setShowModalFolder(true);
  const handleCloseModalFolder = () => setShowModalFolder(false);
  const handleShowDeplacer = (documentId) => { setSelectedDocumentId(documentId); setShowModalDeplcer(true)};
  const [showDeplacer,setShowModalDeplcer]=useState(false);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
  const [folderName, setFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFoldersLoaded, setIsFoldersLoaded] = useState(false); 


  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [showPartage, setShowPartage] = useState(false);
  const handleShowPartage = (documentId) => {
    setSelectedDocumentId(documentId);
    setShowPartage(true); 
  };
  const handleClosePartage = () => {
    setShowPartage(false);
    setSelectedDocumentId(null); 
  };

  const [project,setProject]=useState({});
  const [folders, setFolders] = useState([]);
  const [parentFolderId, setParentFolderId] = useState("root"); 
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedSubFolder, setSelectedSubFolder] = useState(null);
  const [searchResults, setSearchResults] = useState([]); 
  const [noResults, setNoResults] = useState(false);
 const [showRenameModal, setShowRenameModal] = useState(false);
 const [selectedItem, setSelectedItem] = useState(null);
 const [searchTerm, setSearchTerm] = useState('');
 const [openFolders, setOpenFolders] = useState({});
 const [navigationPath, setNavigationPath] = useState([]);

  const handleFolderClick = (folder) => {
    if (folder.type=='FOLDER'){
      setSelectedFolder(folder);
      setSelectedSubFolder(null);
      setNavigationPath((prev) => [...prev, folder]);
    }
   
  };

  const handleSubFolderClick = (subFolder) => {
    if (subFolder.type=='FOLDER'){
    setSelectedSubFolder(subFolder);
    setSelectedFolder(subFolder);
    setNavigationPath((prev) => [...prev, subFolder]);
    }
  };

  const handleNavigationClick = (index) => {
    const newPath = navigationPath.slice(0, index + 1);
    setNavigationPath(newPath);
    if (newPath.length > 0) {
      setSelectedFolder(newPath[newPath.length - 1]);
      setSelectedSubFolder(null);
    } else {
      setSelectedFolder(null);
      setSelectedSubFolder(null);
    }
  };

  const handleGoBack = () => {
    if (navigationPath.length > 0) {
      const newPath = navigationPath.slice(0, navigationPath.length - 1);
      setNavigationPath(newPath);
      if (newPath.length > 0) {
        setSelectedFolder(newPath[newPath.length - 1]);
        setSelectedSubFolder(null);
      } else {
        setSelectedFolder(null);
        setSelectedSubFolder(null);
      }
    }
  };

  const renderBreadcrumb = () => {
    return (
      <div className="breadcrumb">
        <button onClick={handleGoBack} style={{ marginRight: '10px' }}><FaArrowLeft size={15} /> 
        </button>
        <span onClick={() => handleNavigationClick(-1)} style={{ cursor: 'pointer' }}>Ma GED</span>
        {navigationPath.map((folder, index) => (
          <span key={index} onClick={() => handleNavigationClick(index)} style={{ cursor: 'pointer' }}>
            {' > '} {folder.name}
          </span>
        ))}
      </div>
    );
  };
 const toggleFolder = (folderId) => {
   setOpenFolders((prev) => ({
     ...prev,
     [folderId]: !prev[folderId], 
   }));
 };
 const handleRootClick = () => {
  setSelectedFolder(null); 
  setSelectedSubFolder(null);
  setParentFolderId("root"); 
};

 const CustomPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: '#000',
  },
  '& .Mui-selected': {
    backgroundColor: 'rgb(25, 175, 120)!important',
    color: '#fff',
  },
  '& .MuiPaginationItem-ellipsis': {
    color: '#000',
  },
});
  
 const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const paginateFolders = (folders, page, itemsPerPage) => {
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return folders.slice(startIndex, endIndex);
};
const paginatedFolders = paginateFolders(folders, currentPage, itemsPerPage);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


 

  const fetchFolders = async () => {
    setIsLoading(true);
    try {
      const fetchAllFolders = async (parentId, limit, nextToken = null) => {
        const response = await getDMSDocuments(projectId, parentId, limit, nextToken);
        const items = response?.items || [];
        const nextPageToken = response?.nextToken || null;

        let allFolders = [];

        const currentFolders = await Promise.all(
          items.map(async (item) => ({
            name: item.documentName,
            type: item.documentType,
            id: item.documentId,
            subfolders: await fetchPaginatedSubfolders(item.documentId, limit),
          }))
        );

        allFolders = [...allFolders, ...currentFolders];

        if (nextPageToken) {
          const nextFolders = await fetchAllFolders(parentId, limit, nextPageToken);
          allFolders = [...allFolders, ...nextFolders];
        }

        return allFolders;
      };

      const fetchPaginatedSubfolders = async (parentId, limit) => {
        let allSubfolders = [];
        let token = null;

        do {
          const response = await fetchAllFolders(parentId, limit, token);
          allSubfolders = [...allSubfolders, ...response];
          token = response?.nextPageToken || null;
        } while (token);

        return allSubfolders;
      };

      const structuredFolders = await fetchAllFolders("root", itemsPerPage);
      setFolders(structuredFolders);
    } catch (error) {
      console.error("Erreur lors de la récupération des documents :", error);
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };


useEffect(() => {
  const fetchProject = async () => {
    try {
      const response = await getWebpackageOrder(projectId); 
      setProject(response?.data?.getWebpackageOrder || {});
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  fetchProject();
    fetchFolders();
  }, [projectId, parentFolderId]);



const handleFolderCreation = async () => {
  if (!folderName.trim()) {
    Swal.fire({
      icon: "warning",
      title: "Validation",
      text: "Veuillez saisir un nom pour le dossier.",
    });
    return;
  }

  try {
    const result = await createFoler(projectId, parentFolderId, folderName.trim());
    console.log("Dossier créé avec succès :", result);

    Swal.fire({
      icon: "success",
      title: "Succès",
      text: "Le dossier a été créé avec succès.",
    });
   
    handleCloseModalFolder();
    await fetchFolders();
  } catch (error) {
    console.error("Erreur lors de la création du dossier :", error);

    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Une erreur est survenue lors de la création du dossier.",
    });
  }
};

const handleDeleteDocument = async (projectId, id) => {
  console.log("avant evoi",id );
  try {
   
    const response = await deleteDocument(projectId, id);
     console.log('delete',response);
   
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Document supprimé avec succès.",
      });
      await fetchFolders();
      setFolders((prevFolders) =>
        prevFolders.filter((folder) => folder.id !== id)
      );
      
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Erreur lors de la suppression du document.'",
    });

  }
};


const renderFolders = (subfolders, page = 1) => {
  const paginatedSubfolders = paginateFolders(subfolders, page, itemsPerPage);

  return (
    <ul>
      {paginatedSubfolders.map((subfolder) => (
        <li key={subfolder.id}>
          {subfolder.type === 'FOLDER' ? <FaFolder /> : <FaFile />} {subfolder.name}
          {subfolder.subfolders && subfolder.subfolders.length > 0 && (
            renderFolders(subfolder.subfolders)
          )}
        </li>
      ))}
    </ul>
  );
};


const handleCopyDocument = async (projectId, id) => {
  console.log("avant evoi",id );
  try {
   
    const response = await copyDocument(projectId, id);
     console.log('copy',response);
   
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Document copy avec succès.",
      });
      await fetchFolders();
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Erreur lors de la copy du document.'",
    });

  }
};

/*partie search */


  
const handleSearchDocument = async () => {
  try {
    if (!searchTerm.trim()) {
      setSearchResults([]); 
      setNoResults(false); 
      return;
    }

    setIsLoading(true);
    setNoResults(false);

    const results = await fetchSearchDocument(projectId, searchTerm);
    console.log('Résultats de la recherche:', results);

    setSearchResults(results.items || []);
    setNoResults(results.items && results.items.length === 0);
  } catch (error) {
    console.error('Erreur lors de la recherche:', error);
    setSearchResults([]); 
    setIsLoading(false);
  }
};

/*dowloand file*/
const downloadFile = async (projectId, documentId) => {
  console.log(documentId);
  try {
    const { url } = await downloadDocument(projectId, documentId);
    console.log("URL de téléchargement:", url);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = ''; 
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch (error) {
    console.error('Erreur lors du téléchargement du fichier:', error);
  }
};

const renderFolderOptions = (folders, level = 0) => {
  const options = [];

  folders.forEach((folder) => {

        if (folder.type === "FOLDER") {
          options.push({
            value: folder.id,
            label: (
              <div style={{ display: "flex", alignItems: "center", marginLeft: level * 10 }}>
                <FaFolder style={{ marginRight: "5px" }} />
                {folder.name}
              </div>
            ),
          });
        }
      
  

    if (folder.subfolders && folder.subfolders.length > 0) {
      options.push(...renderFolderOptions(folder.subfolders, level + 1));
    }
  });

  return options;
};


//  debutpartie rename 
const handleShowRenameModal = (item) => {
  setSelectedItem(item); 
  setShowRenameModal(true); 
};

const handleCloseRenameModal = () => {
  setShowRenameModal(false); 
};

const handleRename = async (projectId, documentId, newDocumentName) => {
  try {
    await renameDocument(projectId, documentId, newDocumentName);
  } catch (error) {
    console.error('Error renaming document:', error);
  }
};
//fin 


  return (
    <div className="dashboard-body ">
      <div className="position-relative w-100 ">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> GED  &gt;{" "}{project.projectName}</h2>

       <div className="row h-90" style={{ height: "90%" }}>

         <div className="sidebar col-3">
            <h2 className="d-flex flex-column main-title h2-titel">Pharos GED</h2>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                <ClipLoader size={50} color="#FFFFFF" />
              </div>
            ) : (

              <ul className="folder-tree">
                <div onClick={handleRootClick} style={{ cursor: "pointer" }}>
                  /Ma GED
                  {isFoldersLoaded && folders.length === 0 ? (
                    <li>Aucun dossier disponible</li>
                  ) : (
                    <ul>
                      {folders
                        .filter(folder => folder.type === "FOLDER")
                        .map((folder) => (
                          <div key={folder.id}>
                            <div
                              onClick={() => toggleFolder(folder.id)}
                              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                            >
                              <span style={{ marginRight: "10px" }}>
                                {openFolders[folder.id] ? <FaChevronUp /> : <FaChevronDown />}
                              </span>
                              <FaFolder style={{ marginRight: "5px" }} />
                              {folder.name}
                            </div>
                            {folder.subfolders &&
                              folder.subfolders.length > 0 &&
                              openFolders[folder.id] && (
                                <div>{renderFolders(folder.subfolders)}</div>
                              )}
                          </div>
                        ))}
                    </ul>
                  )}
                </div>
                <li>
                  <FaTrash />{" "}
                  <Link to={`/ged-corbeille/${project.projectId}`} style={{ textDecoration: "none" }}>
                    Corbeille
                  </Link>
                </li>
              </ul>

            )}
          </div>

          <div className="card content-area col-9">
            <div className="search-form mt-0 mb-20" style={{ position: "relative" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher dans ma GED..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchDocument();
                  }
                }}
                style={{ paddingLeft: "35px" }}
              />
              <button
                onClick={handleSearchDocument}
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: "0",
                  cursor: "pointer",
                }}
              >
                <img
                  src="/assets/dashboard/images/icon/icon_10.svg"
                  alt="icon"
                  className="lazy-img"
                  style={{
                    height: "16px",
                  }}
                />
              </button>
            </div>
            <div className="actions d-flex align-items-center">
              <button onClick={handleShowModalFolder}>Créer un dossier</button>
              <button onClick={handleShowModal}>Charger un fichier</button>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                <ClipLoader size={50} color="#19AF78" />
              </div>
            ) : (
              <>
                {renderBreadcrumb()}
                <div className="col-md-12">
                  {searchResults.length > 0 ? (
                    <div className="row">
                      {searchResults.map((folder, index) => (
                        <div key={index} className="col-md-4 mb-3">
                          <Card>
                            <Card.Body className="position-relative">
                              <Card.Title className="fadeInUp">
                                {folder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {folder.documentName}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </div>
                  ) : noResults ? (
                    <p>Aucun résultat trouvé.</p>
                  ) : selectedFolder ? (
                    
                    selectedFolder.subfolders && selectedFolder.subfolders.length > 0 ? (
                      <div className="row">
                        {selectedFolder.subfolders.map((item, index) => (
                          <div key={index} className="col-md-4 mb-3" onClick={() => handleSubFolderClick(item)}>
                            <Card>
                              <Card.Body className="position-relative">
                                <Card.Title className="fadeInUp">
                                  {item.type === 'FOLDER' ? <FaFolder /> : <FaFile />} {item.name}
                                </Card.Title>
                                <ActionMenu
                                  item={item}
                                  projectId={projectId}
                                  onRename={handleShowRenameModal}
                                  onCopy={handleCopyDocument}
                                  onMove={handleShowDeplacer}
                                  onShare={handleShowPartage}
                                  onDownload={downloadFile}
                                  onDelete={handleDeleteDocument}
                                />
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Aucun sous-dossier dans ce dossier.</p>
                    )
                  ) : folders.length > 0 ? (
                    <div className="row">
                      {paginatedFolders.map((item, index) => (
                        <div key={index} className="col-md-4 mb-3" onClick={() => handleFolderClick(item)}>
                          <Card>
                            <Card.Body className="position-relative">
                              <Card.Title className="fadeInUp">
                                {item.type === 'FOLDER' ? <FaFolder /> : <FaFile />} {item.name}
                              </Card.Title>
                              <ActionMenu
                                item={item}
                                projectId={projectId}
                                onRename={handleShowRenameModal}
                                onCopy={handleCopyDocument}
                                onMove={handleShowDeplacer}
                                onShare={handleShowPartage}
                                onDownload={downloadFile}
                                onDelete={handleDeleteDocument}
                              />
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        
       
      
        </div>
       <div className="mt-3 d-flex justify-content-end">
          <CustomPagination
            count={Math.ceil(folders.length / itemsPerPage)} 
            page={currentPage} 
            onChange={handlePageChange} 
            shape="rounded"
          />
          
        </div>

    </div>
    <UploadFileModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        projectId={projectId}
        folders={folders}
        onUpdate={() => fetchFolders()}
      />
     {showPartage && (
        <PartageDocument
          showPartage={showPartage}
          handleClosePartage={handleClosePartage}
          projectId={projectId}
          documentIds={selectedDocumentId ? [selectedDocumentId] : []}
        />
      )}
      {showDeplacer && (
        <DeplacerDocument
          showDeplacer={showDeplacer}
          handleCloseDeplacer={handleCloseDeplacer}
          projectId={projectId}
          documentId={selectedDocumentId}
          onUpdate={() => fetchFolders()}
       />
      )}
      {selectedItem && (
        <RenameDocument
          show={showRenameModal}
          onHide={handleCloseRenameModal}
          item={selectedItem}
          projectId={projectId}
          onRename={handleRename}
          fetchFolders={fetchFolders}
        />
      )}
     <Modal show={showModalFolder} onHide={handleCloseModalFolder}  size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Créer un Dossier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Nom dossier :</Form.Label>
          <Form.Control
            type="text"
            placeholder="Entrez le nom du dossier"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
          <Form.Group controlId="parentFolder">
            <Form.Label>Dossier Parent :</Form.Label>
            <Select
              options={renderFolderOptions(folders)}
              value={renderFolderOptions(folders).find((opt) => opt.value === parentFolderId)}
              onChange={(selectedOption) => setParentFolderId(selectedOption?.value || "root")}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalFolder}>
            Fermer
          </Button>
          <Link
            to=""
            style={{
              backgroundColor: "#19AF78",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              textDecoration: "none",
              transition: "all 0.3s ease",
            }}
            onClick={handleFolderCreation}
          >
            Créer
          </Link>
        </Modal.Footer>
      </Modal>
     
     <style>{`
        .dashboard-body {
          display: flex;
        }
        .sidebar {
          background-color: rgb(25, 175, 120) !important;
          color: white;
          padding: 20px;
         
        }
         
        .folder-tree {
          list-style: none;
          padding-left: 0;
        }
       
        .content-area {
          flex: 1;
          padding: 20px;
        }
        .search-bar input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
        .actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }
        .actions button {
          padding: 10px 15px;
          background-color: #ccc;
          border: none;
          border-radius: 5px;
        }
          .actions button:hover {
          background-color: rgb(25, 175, 120) !important;
          color: white !important; /* Utilisez "color" au lieu de "text-color" */
        }
        .breadcrumb {
          margin-bottom: 20px;
        }
          
    @media (max-width: 768px) {
  .sidebar {
    width:100%;
  }
  .content-area {
    width:100%;
  }

  .breadcrumb {
    font-size: 14px;
  }

  
    
}


 
 `}</style>
    </div>

  );
  
};

export default Ged;

