"use client";
import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../header";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addEventsInCalendar, deleteEventInCalendar, getEventsInCalendar } from "../../../../api/Services/emailService";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaArrowLeft } from 'react-icons/fa'; 
import fr from "date-fns/locale/fr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const locales = {
  fr: fr,
};

const EmailCalendar = ({ setIsOpenSidebar }) => {
  const { emailAddress } = useParams();  
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchDate, setSearchDate] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false); 
  const [selectedEvent, setSelectedEvent] = useState(null); 
  const [modalDetailIsOpen, setModalDetailIsOpen] = useState(false);
  const [errorMessage,setErrorMessage]=useState("");

  const [newEvent, setNewEvent] = useState({
    startTimeIso: "",
    endTimeIso: "",
    subject: "",
    body: "",
    destinationEmailAddresses: [""],
    attachments: [],
  });

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getEventsInCalendar(emailAddress, format(searchDate, "yyyy-MM-dd")); 
      const items = response?.data?.getEventsInCalendar?.items ?? [];

      if (items.length > 0) {
        const apiEvents = items.map((event) => {
          const startDate = new Date(event.start); 
          const endDate = new Date(event.end); 

          return {
            itemId: event.itemId,
            title: event.subject || "Sans titre",  
            start: startDate, 
            end: endDate,
            location: event.location || "Aucune localisation", 
            inviteAttendees: event.inviteAttendees || []
          };
        });

        setEvents(apiEvents);
        setFilteredEvents(apiEvents); 
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des événements :", error);
    }
  }, [emailAddress, searchDate]);

  useEffect(() => {
    fetchEvents();
  }, [emailAddress, searchDate, fetchEvents]);

  const localizer = dateFnsLocalizer({
    format: (date, formatString) => format(date, formatString, { locale: fr }),
    parse: (dateString, formatString) => parse(dateString, formatString, new Date(), { locale: fr }),
    startOfWeek: () => new Date(0),
    getDay: (date) => date.getDay(),
    locales: locales,
  });

  const handleSearchDateChange = (selectedDate) => {
    setSearchDate(selectedDate); 
  
    if (selectedDate) {
      const filtered = events.filter(
        (event) =>
          event.start.getFullYear() === selectedDate.getFullYear() &&
          event.start.getMonth() === selectedDate.getMonth() &&
          event.start.getDate() === selectedDate.getDate()
      );
      setFilteredEvents(filtered); 
    } else {
      setFilteredEvents(events); 
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const validateEventDates = () => {
    const startDate = newEvent.startTimeIso;
    const endDate = newEvent.endTimeIso;
  
    if (startDate >= endDate) {
      setErrorMessage("La date de début doit être antérieure à la date de fin.");
      return false;
    }
    setErrorMessage("");
    return true;
  };
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event); 
    setModalDetailIsOpen(true);
  };

  const handleCloseModalDetail = () => {
    setModalDetailIsOpen(false);
    setSelectedEvent(null);  
  };

  const handleDeleteEvent = async () => {
    try {
      const response = await deleteEventInCalendar(emailAddress, selectedEvent.itemId);
      console.log("response", response);
  
      if (response?.data?.deleteEventsInCalendar === null) {
        Swal.fire({
          title: 'Événement supprimé',
          text: 'L\'événement a été supprimé avec succès.',
          icon: 'success',
        });
        setModalDetailIsOpen(false);
        await fetchEvents(); 
      } else {
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'événement:', error);
      Swal.fire({
        title: 'Erreur',
        text: 'Une erreur est survenue lors de la suppression.',
        icon: 'error',
      });
    }
  };

  const handleSubmit = async () => {
    if (!validateEventDates(newEvent.start, newEvent.end)) {
      return; 
    }
    if (newEvent.startTimeIso && newEvent.endTimeIso) {
      const formattedStartTime = new Date(newEvent.startTimeIso).toISOString();
      const formattedEndTime = new Date(newEvent.endTimeIso).toISOString();
  
      const updateEvent = {
        input: {
          ...newEvent,
          startTimeIso: formattedStartTime,
          endTimeIso: formattedEndTime,
        },
      };
  
      try {
        const response = await addEventsInCalendar(emailAddress, updateEvent.input);
        Swal.fire({
          title: 'Ajout de l\'événement',
          text: 'L\'événement a été ajouté avec succès',
          icon: 'success',
        });
  
        setModalIsOpen(false);
        await fetchEvents(); 
      } catch (error) {
        console.error('Erreur lors de l\'ajout de l\'événement:', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur est survenue, veuillez réessayer.',
          icon: 'error',
        });
      }
    } else {
      Swal.fire({
        title: 'Erreur',
        text: 'Les horaires de l\'événement ne sont pas valides.',
        icon: 'warning',
      });
    }
  };
  
  const messages = {
    today: "Aujourd'hui",
    previous: "Précédent",
    next: "Suivant",
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    agenda: "Agenda",
    date: "Date",
    time: "Heure",
    event: "Événement",
    noEventsInRange: "Aucun événement dans cette période.",
  };

  return (
    <div className="dashboard-body">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

      <div className="position-relative">
        <div className="row d-flex">
          <div className="col-xl-12 col-lg-12 d-flex flex-column">
            <Link to={`/boites-mail-dashb/${emailAddress}`} style={{ position: 'start', top: '0', right: '0' }}>
              <FaArrowLeft size={20} /> {" "}Précédent
            </Link>           
            <div className="user-activity-chart bg-white border-20 mt-30 h-100">
              <h4 className="dash-title-two">Calendrier</h4>
              <div className="p-4">
              <div className="col-md-3 mb-4">
                  <label htmlFor="search-date" className="form-label">
                    Rechercher par date:
                  </label>
                </div>
                <div className="col-md-4 mb-4">
                 <DatePicker
                    selected={searchDate}
                    onChange={handleSearchDateChange}
                    dateFormat="dd/MM/yyyy" 
                    locale={fr}
                    id="search-date"
                    className="form-control"
                    calendarClassName="my-custom-calendar"
                  />
                </div>

                <div className="calendar-container mt-4">
                  <div className="col-md-2 mb-4">
                    <button
                      onClick={handleOpenModal}
                      className="compose_button mb-3 col-5"
                    >
                      Ajouter un événement
                    </button>
                  </div>

                  <Calendar
                    localizer={localizer}
                    messages={messages} 
                    events={filteredEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, width: "100%" }}
                    onSelectEvent={handleEventClick}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalIsOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouvel événement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Sujet
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={newEvent.subject}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="startTimeIso" className="form-label">
                Heure de début
              </label>
              <input
                type="datetime-local"
                id="startTimeIso"
                name="startTimeIso"
                value={newEvent.startTimeIso}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="endTimeIso" className="form-label">
                Heure de fin
              </label>
              <input
                type="datetime-local"
                id="endTimeIso"
                name="endTimeIso"
                value={newEvent.endTimeIso}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="body" className="form-label">
                Description
              </label>
              <textarea
                id="body"
                name="body"
                value={newEvent.body}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="destinationEmailAddresses" className="form-label">
                Emails
              </label>
              <input
                type="text"
                id="destinationEmailAddresses"
                name="destinationEmailAddresses"
                value={newEvent.destinationEmailAddresses.join(",")}
                onChange={(e) =>
                  setNewEvent({
                    ...newEvent,
                    destinationEmailAddresses: e.target.value.split(","),
                  })
                }
                className="form-control"
              />
            </div>
            {errorMessage && (
                <div className="alert alert-danger mt-2">{errorMessage}</div>
              )}
            <button type="button" className="compose_button mb-3 col-5 " onClick={handleSubmit}>
              Ajouter l'événement
            </button>
          </form>
        </Modal.Body>
      </Modal>
      
      <Modal show={modalDetailIsOpen} onHide={handleCloseModalDetail}>
        <Modal.Header closeButton>
          <Modal.Title>Détails de l'événement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <form>
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Sujet
                </label>
                <input
                  type="text"
                  id="subject"
                  value={selectedEvent.title}
                  readOnly
                  className="form-control"
                />
              </div>
              
              <div className="mb-3">
                <label htmlFor="startTime" className="form-label">
                  Heure de début
                </label>
                <input
                  type="datetime-local"
                  id="startTime"
                  value={new Date(selectedEvent.start).toISOString().slice(0, 16)}
                  readOnly
                  className="form-control"
                />
              </div>
              
              <div className="mb-3">
                <label htmlFor="endTime" className="form-label">
                  Heure de fin
                </label>
                <input
                  type="datetime-local"
                  id="endTime"
                  value={new Date(selectedEvent.end).toISOString().slice(0, 16)}
                  readOnly
                  className="form-control"
                />
              </div>
              
              <div className="mb-3">
                <label htmlFor="location" className="form-label">
                  Localisation
                </label>
                <input
                  type="text"
                  id="location"
                  value={selectedEvent.location || 'Aucune localisation'}
                  readOnly
                  className="form-control"
                />
              </div>
            
              <div className="mb-3">
                  <label htmlFor="attendees" className="form-label">
                      Participants :
                  </label>
                  {selectedEvent.inviteAttendees && selectedEvent.inviteAttendees.length > 0 ? (
                      selectedEvent.inviteAttendees.map((attendee, index) => (
                        <div key={index} className="attendee-card mb-3">
                          <label htmlFor={`attendee-name-${index}`} className="form-label">
                            {`Participant ${index + 1}`}
                          </label>
                          <div className="attendee-info">
                            <label htmlFor={`attendee-name-${index}`} className="form-label">Nom</label>
                            <input
                              type="text"
                              id={`attendee-name-${index}`}
                              value={attendee.name}
                              readOnly
                              className="form-control mb-2"
                            />
                            <label htmlFor={`attendee-email-${index}`} className="form-label">Email</label>
                            <input
                              type="email"
                              id={`attendee-email-${index}`}
                              value={attendee.email}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <input
                      type="text"
                      value="Aucun participant"
                      readOnly
                      className="form-control"
                      />
                  )}
              </div>
              <button type="button" className="btn btn-danger" onClick={handleDeleteEvent}>
                Supprimer l'événement
              </button>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmailCalendar;
