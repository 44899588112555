'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import FacturesCreate from './factureCreate';

const EmployDashboardProfilePage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (
    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <FacturesCreate setIsOpenSidebar={setIsOpenSidebar}/>
    </div>   
  );
};

export default EmployDashboardProfilePage;