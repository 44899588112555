import React from 'react';
import DashboardHeader from '../header';


const AchatCreate = ({setIsOpenSidebar}) => {    
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className="main-title text-center h2-titel">Créer un achat</h2> 
        <div className="bg-white card-box border-20 mb-20">                        
            <div className="row mt-20">
                <div className="col-md-12">
                    <h4>Date: 02/08/2024 <br /> numéro: 1249458</h4>
                </div>
                <div className="col-md-12 mt-10">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="dash-input-wrapper mb-30">
                            <label htmlFor="">service</label>
                            <input type="text" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="dash-input-wrapper mb-30">
                            <label htmlFor="">produits</label>
                            <input type="text"  />
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="dash-input-wrapper mb-30">
                            <label htmlFor="">Montant HT </label>
                            <input type="text" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="dash-input-wrapper mb-30">
                            <label htmlFor="">Montant TTC</label>
                            <input type="text"  />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row mt-50">                
                <div className="col-md-6">
                    <h4>Montant TTC : 15000</h4>                    
                </div>
            </div>

            <div className="button-group d-flex justify-content-center mt-30">
                <a href="#" className="dash-btn-two tran3s me-3">Valider</a>                
            </div>
        </div>
       

      </div>
    </div>
  );
};

export default AchatCreate;