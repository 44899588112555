import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setBackgroundColor, setProject, setTextColor } from "../../../redux/packageSlice";
import {Typography} from "@mui/material";
const CandidateV3Area = () => {
  const dispatch = useDispatch();
  const {projectId}=useParams();
  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});

  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(projectData.backgroundColor);
  const [selectedTextColor, setSelectedTextColor] = useState(projectData.textColor);

  const palette1 = [
    "#213A57", "#0B6477", "#14919B", "#1E88E5", "#3949AB",
    "#5E35B1", "#6A1B9A", "#7B1FA2", "#8E24AA", "#D81B60"
  ]; 
  
  const palette2 = [
    "#F4E7FB", "#F3DCDC", "#F5BCBA", "#FFD54F", "#FFB74D",
    "#FF8A65", "#D4E157", "#AED581", "#81C784", "#4DB6AC"
  ]; 
  
  const colorNameMapping = {
    "#213A57": "navy",
    "#0B6477": "teal",
    "#14919B": "cyan",
    "#1E88E5": "blue",
    "#3949AB": "indigo",
    "#5E35B1": "violet",
    "#6A1B9A": "deep purple",
    "#7B1FA2": "purple",
    "#8E24AA": "magenta",
    "#D81B60": "crimson",
    "#F4E7FB": "lavender",
    "#F3DCDC": "pink",
    "#F5BCBA": "salmon",
    "#FFD54F": "yellow",
    "#FFB74D": "orange",
    "#FF8A65": "coral",
    "#D4E157": "lime",
    "#AED581": "light green",
    "#81C784": "mint",
    "#4DB6AC": "aqua"
  };
  
  const handleBackgroundColorChange = (color) => {
    const colorName = colorNameMapping[color];
    setSelectedBackgroundColor(colorName);
     dispatch(
          setProject({
            projectId,
            data: {
              website: {
                globalDetails: {
                  backgroundColor: colorName,
                },
              },
            },
          })
        );

  };

  const handleTextColorChange = (color) => {
    const colorName = colorNameMapping[color];
    setSelectedTextColor(colorName);
    dispatch(
      setProject({
        projectId,
        data: {
          website: {
            globalDetails: {
              textColor: colorName,
            },
          },
        },
      })
    );
  };

  console.log(selectedBackgroundColor,selectedTextColor);

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
        <div className="d-flex mb-3">
                                                                  <Link to={`/package-com9/${projectId}`} 
                                                                          style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                          className="d-flex justify-content-around">
                                                                      <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                                                  </Link>
                                                                  <Link to="#"
                                                                          style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                                          className="d-flex justify-content-center">
                                                      
                                                                      <Typography component="span" className="d-flex justify-content-center text-center">13/15</Typography>
                                                                  </Link>
                                                                  <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                               </div>
         
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">Choisissez vos couleurs</h3>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 mb-4">
              <div className="card p-3 text-center">
                <h5 className="mb-3">Couleur pour l'arrière-plan</h5>
                <div className="d-flex justify-content-around flex-wrap">
                  {palette1.map((color) => (
                    <button                                  
                      key={color}
                      className="color-swatch"
                      style={{
                        backgroundColor: color,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                          selectedBackgroundColor === colorNameMapping[color]
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleBackgroundColorChange(color)}
                    />
                  ))}
                </div>
                <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedBackgroundColor || "Aucune"}</strong>
                </p>
               
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card p-3 text-center">
                <h5 className="mb-3">Couleur pour le texte</h5>
                <div className="d-flex justify-content-around flex-wrap">
                  {palette2.map((color) => (
                    <button
                      key={color}
                      className="color-swatch"
                      style={{
                        backgroundColor: color,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                        selectedTextColor === colorNameMapping[color]
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                     
                      onClick={() => handleTextColorChange(color)}
                    />
                  ))}
                  
                </div>
                <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedTextColor || "Aucune"}</strong>
                </p>
               
              </div>
            </div>
            <div className="col-12 text-center mb-4">
              <button
                type="submit"
                className="btn-eleven fw-500 tran3s d-block mt-20"
              >
                <Link to={`/package-com11/${projectId}`} className="d-block">
                  Validez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
