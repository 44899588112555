import React from "react";
import { Link } from "react-router-dom";
import { FaUserAlt, FaUserFriends, FaSlack } from 'react-icons/fa';

const CandidateV3Area = () => {
    return (
        <>

<div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>

                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                    <div className="d-flex mb-1">
                                                                                  <Link to="//activite-statut-p1"
                                                                                          style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                                          className="d-flex justify-content-around">
                                                                                      <span className="precedent-text">{"< Précédent"}</span>
                                                                                  </Link>
                                                                                  <Link to="#"
                                                                                          style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                                                                          className="d-flex justify-content-center">
                                                                      
                                                                                      <span className="d-flex justify-content-center text-center">5/5</span>
                                                                                  </Link>
                                                                                  <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                                              </div>
                    <div className="text-center">
                        
                        <div className="title-one text-center mb-1 lg-mb-2

 ">
                            <h3 className="fw-600">Que détiendra la société ?</h3>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <Link to="/activite-statut-s2" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    <FaUserAlt className="me-2" /> Des titres d’autres sociétés
                                </button>
                            </Link>
                            <Link to="/activite-statut-s2" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    <FaUserFriends className="me-2" /> Des immeubles (terrains, maison, appartement…)
                                </button>
                            </Link>
                            <Link to="/activite-statut-s1" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    <FaSlack className="me-2" /> Les deux
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
