import React, { useState } from 'react';

const ContactUsSection = () => {
  // State to manage form data and errors
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic validation
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Le nom est requis.';
    if (!formData.email) newErrors.email = 'Un email valide est requis.';
    if (!formData.message) newErrors.message = 'Veuillez nous laisser un message.';
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle form submission
      // For example, send form data to your API or backend
      console.log('Formulaire soumis :', formData);
    }
  };

  return (
    <section className="contact-us-section pt-0 lg-pt-0" style={{ paddingTop: '0' }}>
      <div className="container">
        <div className="border-bottom pb-150 lg-pb-80">
          <div className="title-one text-center mb-70 lg-mb-40">
            <h2>Contactez-nous</h2>
          </div>
          <div className="row">
            <div className="col-xl-10 m-auto">
              <div className="row">
                <div className="col-md-4">
                  <div className="address-block-one text-center mb-40 ">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                      <img src="/assets/images/icon/icon_1.png" alt="Notre adresse" />
                    </div>
                    <h5 className="title">Notre adresse</h5>
                    <p>1012 Pebda Parkway, Mirpur 2 <br /> Dhaka, Bangladesh</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="address-block-one text-center mb-40 ">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                      <img src="/assets/images/icon/icon_2.png" alt="Informations de contact" />
                    </div>
                    <h5 className="title">Informations de contact</h5>
                    <p>
                      Ouvrez un chat ou appelez-nous au <br />
                      <a href="tel:310.841.5500" className="call">310.841.5500</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="address-block-one text-center mb-40 ">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                      <img src="/assets/images/icon/icon_3.png" alt="Assistance en direct" />
                    </div>
                    <h5 className="title">Assistance en direct</h5>
                    <p>
                      service de chat en direct <br />
                      <a href="#" className="webaddress">www.pharos.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-9 m-auto">
              <div className="form-style-one mt-85 lg-mt-50 ">
                <form onSubmit={handleSubmit} id="contact-form">
                  <div className="messages"></div>
                  <div className="row controls">
                    <div className="col-sm-6">
                      <div className="input-group-meta form-group mb-30">
                        <label htmlFor="name">Nom*</label>
                        <input
                          type="text"
                          placeholder="Votre nom*"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <div className="help-block with-errors">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-group-meta form-group mb-30">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          placeholder="Adresse email*"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <div className="help-block with-errors">{errors.email}</div>}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-35">
                        <label htmlFor="subject">Objet (optionnel)</label>
                        <input
                          type="text"
                          placeholder="Écrivez l'objet ici.."
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group-meta form-group mb-35">
                        <textarea
                          placeholder="Votre message*"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        {errors.message && <div className="help-block with-errors">{errors.message}</div>}
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn-eleven fw-500 tran3s d-block">Envoyer le message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
