import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TextField, Button, Grid, Typography, Container, Box } from "@mui/material";


const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");
    const {projectId}=useParams();


    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className=" text-center" >CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two ">
                <div className="container">
                <div className="d-flex mb-4">
                                            <Link to={`/package-com4N/${projectId}`}
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center">5/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                         </div>
                   
                    <div className="title-one text-center mb-1 lg-mb-2

 ">
                        <h3 className="fw-600">
                            Nous vous mettons en contact avec un de nos designers partenaires
                        </h3>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 mb-4">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com6/${projectId}`} className="d-block">
                                    Continuez
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
