import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { generateText } from "../../../api/Services/webPackageService";
import { useDispatch } from "react-redux";
import { setProject, setServices } from "../../../redux/packageSlice";
import { TextareaAutosize, TextField, Typography } from "@mui/material";

const CandidateV3Area = ({ style_2 }) => {
    const [textareaValue, setTextareaValue] = useState("");
    const [generatedText, setGeneratedText] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { projectId } = useParams();

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const dispatch = useDispatch();

    const handleGenerateClick = async () => {
        const keywords = textareaValue.split(",").map((word) => word.trim());
        setLoading(true);
        setError(null);

        try {
            const text = await generateText(keywords);
            setGeneratedText(text);

            dispatch(
                setProject({
                    projectId,
                    data: {
                        website: {
                            services: {
                                text: text,
                                items: keywords,
                            },
                        },
                    },
                })
            );
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                    <div className="d-flex mb-3">
                        <Link
                            to={`/package-com8/${projectId}`}
                            style={{ margin: '10px auto', width: 'auto', paddingLeft: '65px' }}
                            className="d-flex justify-content-around"
                        >
                            <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                        </Link>
                        <Link
                            to="#"
                            style={{ margin: '10px auto', width: 'auto', paddingRight: '100px' }}
                            className="d-flex justify-content-center"
                        >
                            <Typography component="span" className="d-flex justify-content-center text-center">11/15</Typography>
                        </Link>
                        <a style={{ margin: '10px auto', width: 'auto' }} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                    </div>

                    <div className="title-one text-center mb-1 lg-mb-2">
                        <h3 className="fw-600">
                            Sélectionnez dans la liste déroulante des mots-clés concernant votre
                            activité. Vous pouvez également ajouter des mots qui n’y figurent pas.
                        </h3>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <TextareaAutosize
                                minRows={3}
                                className="form-control"
                                value={textareaValue}
                                onChange={handleTextareaChange}
                                placeholder="Ajoutez vos propres mots-clés ici, séparés par des virgules"
                            />
                        </div>
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn-eleven fw-500 tran3s d-block mt-20"
                                onClick={handleGenerateClick}
                                disabled={loading}
                            >
                                {loading ? "Génération en cours..." : "Générez"}
                            </button>
                        </div>
                        {error && (
                            <div className="col-12 mt-4">
                                <div className="alert alert-danger">{error}</div>
                            </div>
                        )}
                        {generatedText && (
                            <div className="col-12 mt-4">
                                <div className="generated-text-section p-4 border rounded">
                                    <h5 className="fw-600 mb-3">Text généré</h5>
                                    <p>{generatedText}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 mb-4">
                        <button
                            type="submit"
                            className="btn-eleven fw-500 tran3s d-block mt-20"
                            disabled={!generatedText || loading}
                            style={{
                                backgroundColor: !generatedText || loading ? '#b0b0b0' : '',
                                cursor: !generatedText || loading ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <Link
                                to={`/package-com9/${projectId}`}
                                className="d-block"
                                style={{ pointerEvents: !generatedText || loading ? 'none' : 'auto' }}
                            >
                                Validez
                            </Link>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;