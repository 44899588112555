import React from "react";
import { Link ,useLocation } from "react-router-dom";
import { FaFileInvoice, FaFileAlt } from 'react-icons/fa'; 
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = ({ style_2 }) => {
  const location = useLocation();
  const selectedService = location.state?.selectedService || 'Minimal'; 
  

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >CRÉATION D’XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-20 lg-pt-30 pb-30 xl-pb-30  lg-pb-30 ">
        <div className="container">
          <div className="text-center">
            <div className="text-center">
            <h2 className="fw-600" >
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
            </div>
                <div className="d-flex mb-1">
                        <Link to="/create_soc"
                                style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                className="d-flex justify-content-around">
                            <span className="precedent-text">{"< Précédent"}</span>
                        </Link>
                        <Link to="#"
                                style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                className="d-flex justify-content-center">
            
                            <span className="d-flex justify-content-center text-center">1/4</span>
                        </Link>
                        <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                    </div>
           
           

          </div>
         <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600 mb-4">
              Pharos Compta
            </h3>
            <p className="fw-600">
              Un service <strong>gratuit</strong> pour générer et stocker rapidement vos<br /> 
              factures et devis. Un service <strong>disponible</strong> à partir <strong>de la création<br /> 
              d’un compte Pharos</strong>
            </p>

            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <div className="d-flex justify-content-center align-items-center ">
              <div className="feature-list" style={{ width: '100%', maxWidth: '600px' }}>
                <div className="d-flex align-items-center mb-4">
                  <FaFileInvoice size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Facturation</h4>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <FaFileAlt size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Devis</h4>
                  </div>
                </div>
              </div>
        </div>
    </div>
         <div className="row mt-5">
                <div className="col-12 text-center">
                    <CustomButton to="/create_soc2" state={{ selectedService }} >
                    Pour suivre la commande →
                    </CustomButton >
                  
                </div>
              </div>
           </div>

        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
