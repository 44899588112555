import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setProject } from "../../../redux/packageSlice";
import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});

  const [selectedFont, setSelectedFont] = useState(projectData.fontFamily || "");

  useEffect(() => {
    setSelectedFont(projectData.fontFamily || "");
  }, [projectData.fontFamily]);

  const handleFontChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);

    dispatch(
      setProject({
        projectId,
        data: {
          website: {
            globalDetails: {
              fontFamily: font,
            },
          },
        },
      })
    );

  };

  return (
    <>
     <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
  </div>
 <Container sx={{ py: 5 }}>
    <div className="d-flex mb-3">
                                                                  <Link to={`/package-com8/${projectId}`} 
                                                                          style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                          className="d-flex justify-content-around">
                                                                      <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                                                  </Link>
                                                                  <Link to="#"
                                                                          style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                                          className="d-flex justify-content-center">
                                                      
                                                                      <Typography component="span" className="d-flex justify-content-center text-center">12/15</Typography>
                                                                  </Link>
                                                                  <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                               </div>

     
                                                                            <div className="title-one text-center mb-1 lg-mb-2

 ">
                                      <h3 className="fw-600">Choisissez la police de votre site</h3>
                                  </div>


      <Box sx={{ mt: 5 }}>
        <Box mb={4}>
          <Typography variant="body1" gutterBottom>
            Liste déroulante de polices
          </Typography>
          <Select
            id="font-select"
            value={selectedFont}
            onChange={handleFontChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>-- Sélectionnez une police --</em>
            </MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Courier New">Courier New</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
          </Select>
        </Box>

        {selectedFont && (
          <Box
            sx={{
              fontFamily: `${selectedFont}, sans-serif`,
              p: 3,
              border: "1px solid #ddd",
              borderRadius: 1,
            }}
          >
           <Typography
          variant="h5"
          sx={{
            fontFamily: `${selectedFont}, sans-serif !important`,
          }}
        >
          Donnez un style unique à votre entreprise !
        </Typography>

          </Box>
        )}

      <Box mt={4}>
        <div className="col-12 text-center">
          <CustomButton
          
            disabled={!selectedFont} 
          
            to={`/package-com10/${projectId}`}
            sx={{
              minWidth:"114px",
              '&:disabled': {
                backgroundColor: '#b0b0b0',
                color: '#fff',
                cursor: 'not-allowed',
                opacity: 0.6,
              },
            }}
          >
            Validez
          </CustomButton>
        </div>
      </Box>

      </Box>
    </Container>
  </>
  );
};

export default CandidateV3Area;
