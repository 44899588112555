
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";



import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import { Col, Row } from "react-bootstrap";
import { Grid, TextField, Button, Checkbox, FormControlLabel, Typography ,Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import { Link } from "react-router-dom";

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

// Amplify.configure(awsconfig);

const schema = Yup.object().shape({
  prenom: Yup.string().required("Prénom requis"),
  nom: Yup.string().required("Nom requis"),
  email: Yup.string().required("Email requis").email("Email invalide"),
  password: Yup.string()
    .required("Mot de passe requis")
    .min(6, "Le mot de passe doit contenir au moins 6 caractères"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Les mots de passe doivent correspondre")
    .required("Confirmation du mot de passe requise"),
});

const ErrorMsg = ({ msg }) => (
  <div className="error-msg">{msg}</div>
);

const RegisterForm = () => {
  const [showDialog, setShowDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const { prenom, nom, email, password } = data;
      const attributeList = [
        { Name: 'email', Value: email },
        { Name: 'given_name', Value: prenom },
        { Name: 'family_name', Value: nom },
      ];
  
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          console.error('Erreur lors de l’inscription', err);
          setErrorMessage(err.message);
          setIsLoading(false);
          return;
        }
        console.log('Inscription réussie !', result.user);
        setShowDialog(true);  
      });
    } catch (error) {
      console.error("Erreur lors de l'inscription", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorMessage && <div className="error-msg">{errorMessage}</div>} 
       
        <Grid container spacing={2}>
  <Grid item xs={12}>
    <div className="input-group-meta position-relative mb-25">
      <label>Prénom*</label>
      <TextField
        fullWidth
        placeholder="Dupont"
        variant="outlined"
        {...register("prenom")}
        error={!!errors.prenom}
        helperText={errors.prenom?.message}
        sx={{
          '& .MuiFormHelperText-root': {
            color: 'red', 
          }
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12}>
    <div className="input-group-meta position-relative mb-25">
      <label>Nom*</label>
      <TextField
        fullWidth
        placeholder="Dupont"
        variant="outlined"
        {...register("nom")}
        error={!!errors.nom}
        helperText={errors.nom?.message}
        sx={{
          '& .MuiFormHelperText-root': {
            color: 'red',
          }
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12}>
    <div className="input-group-meta position-relative mb-25">
      <label>Email*</label>
      <TextField
        fullWidth
        placeholder="jean.dupont@example.com"
        type="email"
        variant="outlined"
        {...register("email")}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{
          '& .MuiFormHelperText-root': {
            color: 'red',
          }
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12}>
    <div className="input-group-meta position-relative mb-20">
      <label>Mot de passe*</label>
      <TextField
        fullWidth
        placeholder="Entrez le mot de passe"
        type="password"
        variant="outlined"
        {...register("password")}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{
          '& .MuiFormHelperText-root': {
            color: 'red', 
          }
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12}>
    <div className="input-group-meta position-relative mb-25">
      <label>Confirmation du mot de passe*</label>
      <TextField
        fullWidth
        placeholder="Confirmez le mot de passe"
        type="password"
        variant="outlined"
        {...register("confirmPassword")}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        sx={{
          '& .MuiFormHelperText-root': {
            color: 'red', 
          }
        }}
      />
    </div>
  </Grid>
        </Grid>

          <Grid item xs={12} mb={2}>
          <div className="agreement-checkbox d-flex justify-content-between align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  {...register("terms", { required: "Vous devez accepter les conditions générales" })}
                  id="terms-checkbox"  
                />
              }
              label={
                <span>
                  En cliquant sur le bouton S'inscrire, vous acceptez les{" "}
                 <Link to="#">Conditions générales</Link> &{" "}
                 <Link to="#">Politique de confidentialité</Link>.
                </span>
              }
            />
            {errors.terms && (
              <Typography color="error" variant="body2">
                {errors.terms.message}
              </Typography>
            )}
          </div>
        </Grid>



          <Grid item xs={12}>
          <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={isLoading}
          sx={{
            backgroundColor: 'rgb(25, 175, 120) !important',
            '&:hover': {
              backgroundColor: 'rgb(25, 175, 120) !important', 
            },
          }}
        >
          {isLoading ? "Chargement..." : "S'inscrire"}
        </Button>

          </Grid>
       

      </form>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle align="center">Votre enregistrement a réussi</DialogTitle>
        <DialogContent>
          <Typography align="center" variant="body1">
            Finalisez votre inscription en retournant à votre boite mail pour
            valider le mail de validation. Puis, identifiez-vous avec votre
            nouveau compte.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RegisterArea = () => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >
                  S’inscrire
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="registration-section position-relative pt-100 lg-pt-80 pb-150 lg-pb-80">
        <div className="container">
          <div className="user-data-form">
            <div className="text-center">
              <h2>Créer un compte</h2>
            </div>
            <div className="form-wrapper m-auto">
              <RegisterForm />
              <div className="d-flex align-items-center mt-30 mb-10">
                <div className="line"></div>
                <span className="pe-3 ps-3">OU</span>
                <div className="line"></div>
              </div>
              <div className="row justify-content-center">
                              <div className="col-md-6">
                                <Link
                                  to="#"
                                  className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                                >
                                  <img
                                    src="/assets/images/icon/google.png"
                                    alt="Google Icon" style={{maxWidth: "15px" }}
                                  />
                                  <span className="ps-2">Inscription avec Google</span>
                                </Link>
                              </div>
                              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterArea;
