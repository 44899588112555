import React ,{useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Use react-router-dom for routing
import { FaDesktop, FaGlobe, FaEnvelope, FaFileAlt } from 'react-icons/fa'; 
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = ({ style_2 }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isWebProSelected, setIsWebProSelected] = useState(false);


  const isDomiciliationSelected = location.state?.isDomiciliationSelected || false;
  const selectedService = location.state?.selectedService || 'Minimal'; 


console.log("selectedService",selectedService);
 
  const handleNextStep = () => {
    setIsWebProSelected(true);
 console.log(isDomiciliationSelected);
    navigate('/create_soc5', { state: { isDomiciliationSelected,isWebProSelected:true ,selectedService} });
  };
  const handleContinueWithoutWebPro =()=>{
    setIsWebProSelected(false);
    navigate('/create_soc5',{ state: { isDomiciliationSelected,selectedService} });
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12  text-center col-md-8">
                <h2  >CRÉATION D’XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
          <div className="text-center">
          <div className="text-center">
            <h2 className="fw-600">
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
           </div>
                        <div className="d-flex mb-1">
                                                         <Link to="/create_soc3"
                                                                 style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                 className="d-flex justify-content-around">
                                                             <span className="precedent-text">{"< Précédent"}</span>
                                                         </Link>
                                                         <Link to="#"
                                                                 style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                                                 className="d-flex justify-content-center">
                                             
                                                             <span className="d-flex justify-content-center text-center">4/4</span>
                                                         </Link>
                                                         <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                     </div>
           
          </div>
          <div className="title-one text-center mb-1 lg-mb-2

  mb-3">
            <h3 className="fw-600">
              Pharos WebPro <br /> 49,90 € HT / mois
            </h3>
            <br />
            <p className="fw-600">
              Pharos WebPro vous crée en quelques minutes tous les outils<br /> digitaux essentiels pour une présence professionnelle sur internet
            </p>
           
            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <div className="d-flex justify-content-center align-items-center ">
              <div className="feature-list" style={{ width: '100%', maxWidth: '600px' }}>
                <div className="d-flex align-items-center mb-3">
                  <FaDesktop size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Site Web vitrine</h4>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <FaGlobe size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Un nom de domaine</h4>
                  <p>exemple https://ma-societe.fr</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaEnvelope size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Une adresse et boite mail professionnelle</h4>
                  <p>exemple contact@ma-societe.fr</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaFileAlt size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Un gestionnaire électronique de documents (GED) pour stocker en ligne les documents
                  </h4>
                  </div>
                </div>
              </div>
              
        </div>
        </div>

         
  
        <div className="row mt-5 justify-content-center">
        <div className=" text-center col-12">
            <CustomButton  onClick={handleNextStep}>
               Ajouter et poursuivre la commande  → 
            </CustomButton >
              
            </div>
          
        </div>
       
      <div className="col-12" style={{ textAlign: 'center', marginTop: '7px' }}>
        <span
          onClick={handleContinueWithoutWebPro}
          style={{ cursor: 'pointer' }}
        >
          Poursuivre la commande →
        </span>
      </div>
      </div>


        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
