import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import DashboardHeader from '../header';
import ComposeEmailModal from './ComposeEmailModal';
import { ClipLoader } from "react-spinners";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TablePagination,
  Box,
  
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getNumberOfMails, getReceivedMails,getDraftMails, getSentMails, getAllContact, deleteEmail, deleteContact,  fetchDownloadAttachment } from '../../../../api/Services/emailService';
import Swal from 'sweetalert2';
import AddContactModal from './addContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faPaperclip } from '@fortawesome/free-solid-svg-icons';
import DeplacerDocumentVersDMS from './attachementVersDMS';
import ReplyBox from './ReplyBox';



const DashboardMessage = ({ setIsOpenSidebar }) => {
  const { emailAddress } = useParams();
  const navigate= useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("received"); 
  const [emails, setEmails] = useState([]);
  const [receivedEmails, setReceivedEmails] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  const [draftEmails, setDraftEmails] = useState([]);
  const [contactEmails, setContactEmails] = useState([]);
  const [allEmail, setAllEmail] = useState(0);
  const [unReadEmail, setUnReadEmail] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isComposeModalOpen, setComposeModalOpen] = useState(false);
  const [attachments,setAttachments]=useState([]);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
   const handleShowDeplacer = () => {  setShowModalDeplcer(true)};
   const [showDeplacer,setShowModalDeplcer]=useState(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [showReplyBox, setShowReplyBox] = useState(false);
   const [actionMode, setActionMode] = useState(null);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const handleOpenModal = () => {setIsModalOpen(true);}
   const handleCloseModal = () =>{ setIsModalOpen(false);
     
   }
 
   const handleSendReply = (content, attachments) => {
     console.log("Réponse ou transfert envoyé :", content);
     console.log("Pièces jointes :", attachments);
     setShowReplyBox(false);
   };
 
   const handleCancelReply = () => {
     setShowReplyBox(false);
   };
  //  const filteredContacts = Array.from({ length: 25 }, (_, i) => `Contact ${i + 1}`);
 //pour actaiser filder et revenir au table
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter); 
    setSelectedEmail(null); 
  };
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
 
   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPage(0);
   };

 //date
   function formatDate(dateString) {
    const options = { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", options);
  }
  

  const openComposeModal = () => {
    setComposeModalOpen(true);
  };

  const closeComposeModal = () => {
    setComposeModalOpen(false);
  };
  const fetchEmails = async () => {
    setIsLoading(true);
    try {
      const numberResponse = await getNumberOfMails(emailAddress);
      setAllEmail(numberResponse?.totalEmailsCount + numberResponse?.unreadEmailsCount || 0);
      setUnReadEmail(numberResponse?.unreadEmailsCount || 0);
      
      const [received, sent, draft,contact] = await Promise.all([
        getReceivedMails(emailAddress),
        getSentMails(emailAddress),
        getDraftMails(emailAddress),
        getAllContact(emailAddress),
      ]);
       console.log("received",received);
      setReceivedEmails(received.items || []);
      setSentEmails(sent.items || []);
      setDraftEmails(draft.items || []);
      setContactEmails(contact.items || []);
    } catch (error) {
      console.error("Error fetching emails:", error);
     
    }finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
   if (emailAddress) {
      fetchEmails();
    }
  }, [emailAddress]);
  useEffect(() => {
    
    let filteredEmails = [];
    switch (filter) {
      case "received":
        filteredEmails = receivedEmails.map(email => ({ ...email, isSelected: false }));
        break;
      case "sent":
        filteredEmails = sentEmails.map(email => ({ ...email, isSelected: false }));
        break;
      case "draft":
        filteredEmails = draftEmails.map(email => ({ ...email, isSelected: false }));
        break;
      case "contact":
        filteredEmails = contactEmails.map(contact => ({ ...contact, isSelected: false }));
        break;
      default:
        filteredEmails = [];
    }
    setEmails(filteredEmails);
  }, [filter, receivedEmails, sentEmails, draftEmails, contactEmails]);
  
  const handleRowClick = (email) => {
    setSelectedEmail(email);
    setAttachments(email.attachments || []);
    console.log("Attachments:", attachments);

  };
 
  const handleSelectEmail = (email) => {
    setEmails((prevEmails) =>
      prevEmails.map((e) =>
        e.itemId === email.itemId ? { ...e, isSelected: !e.isSelected } : e
      )
    );
  };

 
  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setEmails((prevEmails) =>
      prevEmails.map((email) => ({ ...email, isSelected }))
    );
  };
 
  const handleDeleteEmail = async (itemIds) => {
    const isMultiple = Array.isArray(itemIds);
    const ids = isMultiple ? itemIds : [itemIds];
  
    if (ids.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Aucune sélection",
        text: "Veuillez sélectionner au moins un email.",
      });
      return;
    }
  
    const result = await Swal.fire({
      icon: "warning",
      title: "Suppression des emails",
      text: `Vous voulez vraiment supprimer ${ids.length} email(s) ?`,
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });
  
    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: "Suppression en cours...",
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading(),
        });
  
        const response = await deleteEmail(emailAddress, ids);
        if (response?.data?.deleteMails === null) {
          await fetchEmails();
          Swal.fire({
            icon: "success",
            title: "Supprimé",
            text: `${ids.length} email(s) ont été supprimé(s) avec succès.`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Les emails n'ont pas été supprimés.",
          });
        }
      } catch {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur est survenue.",
        });
      }
    }
  };
  

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteContact(emailAddress, [itemId]);
      if (response?.data) {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Contact supprimé avec succès.",
        });
        const updatedContacts = await getAllContact(emailAddress);
        setContactEmails(updatedContacts.items || []);
      } else {
        throw new Error("Erreur lors de la suppression du contact.");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du contact :", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Impossible de supprimer le contact. Veuillez réessayer.",
      });
    } finally {
      await fetchEmails(emailAddress); 
    }
  };
  
  
  
  /* partie search*/
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = emails.filter((email) =>
    email.subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.recipient?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const filteredContacts = contactEmails.filter((contact) =>
    contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  /* partie dawlond fils*/
 
  const dowloadSingleAttachment = async (attachment) => {
    try {
      if (!emailAddress) {
        console.error("L'adresse e-mail source est manquante.");
        return;
      }
  console.log( emailAddress,
    selectedEmail.itemId,
    attachment.attachmentId);
      const url = await fetchDownloadAttachment(
        emailAddress,
        selectedEmail.itemId,
        attachment.attachmentId
      );
  
      if (url) {
        window.open(url, '_blank');
      } else {
        console.warn("URL de téléchargement non disponible pour :", attachment.name);
      }
    } catch (error) {
      console.error(`Erreur lors du téléchargement de la pièce jointe : ${attachment.name}`, error);
    }
  };
  
  

  return (
    <div className="dashboard-body">
    <div className="position-relative">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
      <div className="card-box border-20 p0 mt-30 " style={{  minHeight: "50vh",background: "white",borderRadius: "15px"}}>
          <div className="message-wrapper">
            <div className="row gx-0 h-100">
              <div className="col-lg-3 ">
                <div className="message-sidebar ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-3">
              
                  <div className="email_interface">
                    <button className="compose_button" onClick={openComposeModal}>
                      <i className="fas fa-pencil-alt"></i> Rédiger un email
                    </button>
                        {isComposeModalOpen && (
                    <ComposeEmailModal onClose={closeComposeModal}addressEmail={emailAddress} />
                  )}       
                    <div className="message_container">
                      <div className="message_filter" id="module_btns">
                        <div 
                          className={`filter_item ${filter === "received" ? 'active' : ''}`} 
                          onClick={() => handleFilterChange("received")}
                        >
                          <i className="fas fa-envelope"></i>
                          <span className="filter_text">Boite de réception</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              receivedEmails?.length || 0
                            )}</span>
                        </div>
                        
                        <div 
                          className={`filter_item ${filter === "sent" ? 'active' : ''}`} 
                          onClick={() => handleFilterChange("sent")}
                        >
                          <i className="fas fa-paper-plane"></i>
                          <span className="filter_text">Messages envoyés</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              sentEmails?.length || 0
                            )}</span>
                        </div>
                        <div 
                          className={`filter_item ${filter === "draft" ? 'active' : ''}`} 
                          onClick={() => handleFilterChange("draft")}
                        >
                          <i className="fas fa-pencil-alt"></i>
                          <span className="filter_text">Brouillons</span>
                          <span className="filter_count">
                            {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              draftEmails?.length || 0
                            )}
                          </span>
                        </div>
                        <div 
                          className={`filter_item ${filter === "contact" ? 'active' : ''}`} 
                          onClick={() => handleFilterChange("contact")}
                        >
                          <i className="fa-solid fa-user-alt"></i>
                          <span className="filter_text">Contacts</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              contactEmails?.length || 0
                            )}</span>
                        </div>
                        <div 
                          className="filter_item"
                          onClick={() => navigate(`/email-calendrier/${emailAddress}`)}
                        >
                          <i className="fa-solid fa-calendar-days"></i>
                          <span className="filter_text">Calendrier</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="message-sidebar ">
                  <div className="ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-3">
                     <div className="search-form mt-20 mb-20" style={{ position: "relative" }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Rechercher ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            
                            style={{ paddingLeft: "35px" }}
                          />
                          <button
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              background: "none",
                              border: "none",
                              padding: "0",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/assets/dashboard/images/icon/icon_10.svg"
                              alt="icon"
                              className="lazy-img"
                              style={{
                                height: "16px",
                              }}
                            />
                          </button>

                      
                      </div>
                    

                      {emails.some(email => email.isSelected) && (
                              <button
                                className="delete-selected-btn"
                                onClick={() => {
                                  const selectedEmails = emails.filter((email) => email.isSelected);
                                  const selectedIds = selectedEmails.map((email) => email.itemId);
                                  handleDeleteEmail(selectedIds);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} className="icon-trash" />
                              </button>
                            )}
                    </div>
                    {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                      <ClipLoader size={50} color="#19AF78" />
                    </div>
                     ) :
                    filter === "contact" ? (
                      <>
                      <div
                        className="d-flex flex-column align-items-end ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-3 mb-4"
                        style={{ width: "100%" }}
                      >
                        <Link
                          className="dash-btn-two tran3s mb-3"
                          style={{
                            display: "inline-block",
                            borderRadius: "5px",
                            textDecoration: "none",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenModal}
                        >
                          Ajouter un Contact
                        </Link>

                        {isModalOpen && (
                          <AddContactModal
                            open={isModalOpen}
                            handleClose={handleCloseModal}
                            adressEmail={emailAddress}
                            fetchEmails={fetchEmails}
                          />
                        )}

                       <TableContainer component={Paper} style={{ width: "100%" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nom</TableCell>
                              <TableCell>Prénom</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Téléphone</TableCell>
                              <TableCell align="center">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredContacts.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((contact) => (
                              <TableRow key={contact.itemId}>
                                <TableCell>{contact.name || "—"}</TableCell>
                                <TableCell>{contact.surname || "—"}</TableCell>
                                <TableCell>{contact.emails || "—"}</TableCell>
                                <TableCell>{contact.phoneNumbers || "—"}</TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    color="error"
                                    onClick={() => handleDelete(contact.itemId)}
                                    aria-label="Supprimer le contact"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={filteredContacts.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage="Lignes par page :"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                          sx={{
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                              margin: 0,
                            },
                            '& .MuiTablePagination-toolbar': {
                              padding: 0,
                            },
                          }}
                        />

                      </div>
                      </>

                      ) : selectedEmail ? (
                        <div className="email-details-container">
                        <button
                          className="back-to-table-btn"
                          onClick={() => setSelectedEmail(null)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "20px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i className="fas fa-arrow-left" style={{ marginRight: "15px" }}></i>
                          Retour à la liste
                        </button>
                      
                        <div className="email-content-wrapper">
                          <div className="email-header d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src="/user.png"
                                alt="Sender Avatar"
                                className="sender-avatar"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                              <div>
                                <h4 style={{ marginBottom: "7px", fontWeight: "600" }}>{selectedEmail.subject}</h4>
                                <p className="sender-info" style={{ marginBottom: "2px", color: "#555" }}>
                                  {filter === "received" ? `De : ${selectedEmail.sender}` : `À : ${selectedEmail.sender}`}
                                </p>
                              </div>
                            </div>
                      
                            <div className="d-flex align-items-center">
                              <span style={{ color: "#888", fontSize: "14px" }}>{formatDate(selectedEmail.time)}</span>
                              <i className="fas fa-share" style={{ marginLeft: "20px", marginRight: "10px", cursor: "pointer" }}  onClick={() => {
                                        setActionMode("transfer");
                                        setShowReplyBox(true);
                                      }}></i>
                      
                              <div className="dropdown" style={{ marginLeft: "10px" }}>
                                <button
                                  className="btn dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ background: "none", border: "none", cursor: "pointer" }}
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button className="dropdown-item" 
                                     onClick={() => {
                                        setActionMode("reply");
                                        setShowReplyBox(true);
                                      }}>
                                      <i className="fa fa-reply" style={{ marginRight: "5px" }}></i> Répondre
                                    </button>
                                  </li>
                                  <li>
                                    
                                  <button className="dropdown-item" onClick={() => handleDeleteEmail(selectedEmail.itemId)}>
                                    <i className="fas fa-trash-alt" style={{ marginRight: "5px", color: "red" }}></i>
                                    Supprimer
                                  </button>

                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                      
                          <div className="email-body">
                            <p dangerouslySetInnerHTML={{ __html: selectedEmail.body }}></p>
                          </div>
                      
                          {attachments?.length > 0 && (
                            <div className="email-footer">
                              <h4>Pièces jointes</h4>
                              {attachments.map((attachment, index) => (
                                <div key={index} className="file tran3s d-flex align-items-center mt-10 position-relative">
                                  <div className="icon rounded-circle d-flex align-items-center justify-content-center">
                                    <img
                                      src="/assets/dashboard/images/icon/icon_31.svg"
                                      alt="icon"
                                      className="lazy-img"
                                    />
                                  </div>
                                  <div className="ps-2">
                                    <div className="file-name">{attachment.name}</div>
                                  </div>
                      
                                  <div className="action-dots float-end">
                                    <button
                                      className="action-btn dropdown-toggle"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ background: "none", border: "none", cursor: "pointer" }}
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                      <li>
                                        <button className="dropdown-item" onClick={() => dowloadSingleAttachment(attachment)}>
                                          <i className="fas fa-download" style={{ marginRight: "5px" }}></i> Télécharger
                                        </button>
                                      </li>
                                      <li>
                                        <button className="dropdown-item" onClick={() => handleShowDeplacer()}>
                                          <i className="fas fa-folder" style={{ marginRight: "5px" }}></i> Placer vers DMS
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  {showDeplacer && (
                                    <DeplacerDocumentVersDMS
                                      showDeplacer={showDeplacer}
                                      handleCloseDeplacer={handleCloseDeplacer}
                                      itemId={selectedEmail.itemId}
                                      adressEmail={emailAddress}
                                      attachmentId={attachment.attachmentId}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                      
                         
                        </div>
                        {showReplyBox && (
                          <ReplyBox
                            onSendReply={handleSendReply}
                            senderEmail={selectedEmail.sender}
                            objectEmail={selectedEmail.subject}
                            onCancel={handleCancelReply}
                            addressEmail={emailAddress}
                            actionMode={actionMode} 
                            originalBody={selectedEmail.body} 
                            originalTime={formatDate(selectedEmail.time)}
                            fetchEmails={fetchEmails}
                          />
)}            
                        
                        </div>
                 ) : (
                        <div className="emails-content p-3">
                          {emails.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <input type="checkbox" onChange={handleSelectAll} />
                                    </th>
                                    <th>Expéditeur</th>
                                    <th>Objet</th>
                                    <th>Pièce jointe</th>
                                    <th>Heure</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredItems
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((email, index) => (
                                      <tr key={index} onClick={() => handleRowClick(email)} 
>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={email.isSelected}
                                            onClick={(e) => e.stopPropagation()} 
                                            onChange={() => handleSelectEmail(email)}
                                          />
                                        </td>
                                        <td>{email.sender}</td>
                                        <td>{email.subject}</td>
                                        <td >
                                          {email.attachments && email.attachments.length > 0 ? (
                                            <FontAwesomeIcon
                                              icon={faPaperclip}
                                              className="d-flex justify-content-center"
                                            />
                                          ) : null}
                                        </td>

                                        <td>{new Date(email.time).toLocaleString()}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>Aucun email trouvé.</p>
                          )}
                          
                         <Box display="flex-column align-items-end"  justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={filteredItems.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage="Lignes par page :"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                          sx={{
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                              margin: 0,
                            },
                            '& .MuiTablePagination-toolbar': {
                              padding: 0,
                            },
                          }}
                        />
                      </Box>
                           

                        </div>

                      )}

                </div>
                
              </div>
          <style>
            {
              `tr:hover {
            background-color: #f9f9f9 ;
            cursor: pointer;
          }

              `
            }
          </style>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default DashboardMessage;
