import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography,TextField } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { mutationSentEmail, uploadFilesToS3 } from '../../../../api/Services/emailService';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';

const ReplyBox = ({ senderEmail, fetchEmails, onCancel, objectEmail, addressEmail, actionMode, originalBody, originalTime }) => {
  const [replyContent, setReplyContent] = useState("");
  const [replyAttachments, setReplyAttachments] = useState([]);
  const [transferEmail, setTransferEmail] = useState("");
  const contentRef = useRef(null);

  const cleanHTML = DOMPurify.sanitize(originalBody);


  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = cleanHTML; 
    }
  }, [cleanHTML]);

  const handleContentChange = () => {
    if (contentRef.current) {
      setReplyContent(contentRef.current.innerHTML);
    }
  };

  const handleSend = async () => {
    if (!senderEmail || !objectEmail || !replyContent) {
      Swal.fire({
        icon: 'warning',
        title: 'Champs manquants',
        text: 'Veuillez remplir tous les champs requis avant d\'envoyer l\'email.',
      });
      return;
    }

    try {
      const uploadedFileKeys = [];

      for (const file of replyAttachments) {
        const uploadResponse = await uploadFilesToS3(file);
        console.log("Réponse du téléchargement :", uploadResponse);

        const fileKey = new URL(uploadResponse).pathname.substring(1);
        uploadedFileKeys.push(fileKey);
      }

      console.log("Fichiers téléchargés avec succès :", uploadedFileKeys);

      const input = {
        sourceEmailAddress: addressEmail,
        input: {
          body: replyContent,
          subject: actionMode === "reply" ? `Re: ${objectEmail}` : `Fwd: ${objectEmail}`,
          destinationEmailAddresses: actionMode === "reply" ? senderEmail : transferEmail,
          attachments: uploadedFileKeys || [],
        },
      };
      console.log("Input final pour l'envoi :", input);

      const response = await mutationSentEmail(input);
      console.log("Réponse de l'API :", response);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Envoyé",
          text: `Email ${actionMode === "reply" ? "répondu" : "transféré"} avec succès.`,
        });

        try {
          await fetchEmails();
        } catch (error) {
          console.error("Erreur lors de l'actualisation des emails :", error);
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Erreur lors de l'actualisation de la liste des emails.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: `Erreur lors de l'envoi de l'email en mode ${actionMode}.`,
      });
      console.error("Erreur lors de l'envoi de l'email :", error);
    } finally {
      onCancel();
    }
  };

  return (
    <Box className="email-content-wrapper" sx={{ marginTop: 2, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
        {actionMode === "reply" ? `À: ${senderEmail}` : `Transférer à:`}
      </Typography>

      {actionMode === "transfer" && (
        <TextField
          fullWidth
          label="Adresse email de destination"
          value={transferEmail}
          onChange={(e) => setTransferEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
      )}

      {/* Zone de contenu HTML éditable */}
      <div
        ref={contentRef}
        contentEditable={true} // Rendre le contenu éditable
        onInput={handleContentChange} // Récupérer les modifications
        style={{
          border: '1px solid #ddd',
          padding: '10px',
          borderRadius: '4px',
          backgroundColor: '#f9f9f9',
          marginBottom: '16px',
          minHeight: '200px', // Hauteur minimale pour la zone de contenu
          outline: 'none', // Supprimer la bordure bleue lors de la sélection
        }}
      />

      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="outlined"
          color="success"
          component="label"
          startIcon={<AttachFileIcon />}
        >
          Ajouter des pièces jointes
          <input
            type="file"
            hidden
            onChange={(e) => setReplyAttachments([...e.target.files])}
            multiple
          />
        </Button>
      </Box>
      <Box>
        <Button variant="contained" color="success" onClick={handleSend} sx={{ marginRight: 2 }}>
          {actionMode === "reply" ? "Envoyer" : "Transférer"}
        </Button>
        <Button variant="outlined" color="success" onClick={onCancel}>
          Annuler
        </Button>
      </Box>
    </Box>
  );
};

export default ReplyBox;