import React, { useState } from 'react';
import EmployAside from './employ/aside';
import EmployDashboardArea from './employ/dashboard-area';
import Footer from '../../layouts/Footer';

const DashboardMain = () => {
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  return (
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />

      <EmployDashboardArea setIsOpenSidebar={setIsOpenSidebar} />
    </div>
  );
};

export default DashboardMain;