import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { FaFileInvoice, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import { fetchCreateProject } from "../../../api/Services/ProjetService";
import { fetchCheckoutUrl } from "../../../api/Services/paymentSevice";
import { ClipLoader } from "react-spinners";

const CandidateV3Area = () => {
  const location = useLocation();
  const [projectName, setProjectName] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjet, setSelectedProjet] = useState(null);
  

  
  const isDomiciliationSelected = location.state?.isDomiciliationSelected || false; 
  const isWebProSelected = location.state?.isWebProSelected || false;
  const selectedService = location.state?.selectedService || 'Minimal'; 
  console.log(selectedService);

  const serviceDetails = {
    Minimal: { title: 'Pharos Legal Minimal', price: '15 € HT' },
    Premium: { title: 'Pharos Legal Premium', price: '500 € HT' },
  };

  const { title, price } = serviceDetails[selectedService];

console.log('vfg',isWebProSelected);
  const serviceTypes = isDomiciliationSelected ? ["COMPANY_ORDER", "DOMICILIATION"] : ["COMPANY_ORDER"];



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!projectName.trim())
      {
     console.log('de');
       
       setShowError(true);
       return;
     }
     setShowError(false);
     setIsLoading(true);
    try {
      const projectResponse = await fetchCreateProject(projectName);

      const checkoutUrl = await fetchCheckoutUrl(projectName, serviceTypes); 

      if (checkoutUrl) {
        window.location.href = checkoutUrl; 
      }
    } catch (error) {
      console.error(error);
      window.location.href = '/login'; 
    }finally {
      setIsLoading(false); 
    }
  };

  return (
    <>
      <section className="order-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="left-section">
                <div className="mb-4">
                  <Link to="/create_soc4">
                  <span className="precedent-text"> {"< Précédent"}</span>
                 </Link>
                  <Link to="#" className="float-right" style={{ float: 'right' }}>
                    <button type="button" className="btn btn-success">
                      Modifier
                    </button>
                  </Link>
                </div>

                <h2 className="section-title">Votre commande</h2>
                <h3 className="sub-title">Création de xxx</h3>

                <div className="service-info">
                <table className="pricing-table">
                  <thead>
                    <tr>
                      <th className="service-title">
                        <FaFileInvoice /> {title}
                      </th>
                      <th className="pricing-item">{price}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        <ul>
                          <li>Préparation de vos statuts et de votre dossier</li>
                          <li>Entretien téléphonique d’une heure pour répondre à toutes vos questions</li>
                          <li>Vérification du dossier par nos experts</li>
                          <li>Envoi de l’annonce légale</li>
                          <li>Envoi du dossier au greffe</li>
                          <li>Obtention de l’extrait Kbis</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                    { isDomiciliationSelected &&  (
                              <table className="pricing-table">
                                <thead>
                                  <tr>
                                    <th className="service-title">
                                      <FaGlobe /> Domiciliation avec Pharos Legal 
                                      
                                    </th>
                                    <th className="pricing-item">
                                      49.90 € HT / mois
                                    </th>
                                  </tr>
                                </thead>
                                        </table>
                                  ) 
                            }
                          {isWebProSelected &&(
                              <table className="pricing-table">
                                <thead>
                                  <tr>
                                    <th className="service-title">
                                      <FaFileInvoice /> Pharos WebPro 
                                    </th>
                                    
                                    <th className="pricing-item mr-23">
                                        49.90 € HT / mois
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="right-section card mb-4">
                            <div className="card-header">
                              <h3>Récapitulatif</h3>
                            </div>
                            

                              <div className="card-body">
                                <p>Total commande HT: xx €</p>
                                <p>TVA <FaInfoCircle /> : yy €</p>
                                <p>Total TTC: zz €</p>
                                <h4>Nommez votre projet</h4>

                                <TextField
                                  label="Nom du projet"
                                  variant="outlined"
                                  fullWidth
                                  value={projectName}
                                  onChange={(e) => setProjectName(e.target.value)}
                                  error={showError && !projectName.trim()}
                                  helperText={showError && !projectName.trim() ? "Veuillez entrer un nom de projet." : ""}
                                  margin="normal"
                                />

                                    <Button
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      className="btn-eleven1 fw-500 tran3s d-block mt-3"
                                      onClick={handleSubmit}
                                      disabled={!projectName.trim()}
                                      sx={{
                                        '&:disabled': {
                                          backgroundColor: '#b0b0b0',
                                          color: '#fff',
                                          cursor: 'not-allowed',
                                          opacity: 0.6,
                                        },
                                      }}
                                    >
                                      {isLoading ? (
                                        <ClipLoader height="20" width="20" color="#fff" ariaLabel="loading" />
                                      ) : (
                                        'Valider'
                                      )}
                                    </Button>


                                <hr />
                                <p className="note">
                                  En fonction du régime TVA que vous aurez choisi, vous pourrez récupérer les frais de TVA via votre future entreprise.
                                </p>
                              </div>

                          </div>
                        </div>
                      </div>
                    </div>
                        
      </section>
      <style jsx>{`
        .order-section {
          padding: 50px 0 0px 0;
        }
        .btn-success {
          background-color: #28a745;
          color: #fff;
          border: none;
          padding: 10px 20px;
          font-size: 16px;
        }
        .section-title {
          font-size: 24px;
          font-weight: 600;
          margin: 20px 0;
        }
        .sub-title {
          font-size: 20px;
          font-weight: 400;
          margin: 10px 0;
        }
        .service-info {
          margin-top: 20px;
        }
        .pricing-item {
          text-align: right; 
          padding-left: 15px; 
          font-weight: 500;
        }
        .service-title {
          text-align: left; 
          padding-right: 15px; 
        }

        .service-info ul {
          list-style: disc;
          padding-left: 20px;
        }
        .right-section {
          background: #fff;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        .card-header {
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        .card-body {
          padding-top: 10px;
        }
       
        .card-body hr {
          margin: 20px 0;
        }
      `}</style>
    </>
  );
};

export default CandidateV3Area;
