import React, { useState } from "react";
import {
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { addContact } from "../../../../api/Services/emailService";
import Swal from "sweetalert2";

const AddContactModal = ({ open, handleClose, handleAddContact, adressEmail, fetchEmails }) => {
  const [newContact, setNewContact] = useState({
    givenName: "",
    surname: "",
    displayName: "",
    companyName: "",
    phoneNumbers: [{ phoneNumber: "" }],
    emailAddresses: [{ email: "" }],
    physicalAddresses: [
      { street: "", city: "", country: "", zipcode: "" },
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleArrayChange = (e, index, field, arrayName) => {
    const { value } = e.target;
    let formattedValue = value;
    if (field === "phoneNumber" && (value.startsWith("06") || value.startsWith("07"))) {
      formattedValue = "+33" + value.slice(1);
    }
    setNewContact((prev) => {
      const updatedArray = [...prev[arrayName]];
      updatedArray[index][field] = formattedValue;
      return { ...prev, [arrayName]: updatedArray };
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await addContact(adressEmail, newContact);
      console.log("Réponse de l'API :", response);
      if (response.data.addContact) {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Contact ajouté avec succès.",
        });
      }
      handleClose();
      await fetchEmails(adressEmail); 
            } catch (error) {
      console.error("Erreur lors de l'ajout du contact :", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur est survenue lors de l'ajout du contact.",
      });
    }
  };


  return (
    <div className="modal-overlay" >
      <div className="modal-content" style={{ overflowY: "auto", maxHeight: "103vh" }}>
        <header className="modal-header">
          <h3 style={{ color: "rgb(25, 175, 120)" }}>Ajouter un nouveau contact</h3>
          <button className="close-modal" onClick={handleClose}>
            ×
          </button>
        </header>
        <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} >
          <Box display="flex" gap={2}>
            <TextField
              label="Prénom"
              name="givenName"
              value={newContact.givenName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Nom"
              name="surname"
              value={newContact.surname}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Box>
          <TextField
            label="Nom d'affichage"
            name="displayName"
            value={newContact.displayName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Entreprise"
            name="companyName"
            value={newContact.companyName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          {newContact.emailAddresses.map((email, index) => (
            <TextField
              key={index}
              label={`Email ${index + 1}`}
              value={email.email}
              onChange={(e) =>
                handleArrayChange(e, index, "email", "emailAddresses")
              }
              variant="outlined"
              fullWidth
            />
          ))}
          {newContact.phoneNumbers.map((phone, index) => (
            <TextField
              key={index}
              label={`Téléphone ${index + 1}`}
              value={phone.phoneNumber}
              onChange={(e) =>
                handleArrayChange(e, index, "phoneNumber", "phoneNumbers")
              }
              variant="outlined"
              fullWidth
            />
          ))}
          <Box display="flex" gap={2}>
            <TextField
              label="Rue"
              name="street"
              value={newContact.physicalAddresses[0].street}
              onChange={(e) =>
                handleArrayChange(e, 0, "street", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Ville"
              name="city"
              value={newContact.physicalAddresses[0].city}
              onChange={(e) =>
                handleArrayChange(e, 0, "city", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box display="flex" gap={2}>
            <TextField
              label="Pays"
              name="country"
              value={newContact.physicalAddresses[0].country}
              onChange={(e) =>
                handleArrayChange(e, 0, "country", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Code Postal"
              name="zipcode"
              value={newContact.physicalAddresses[0].zipcode}
              onChange={(e) =>
                handleArrayChange(e, 0, "zipcode", "physicalAddresses")
              }
              variant="outlined"
              fullWidth
            />
          </Box>
          <DialogActions>
          <Button onClick={handleClose}  className="text-reset">
            Annuler
          </Button>
          <Button onClick={handleSubmit} className="dash-btn-two tran3s mb-3">
            Ajouter
          </Button>
        </DialogActions>
        </Box>
      </DialogContent>

        
      </div>
    </div>
  );
};

export default AddContactModal;
