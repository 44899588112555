import React, { useEffect, useState } from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Alert, Box } from '@mui/material';
import { getListemailAdress } from '../../../../api/Services/emailService';
import { ClipLoader } from 'react-spinners';

const MailIndex = ({ setIsOpenSidebar }) => {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading]= useState(false);

  useEffect(() => {
    const fetchAdressmail = async () => {
      setIsLoading(true);
        try {
            const response = await getListemailAdress();
            const emailItems = response?.data?.getEmailAddresses?.items || [];
            console.log('Données extraites :', emailItems);
            setEmails(emailItems);
            
        } catch (error) {
            console.error('Erreur lors de la récupération des adresses e-mails :', error);
            setError('Une erreur est survenue lors de la récupération des adresses e-mails.');
        } finally {
            setIsLoading(false);
        }
    };
    fetchAdressmail();
}, []);


  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title mb-0 h2-titel text-center">Boîtes mail</h2>
        </div>

        <div className="bg-white card-box border-20 p-4">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                <ClipLoader size={50} color="#19AF78" />
              </div>
            ) : emails.length === 0 ? (
              <Box mt={3}>
                <Alert severity="info">Aucune adresse e-mail disponible.</Alert>
              </Box>
            ) : (
              emails.map((email, index) => (
                 <Link to={`/boites-mail-dashb/${email.emailAddress}`} className="d-block">
                                                    <button 
                                                        key={index} 
                                                        type="submit" 
                                                        className="btn-eleven fw-500 tran3s d-block mt-20"
                                                    >
                                                       {email.emailAddress || `Adresse mail ${index + 1}`}
                                                    </button>
                                                </Link>
               
              ))
            )}

        <button type="button" className="btn-eleven fw-500 tran3s d-block mt-40">
          <Link to="/ajouter-adresse-email">Commander une nouvelle adresse</Link>
        </button>
      </div>

      </div>
    </div>
  );
};

export default MailIndex;
