import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isDomiciliationSelected = location.state?.isDomiciliationSelected || false;
  const selectedService = location.state?.selectedService || 'Minimal'; 


  console.log("selectedService",selectedService);
  const handleNextStep = () => {
 console.log(isDomiciliationSelected);
    navigate('/create_soc4', { state: { isDomiciliationSelected ,selectedService } });
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >CRÉATION D’XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two ">
        <div className="container">
          <div className="text-center">
            <div className="text-center">
              <h2 className="fw-600">
                Ajoutez d’autres services Pharos pour une commande plus complète
              </h2>
             </div>
             <div className="d-flex mb-1">
                                              <Link to="/create_soc2"
                                                      style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                      className="d-flex justify-content-around">
                                                  <span className="precedent-text">{"< Précédent"}</span>
                                              </Link>
                                              <Link to="#"
                                                      style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                                      className="d-flex justify-content-center">
                                  
                                                  <span className="d-flex justify-content-center text-center">3/4</span>
                                              </Link>
                                              <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                          </div>
           
          </div>
          
          <div className="title-one text-center mb-1 lg-mb-2

 ">
            <h3 className="fw-600">
              Cabinet Comptable
            </h3>
            <p className="fw-600 mt-4">
              Pharos vous met en lien avec ses cabinets expert-comptable<br />
              partenaires pour vous accompagner et conseiller<br />
              dans votre comptabilité.
            </p>
          </div>

          <div className="row mt-3 mb-4">
         
            <div className=" text-center col-12">
            <CustomButton  onClick={handleNextStep}>
               Etre mis en contact et poursuivre la commande →
              </CustomButton >
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
