import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";

const ProtectedRoute = ({ children }) => {
  const navigate=useNavigate();
  const location = useLocation();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    const protectedRoutes = ["/dashboard"];

    if (protectedRoutes.includes(location.pathname) && !authToken) {
      navigate("/login"); 
    } else if (authToken) {
      axiosInstance.defaults.headers["Authorization"] = `Bearer ${authToken}`;
    }
  }, [navigate, location]);

  return children;
};
  // const token = localStorage.getItem("authToken");

  // if (!token) {
  //   return <Navigate to="/login" replace />; 
  // }

  // return children;
// };

export default ProtectedRoute;