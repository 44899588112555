import React from "react";
import { Link, useParams } from "react-router-dom";
import { TextField, Button, Grid, Typography, Container, Box } from "@mui/material";
import CustomButton from "../../../layouts/CustomButton";


const CandidateV3Area = () => {
    const { projectId } = useParams(); 

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
                <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className=" text-center">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two">
                <div className="container">
                    <div className="d-flex mb-1">
                                            <Link to={`/package-com2/${projectId}`}
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center"> 3/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                        </div>
                  
                                        <div className="title-one text-center " >
                                            <h3 className="fw-600">Avez-vous un logo ?</h3>
                                        </div>
                                        <div className="d-flex justify-content-center gap-2 mb-4
 mt-3">
                                                    <Link 
                                                        type="button" 
                                                        className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 me-2" 
                                                        style={{ height: "118px" }}
                                                        to={`/package-com4Y/${projectId}`}
                                                    >
                                                        <span>Oui</span>
                                                    </Link>
                                        
                                                    <Link 
                                                        type="button" 
                                                        className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4" 
                                                        style={{ height: "118px" }}
                                                        to={`/package-com4N/${projectId}`}
                                                       
                                                    >
                                                        <span>Non,je souhaite en créer un</span>
                                                    </Link>
                                                </div>
                                      
                   
                       
                    </div>

               
            </section>
          
        </>
    );
};

export default CandidateV3Area;
