const initialState = {
  projets: {}, 
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROJET':
      return {
        ...state,
        projets: {
          ...state.projets,
          [action.payload.id]: action.payload, 
        },
      };
    default:
      return state;
  }
};

export const setProjet = (projet) => ({ type: 'SET_PROJET', payload: projet });

export default invoiceReducer;
