import axiosInstance from '../axiosConfig';

export const searchEntreprise = async (enterpriseName) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
               query searchEnterprise($enterpriseName: String!) {
                  searchEnterprise(name: $enterpriseName){
                      items {
                          companyName
                          siren
                          address
                          postalCode
                          name
                          firstName
                      }
                  }
              }
          `
        ,
        variables: {
            enterpriseName
        }
  
      });
  
      console.log(response?.data?.data?.searchEnterprise);
      return response?.data?.data?.searchEnterprise;
    } catch (error) {
      console.error("error reuerations des achats:", error);
      throw error;
    }
};