import React from 'react';
import Header from "./Header";
import Footer from './Footer';
import ScrollToTopButton from '../Components/Theme/Theme';
import Contactindex from '../Components/frontend/login/login-area';

function Contact() {
    return (
        <>
            <Header></Header>
            <Contactindex></Contactindex>
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default Contact;