import React, { useEffect, useState } from "react";
import DashboardHeader from "../header";
import CandidateItem from "./candidate-item";
import { fetchAchats } from "../../../../api/Services/achatService";
import { fetchServicePice } from "../../../../api/Services/paymentSevice";
import { ClipLoader } from "react-spinners";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Alert } from "react-bootstrap";
import { styled } from "@mui/system";

const MesAchatsIndex = ({ setIsOpenSidebar }) => {
  const StyledTableContainer = styled(TableContainer)({
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "10px",
  });

  const StyledTableCell = styled(TableCell)({
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    verticalAlign: "middle",
    padding: "10px",
  });

  const SmallCell = styled(TableCell)({
    textAlign: "center",
    width: "80px",
    verticalAlign: "middle",
  });

  const [candidateData, setCandidateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const [purchasesResponse, pricesResponse] = await Promise.all([
          fetchAchats(),
          fetchServicePice(),
        ]);

        const purchases = purchasesResponse.data?.getUserPurchases?.items || [];
        const prices = pricesResponse?.items || [];

        const priceMap = prices.reduce((map, price) => {
          map[price.serviceType] = price;
          return map;
        }, {});

        const mappedData = purchases.map((purchase) => {
          const totalPrice = (purchase.services || []).reduce((sum, service) => {
            const servicePrice = priceMap[service]?.amount || 0;
            return sum + servicePrice;
          }, 0);
          console.log("id",purchase.projectId);
          return {
            id: purchase.projectId,
            name: purchase.projectName,
            date: purchase.creationDate,
            price: `${totalPrice} €`,
            location: purchase.invoiceId,
          };
          
        });

        setCandidateData(mappedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <Typography variant="h4" align="center" gutterBottom className="main-title h2-title">
          Mes achats
        </Typography>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center p-0" style={{ height: "100px"}}>
            <ClipLoader size={50} color="#19AF78" />
          </div>
        ) : candidateData.length > 0 ? (
          <StyledTableContainer component={Paper} className="p-0">
            <Table className="job-alert-table" style={{ tableLayout: "fixed", width: "100%" }}>

              <TableHead>
                <TableRow>
                <StyledTableCell style={{ width: "25%" }}>Nom du Projet</StyledTableCell>
                <StyledTableCell style={{ width: "25%" }}>Date</StyledTableCell>
                <StyledTableCell style={{ width: "25%" }}>Montant</StyledTableCell>
                <SmallCell style={{ width: "25%" }}>PDF</SmallCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {candidateData.map((purchase) => (
                  <CandidateItem
                    id={purchase.id}
                    nom={purchase.name}
                    date={purchase.date}
                    price={purchase.price}
                    invoiceId={purchase.location}
                  />
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : (
          <Alert variant="info">Aucun achat disponible.</Alert>
        )}
      </div>
    </div>
  );
};

export default MesAchatsIndex;