import { Link, useNavigate } from 'react-router-dom';
import DashboardHeader from '../../header';
import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  InputLabel,
  FormControl,
} from '@mui/material';
import RechecherSociete from '../factures/rechercheSociete';
import { useSelector } from 'react-redux';
import { UpdateClient } from '../../../../../api/Services/clientService';
import Swal from 'sweetalert2';

const InfoSocieteClient = ({ setIsOpenSidebar }) => {
  const [ShowSearchModal, setShowModal] = useState(false);
  const handleCloseSearcheModal = () => setShowModal(false);
  const handleShowSearchModal = () => setShowModal(true);
  const selectedEntreprise = useSelector((state) => state.entreprise.selectedEntreprise);
  console.log("selectedEntreprise",selectedEntreprise);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    companyName: selectedEntreprise?.companyName || '',
    siren: selectedEntreprise?.siren || '',
    siret: selectedEntreprise?.siret || '',
    name: selectedEntreprise?.name || '',
    firstName: selectedEntreprise?.firstName || '',
    telephoneMobile: selectedEntreprise?.telephoneMobile || '',
    telephoneFixe: selectedEntreprise?.telephoneFixe || '',
    email: selectedEntreprise?.email || '',
    address: selectedEntreprise?.address || '',
    postalCode: selectedEntreprise?.postalCode || '',
    ville: selectedEntreprise?.ville || '',
    civilite: '',
    fonction: '',
  });
  useEffect(() => {
    if (selectedEntreprise) {
      setFormData({
        companyName: selectedEntreprise.companyName || '',
        siren: selectedEntreprise.siren || '',
        name: selectedEntreprise.name || '',
        firstName: selectedEntreprise.firstName || '',
        telephoneMobile: selectedEntreprise.telephoneMobile || '',
        telephoneFixe: selectedEntreprise.telephoneFixe || '',
        email: selectedEntreprise.email || '',
        address: selectedEntreprise.address || '',
        postalCode: selectedEntreprise.postalCode || '',
        ville: selectedEntreprise.ville || '',
        civilite: '',
        fonction: '',
      });
    }
  }, [selectedEntreprise]);
  console.log("formData",formData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const input = {
        companyName: formData.companyName,
        siret: formData.siren,
        // name: formData.name,
        contactName: formData.firstName,
        phoneNumber:  formData.telephoneMobile,
        // telephoneMobile: formData.telephoneMobile,
        // telephoneFixe: formData.telephoneFixe,
        email: formData.email,
        address: formData.address,
        // postalCode: formData.postalCode,
        // ville: formData.ville,
        // civilite: formData.civilite,
        // fonction: formData.fonction,
      };
     console.log('input',input);
      const response = await UpdateClient(input);
       if (response) {
                Swal.fire({
                  icon: "success",
                  title: "Envoyé",
                  text: "Email sauvegarder avec succès.",
                });
                navigate('/ma-facturation-clients-fournisseurs');
              }
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Erreur lors de sauvegarde de l'email.",
              });
 } 
  };

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel">
          Ma facturation &gt;Projet nom &gt;Modifier un fournisseur/client
        </h2>

        <Box sx={{ backgroundColor: 'white', p: 3, borderRadius: 3, boxShadow: 3 }}>
          <Typography variant="h5" align="center" gutterBottom>
            <u>Les coordonnées de votre fournisseur/Client</u>
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="h6">
                <u>L'entreprise</u>
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Raison Sociale*"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Siret*"
                name="siren"
                value={formData.siren}
                onChange={handleInputChange}
              />
             
              <Button onClick={handleShowSearchModal} variant="primary">
                Trouver une société
              </Button>
            </Grid>

            {ShowSearchModal && (
              <RechecherSociete
                ShowSearchModal={ShowSearchModal}
                handleCloseModalSearch={handleCloseSearcheModal}
              />
            )}

            <Grid item xs={6} md={7} container justifyContent="center">
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                <u>Contact</u>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Civilité*</InputLabel>
                    <Select
                      name="civilite"
                      value={formData.civilite}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="client1">C 1</MenuItem>
                      <MenuItem value="client2">C 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Fonction*</InputLabel>
                    <Select
                      name="fonction"
                      value={formData.fonction}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="client1">Fct 1</MenuItem>
                      <MenuItem value="client2">Fct 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Prénom*"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Nom*"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Téléphone mobile*"
                    name="telephoneMobile"
                    value={formData.telephoneMobile}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Téléphone Fixe*"
                    name="telephoneFixe"
                    value={formData.telephoneFixe}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Email*"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={5}>
              <Typography variant="h6">
                <u>L'adresse</u>
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Adresse*"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Code Postal*"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Ville*"
                name="ville"
                value={formData.ville}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              component={Link}
              to="/ma-facturation-clients-fournisseurs"
              variant="outlined"
              sx={{
                mx: 2,
                color: 'rgb(25, 175, 120)',
                borderColor: 'rgb(25, 175, 120)',
                '&:hover': {
                  backgroundColor: 'transparent',
                  borderColor: 'rgb(25, 175, 120)',
                  color: 'rgb(25, 175, 120)',
                },
              }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'rgb(25, 175, 120)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgb(25, 175, 120)',
                  color: 'white',
                },
              }}
              onClick={handleSubmit}
            >
              Modifier
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default InfoSocieteClient;