import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://zwf6mlywrrgpzanodo5a76omne.appsync-api.eu-west-1.amazonaws.com', 
});

const token = localStorage.getItem("authToken");
if (token) {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 402 )) {
      localStorage.removeItem("authToken");
      window.location.href = '/login'; 
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
