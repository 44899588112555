import React, { useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";

const ModalDateFact = ({ open, handleClose, date, setDate }) => {
  const [tempDate, setTempDate] = useState(date); 

  const handleDateChange = (e) => {
    setTempDate(new Date(e.target.value)); 
  };

  const handleConfirm = () => {
    setDate(tempDate); 
    handleClose();
  };

  const handleCancel = () => {
    setTempDate(date); 
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "26%",
      maxWidth: 800,
      maxHeight: "80vh",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <h4 className="mb-3">CHOIX DE LA DATE DU DOCUMENT</h4>
    
    <TextField
      label="Date"
      fullWidth
      type="date"
      value={tempDate.toISOString().split("T")[0]}
      onChange={handleDateChange}
      sx={{ mb: 2, mt: 5 }}
      InputLabelProps={{
        shrink: true,
      }}
    />

    <Box sx={{ flexGrow: 1 }} />

    <Box sx={{ display: "flex", justifyContent: "center", gap: 2, pt: 2 }}>
      <Button onClick={handleConfirm} variant="outlined" color="success">
        Confirmer
      </Button>
      <Button onClick={handleCancel} variant="outlined" color="error">
        Annuler
      </Button>
    </Box>
  </Box>
</Modal>

  );
};
export default ModalDateFact;