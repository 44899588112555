import React from "react";
import { Link } from "react-router-dom";
import { FaUserAlt, FaUserFriends, FaSlack } from "react-icons/fa";

const CandidateV3Area = () => {
    return (
        <>

<div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>

                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >Choisir son statut</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                    <div className="d-flex mb-1">
                                                              <Link to="/activite-statut-p1"
                                                                      style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                      className="d-flex justify-content-around">
                                                                  <span className="precedent-text">{"< Précédent"}</span>
                                                              </Link>
                                                              <Link to="#"
                                                                      style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                                                      className="d-flex justify-content-center">
                                                  
                                                                  <span className="d-flex justify-content-center text-center">5/5</span>
                                                              </Link>
                                                              <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                          </div>
                    <div className="text-center">
                       
                        <div className="title-one text-center mb-1 lg-mb-2

 ">
                            <h3 className="fw-600">Quels sont les modes de rémunérations souhaités ?</h3>
                        </div>
                    </div>
<div className="d-flex justify-content-center gap-2 mb-4"
>
                        <Link 
                            type="button" 
                            className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 me-2" style={{height: "177px" }}
                           to="/activite-statut-psarl"
                             
                         
                        >
                            <FaUserAlt className="mb-2" size={30} />
                            Versement d’un salaire
                        </Link>

                        <Link 
                            type="button" 
                            className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 "style={{height: "177px" }}
                            to="/activite-statut-psas"
                        >
                            <FaUserFriends className="mb-2" size={30} />
                            Versement de dividendes
                        </Link>
                        <Link 
                            type="button" 
                            className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 "style={{height: "177px" }}
                            to="/activite-statut-psarl"
                        >
                            <FaSlack className="mb-2" size={30} />
                            Les deux                   
                        </Link>
           </div>
                   
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
