import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TextField, Typography} from "@mui/material";


const CandidateV3Area = () => {
    const {projectId}= useParams();


    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className=" text-center" >CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two ">
                <div className="container">
                <div className="d-flex mb-3">
                                            <Link to={`/package-com6/${projectId}`} 
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center">10/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                         </div>
                    
                    <div className="title-one text-center mb-1 lg-mb-2

 ">
                        <h3 className="fw-600">Ajoutez un texte à propos de votre activité</h3>
                    </div>
                     <div className="d-flex justify-content-center gap-2 mb-4
">
                                                                                <Link 
                                                                                    type="button" 
                                                                                    className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4 me-2" 
                                                                                    style={{ height: "118px" }}
                                                                                    to={`/package-com8T/${projectId}`}
                                                                                >
                                                                                    <span>Décrivez-vous même votre activité</span>
                                                                                </Link>
                                                                    
                                                                                <Link 
                                                                                    type="button" 
                                                                                    className="btn-eleven fw-500 tran3s d-flex flex-column align-items-center p-4" 
                                                                                    style={{ height: "118px" }}
                                                                                    to={`/package-com8AI/${projectId}`}
                                                                                   
                                                                                >
                                                                                    <span>Générez un texte grâce à notre solution IA</span>
                                                                                </Link>
                                                                            </div>
                
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
