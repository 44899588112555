import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, TextField, Button } from "@mui/material";

import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '169px', paddingBottom: '5px' }}>
                      <div className="navigation-step mb-3">
                        <Link to="/rendez-vous2"><span className="precedent-text">{"< Précédent"}</span></Link>
                        <span>2/5</span>
                        <Link to="/rendez-vous5"><span className="precedent-text">{"Suivant >"}</span></Link>
                      </div>
                      
                    </div>
        
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
        <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >Formulez vos questions</h2>
                <h3 className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Cela permettra aux juristes de préparer au mieux votre entretien.</h3>
              </div>
        </div>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Entrez vos commentaires ici"
                />
              </Grid>
             </Grid>
             <div className="col-12 text-center mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/rendez-vous5" className="text-white">
                  Continuer → 
                </Link>
              </button>
            </div>
         </div>
       
      </section>

    </>
  );
};

export default CandidateV3Area;
