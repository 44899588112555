import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FicheEntreprise = ({ companyData = {}, onChange }) => {
  const defaultFormData = {
    name: "",
    address: "",
    phone: "",
    email: "",
    socialCapitalAmount: "",
    socialCapitalCurrency: "",
    legalStatus: "",
    capitalStockAmount :"",
    capitalStockCurrency:""

  };


  const [formData, setFormData] = useState({ ...defaultFormData, ...companyData });

  useEffect(() => {
    setFormData({ ...defaultFormData, ...companyData });
  }, [companyData]);

  const handleCompanyChange = (e) => {
    const { id, value } = e.target;

    const updatedData = {
      ...formData,
      [id]: value || "",
    };

    setFormData(updatedData);
    onChange(updatedData); // Propagation des données modifiées
  };

  // Composant réutilisable pour les champs de texte
  const FormInput = ({ id, label, type = "text", ...rest }) => (
    <TextField
      label={label}
      id={id}
      value={formData[id]}
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: !!formData[id] }}
      onChange={handleCompanyChange}
      type={type}
      {...rest}
    />
  );

  return (
    <div className="row">
    <div style={{ cursor: "pointer" }}>
        <Accordion>
            <AccordionSummary>
                <h4>
                    <ExpandMoreIcon style={{ marginRight: "8px" }} />
                    Fiche entreprise
                    </h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className="row mb-4">
                                      <div className="col-md-6">
                                         <TextField
                                          label="Nom de l'entreprise"
                                          fullWidth
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: !!formData.name, 
                                          }}
                                          id="name"
                                          value={formData.name}
                                          onChange={handleCompanyChange}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <TextField
                                          label="Adresse"
                                          fullWidth
                                          variant="outlined"
                                          id="address"
                                          InputLabelProps={{
                                            shrink: !!formData.address, 
                                          }}
                                          value={formData.address}
                                          onChange={handleCompanyChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <TextField
                                          label="Numéro de téléphone"
                                          fullWidth
                                          variant="outlined"
                                          id="phone"
                                          InputLabelProps={{
                                            shrink: !!formData.phone, 
                                          }}
                                          value={formData.phone}
                                          onChange={handleCompanyChange}
                                        />
                                        
                                      </div>
                                      <div className="col-md-6">
                                        <TextField
                                          label="Email"
                                          fullWidth
                                          variant="outlined"
                                          id="email"
                                          InputLabelProps={{
                                            shrink: !!formData.email, 
                                          }}
                                          value={formData.email}
                                          onChange={handleCompanyChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                    
                                      <div className="col-md-6">
                                        <TextField
                                          label="Montant du capital social"
                                          fullWidth
                                          variant="outlined"
                                          id="socialCapitalAmount"
                                          InputLabelProps={{
                                            shrink: !!formData.socialCapitalAmount, 
                                          }}
                                          value={formData.socialCapitalAmount}
                                          onChange={handleCompanyChange}
                                        />
                                      
                                      </div>
                                      <div className="col-md-6">
                                        <TextField
                                          label="Devise du capital social"
                                          fullWidth
                                          variant="outlined"
                                          id="socialCapitalCurrency"
                                          InputLabelProps={{
                                            shrink: !!formData.socialCapitalCurrency, 
                                          }}
                                          value={formData.socialCapitalCurrency}
                                          onChange={handleCompanyChange}
                                        />
                                        
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <TextField
                                          label="Montant des actions"
                                          fullWidth
                                          variant="outlined"
                                          id="capitalStockAmount"
                                          InputLabelProps={{
                                            shrink: !!formData.capitalStockAmount, 
                                          }}
                                          value={formData.capitalStockAmount}
                                          onChange={handleCompanyChange}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <TextField
                                          label="Devise des actions"
                                          fullWidth
                                          variant="outlined"
                                          id="capitalStockCurrency"
                                          InputLabelProps={{
                                            shrink: !!formData.capitalStockCurrency, 
                                          }}
                                          value={formData.capitalStockCurrency}
                                          onChange={handleCompanyChange}
                                        />
                                        
                                      </div>
                                    </div>
                                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-3">
                          <TextField
                           select
                            label="Statut légal du projet"
                            fullWidth
                            variant="outlined"
                            id="legalStatus"
                            value={formData.legalStatus}
                            InputLabelProps={{
                              shrink: !!formData.legalStatus, 
                            }}
                            onChange={(e) =>
                              handleCompanyChange({
                                target: { id: "legalStatus", value: e.target.value },
                              })
                            }
                          >
                            <MenuItem value="SAS">SAS</MenuItem>
                            <MenuItem value="SASU">SASU</MenuItem>
                            <MenuItem value="SARL">SARL</MenuItem>
                            <MenuItem value="EURL">EURL</MenuItem>
                            <MenuItem value="ASSOCIATION">Association</MenuItem>
                            <MenuItem value="AUTO_ENTREPRISE">Auto-Entreprise</MenuItem>
                          </TextField>
                        </div>
                      </div>
            {/* <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="name" label="Nom de l'entreprise" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="address" label="Adresse" />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="phone" label="Numéro de téléphone" type="tel" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="email" label="Email" type="email" />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="socialCapitalAmount" label="Montant du capital social" type="number" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <FormInput id="socialCapitalCurrency" label="Devise du capital social" />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  select
                  label="Statut légal du projet"
                  fullWidth
                  variant="outlined"
                  id="legalStatus"
                  value={formData.legalStatus}
                  onChange={(e) =>
                    handleCompanyChange({
                      target: { id: "legalStatus", value: e.target.value },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="SAS">SAS</MenuItem>
                  <MenuItem value="SASU">SASU</MenuItem>
                  <MenuItem value="SARL">SARL</MenuItem>
                  <MenuItem value="EURL">EURL</MenuItem>
                  <MenuItem value="ASSOCIATION">Association</MenuItem>
                  <MenuItem value="AUTO_ENTREPRISE">Auto-Entreprise</MenuItem>
                </TextField>
              </div>
            </div> */}
            </AccordionDetails>
        </Accordion>
    </div>
</div>
    // <div className="row">
    //   <div className="col-md-12">
    //     <Accordion style={{ boxShadow: "none", border: "none" }}>
    //       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    //         <h4>Fiche entreprise</h4>
    //       </AccordionSummary>
    //       <AccordionDetails>
    //         <div className="row mb-4">
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="name" label="Nom de l'entreprise" />
    //           </div>
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="address" label="Adresse" />
    //           </div>
    //         </div>
    //         <div className="row mb-4">
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="phone" label="Numéro de téléphone" type="tel" />
    //           </div>
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="email" label="Email" type="email" />
    //           </div>
    //         </div>
    //         <div className="row mb-4">
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="socialCapitalAmount" label="Montant du capital social" type="number" />
    //           </div>
    //           <div className="col-12 col-md-6 mb-3">
    //             <FormInput id="socialCapitalCurrency" label="Devise du capital social" />
    //           </div>
    //         </div>
    //         <div className="row mb-4">
    //           <div className="col-12 col-md-6 mb-3">
    //             <TextField
    //               select
    //               label="Statut légal du projet"
    //               fullWidth
    //               variant="outlined"
    //               id="legalStatus"
    //               value={formData.legalStatus}
    //               onChange={(e) =>
    //                 handleCompanyChange({
    //                   target: { id: "legalStatus", value: e.target.value },
    //                 })
    //               }
    //               InputLabelProps={{ shrink: true }}
    //             >
    //               <MenuItem value="SAS">SAS</MenuItem>
    //               <MenuItem value="SASU">SASU</MenuItem>
    //               <MenuItem value="SARL">SARL</MenuItem>
    //               <MenuItem value="EURL">EURL</MenuItem>
    //               <MenuItem value="ASSOCIATION">Association</MenuItem>
    //               <MenuItem value="AUTO_ENTREPRISE">Auto-Entreprise</MenuItem>
    //             </TextField>
    //           </div>
    //         </div>
    //       </AccordionDetails>
    //     </Accordion>
    //   </div>
    // </div>
  );
};

export default FicheEntreprise;
