import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProject } from '../../../redux/packageSlice';
import { TextField, Typography} from "@mui/material";

const CandidateV3Area = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});

    const [selectedSocialNetwork, setSelectedSocialNetwork] = useState(projectData.socialNetworks || {});

    useEffect(() => {
        setSelectedSocialNetwork(projectData.socialNetworks || {});
    }, [projectData]);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setSelectedSocialNetwork(prevState => ({
            ...prevState,
            [id]: value,  
        }));
    };

    const handleSubmit = () => {
        dispatch(setProject({
            projectId,
            data: {
                website: {
                    globalDetails: {
                        socialNetworks: selectedSocialNetwork
                    }
                }
            }
       }));
       
        console.log("Updated Social Networks on submit:", selectedSocialNetwork);
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: '135px', paddingBottom: '30px' }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                <div className="d-flex mb-3">
                                            <Link to={`/package-com6/${projectId}`} 
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center">9/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                         </div>
                    
                    <div className="title-one text-center mb-1 lg-mb-2

 ">
                        <h3 className="fw-600">Avez-vous des réseaux sociaux ?</h3>
                    </div>

                    {['instagram', 'facebook', 'snapchat', 'twitter', 'youtube', 'linkedin'].map((platform) => (
                        <div className="col-12 mb-4" key={platform}>
                        <TextField
                            fullWidth
                            label={platform.charAt(0).toUpperCase() + platform.slice(1)}
                            variant="outlined"
                            id={platform}
                            value={selectedSocialNetwork[platform] || ''}
                            placeholder={`lien ${platform.charAt(0).toUpperCase() + platform.slice(1)}`}
                            onChange={handleInputChange}
                        />
                    </div>
                    ))}

                    <div className="row mt-5 mb-4">
                        <div className="col-12">
                            <button type="button" onClick={handleSubmit} className="btn-eleven fw-500 tran3s d-block mt-20">
                              <Link to={`/package-com8/${projectId}`} className="d-block">
                                    Validez
                                </Link>                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com8/${projectId}`} className="d-block">
                                    Passez cette étape
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
