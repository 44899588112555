"use client"
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { listAccountancyInvoices, telechargerUrl } from '../../../../../api/Services/invoiceService';
import { ClipLoader } from 'react-spinners';


const FacturesIndex = ({setIsOpenSidebar}) => {
const {projectId}= useParams();
 const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
const [invoices, setInvoices] = useState([]);
const [error, setError] = useState(null);
const [isLoading, setIsLoading] = useState(true);
   const projet = useSelector((state) => state.invoice.projets[projectId]); 
   console.log("project",projet);

   useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setIsLoading(true);
        const result = await listAccountancyInvoices(projectId, 10, null);
        console.log(result);
  
        if (result.items && Array.isArray(result.items)) {
          const invoicesWithUrls = await Promise.all(
            result.items.map(async (invoice) => {
              const urlInvoice = await telechargerUrl(invoice.pdfS3Key);
              console.log("urlInvoice:", urlInvoice);
              return { ...invoice, urlInvoice };
            })
          );
  
          setInvoices(invoicesWithUrls); 
        } else {
          setInvoices([]); 
        }
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des factures.');
      } finally {
        setIsLoading(false);
      }
    };
    
  
    if (projectId) fetchInvoices();
  }, [projectId]);

  const handlePdfClick = async (pdfS3Key) => {
    try {
      const response = await telechargerUrl(pdfS3Key);
      if (response.url) {
        setPdfUrl(response.url);
        setShowPdf(true);
      } else {
        console.error("URL du PDF non valide");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF:", error);
    }
  };
  const handleDownloadClick = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";
      link.click();
    }
  };
  
return (
    <div className="dashboard-body">
    <div className="position-relative">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
  
      <h2 className="main-title h2-titel  text-center">Ma facturation &gt; {''}{projet.projectName}{' '} &gt;Liste des factures</h2>
  
      <div className="bg-white card-box border-20">
        
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader height="50" width="50"  color="#19AF78"  />
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : invoices.length > 0 ? (
          <div className="table-responsive mb-5" id="liste-table">
           <table className="table job-alert-table">
  <thead>
    <tr>
      <th scope="col" className="text-center">N° Facture</th>
      <th scope="col" className="text-center">Nom Client</th>
      <th scope="col" className="text-center">Statut</th>
      <th scope="col" className="text-center">Date</th>
      <th scope="col" className="text-center">Montant</th>
      <th scope="col" className="text-center">Facture PDF</th>
    </tr>
  </thead>
  <tbody className="border-0">
    {invoices.map((invoice, index) => (
      <tr key={index}> 
        <td className="text-center ">
          {invoice.status === "VALIDATED" ? (
            invoice.numberId || "--"
          ) : (
            <Link to={`/ma-facturation-factures-modifier/${projet.projectId}/${invoice.numberId.replace(/\//g, "-")}`}  className="hover-green">
              {invoice.numberId || "--"}
             
            </Link>

          )}
        </td>                    
        <td className="text-center">
          {invoice.accountancyClient?.companyName || "--"}
        </td>
        <td className="text-center">
          {invoice.status === "VALIDATED" ?  
            <span className="badge py-3 px-4 fs-7 badge-light-success">
              Validé
            </span> 
            : 
            <span className="badge py-3 px-4 fs-7 badge-light-primary">
              Brouillon
            </span> 
          }
        </td>
        <td className="text-center">
          {invoice.creationDate ? new Date(invoice.creationDate).toLocaleDateString() : "--"} {/* Formatage de la date */}
        </td>
        <td className="text-center">
          {(
            invoice.products?.reduce(
              (total, product) =>
                total + product.quantity * product.unitPrice,
              0
            ) || 0
          ).toFixed(2)}{" "}
          {invoice.products?.[0]?.currency || "€"}
        </td>
        <td className="text-center">
          <span onClick={() => handlePdfClick(invoice.pdfS3Key)}>
            {invoice.status === "VALIDATED" ? (
              <i className="fas fa-file-alt text-success fa-2x" title="Validé"></i>
            ) : (
              <i className="fas fa-file-alt text-danger fa-2x" title="Autre"></i>
            )}
          </span>
        </td>
      </tr>
    ))}
  </tbody>
</table>

          </div>
          
        ) : (
          <div className="text-center mt-4">
            <p className="text-muted">
              Aucune donnée disponible pour le moment.
            </p>
          </div>
        )}
        <div className="d-flex justify-content-between mb-4">
       <Link
            to={`/ma-facturation-factures-create/${projet.projectId}`}
            className="dash-btn-two tran3s "
          >
            Créer une facture
          </Link>
        </div>
  
  
        {showPdf && pdfUrl && (
          <div className="pdf-viewer">
            <div className="viewer-header">
              <button onClick={() => setShowPdf(false)} className="close-button">
                x
              </button>
              <button onClick={handleDownloadClick} className="download-button">
                Télécharger
              </button>
            </div>
            <iframe
              src={pdfUrl}
              title="PDF Viewer"
              width="100%"
              height="100%"
              type="application/pdf"
            ></iframe>
          </div>
        )}
      </div>
      <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
      `}</style>
    </div>
  </div>
  
    
  );
};

export default FacturesIndex;