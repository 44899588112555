"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container text-center">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '169px', paddingBottom: '3px' }}>
            <div className="navigation-step mb-3">
              <Link to="/rendez-vous2"><span className="precedent-text">{"< Précédent"}</span></Link>
              <span>2/5</span>
              <Link to="/rendez-vous4"><span className="precedent-text">{"Suivant >"}</span></Link>
            </div>
            
          </div>
        </div>
      </div>
      <section className="candidates-profile pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
      <div className="container text-center">

        <h2 >Quel est l'objet de votre question ?</h2>
        <h3 className="text-md mt-35 mb-50 lg-mb-30">Sélectionnez les thèmes que vous souhaitez aborder avec nos experts (plusieurs choix possibles)</h3>
          <div className="row mt-5">
            <div className="col-12">
              <div className="row">
                {[
                  { id: "option1", label: "Des infos sur la fiscalité", icon: <FaCalendarDay size={40} /> },
                  { id: "option2", label: "Aides et financement", icon: <FaCommentDots size={40} /> },
                  { id: "option3", label: "Choix du statut juridique", icon: <FaQuestionCircle size={40} /> },
                  { id: "option4", label: "Autre", icon: <FaCheckCircle size={40} /> },
                ].map((item) => (
                  <div className="col-md-6 mb-4" key={item.id}>
                    <div className="card text-center position-relative p-4">
                      <input
                        type="checkbox"
                        id={item.id}
                        className="position-absolute top-0 end-0 m-2"
                        checked={selectedOptions.includes(item.label)}
                        onChange={() => handleCheckboxChange(item.label)}
                      />
                      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '150px' }}>
                        {item.icon}
                        <label htmlFor={item.id} className="mt-3">{item.label}</label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/rendez-vous4" className="text-white">Continuer →</Link>
              </button>
            </div>
          </div>
        </div>
        <style>
          {`
            .navigation-step {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 202px;
            }
            .navigation-step a {
              color: #333;
              text-decoration: none;
              font-weight: bold;
            }
            .navigation-step span {
              font-size: 16px;
            }
            .card {
              border-radius: 10px;
              padding: 20px;
            }
            .btn-eleven {
              background-color: #007bff;
              color: white;
              padding: 10px 20px;
              border: none;
              border-radius: 5px;
            }
          `}
        </style>
      </section>
    </>
  );
};

export default CandidateV3Area;
