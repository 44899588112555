import React, { useEffect, useState } from 'react';
import { FaFolder, FaTrash, FaEllipsisV, FaFile, FaSearch,FaChevronDown,FaChevronUp, FaArrowLeft } from 'react-icons/fa';
import { Modal, Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DashboardHeader from '../header';
import { getWebpackageOrder } from '../../../../api/Services/webPackageService';
import { fetchSearchDocument, getDMSDocuments, getDMSTrashDocuments } from '../../../../api/Services/gedService';
import DeplacerDocument from './Modals/DeplacerDocument';
import { Pagination, styled } from '@mui/material';
import { ClipLoader } from 'react-spinners';

const GedCorbeille = ({ setIsOpenSidebar }) => {
  const [folders, setFolders] = useState([]);
  const { projectId } = useParams();
  
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [searchResults, setSearchResults] = useState([]); 
  const [noResults, setNoResults] = useState(false);
  const handleShowDeplacer = (documentId) => { setSelectedDocumentId(documentId); setShowModalDeplcer(true)};
  const [showDeplacer,setShowModalDeplcer]=useState(false);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [nextToken, setNextToken] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [selectedSubFolder, setSelectedSubFolder] = useState(null);
  
   const [navigationPath, setNavigationPath] = useState([]);
  

 const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
      color: '#000',
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(25, 175, 120)!important',
      color: '#fff',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: '#000',
    },
  });
  
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchFolders(newPage);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(folders.length / itemsPerPage);
  
  const fetchSubfolders = async (parentId) => {
    try {
      const response = await getDMSDocuments(projectId, parentId, itemsPerPage, nextToken);
      return response?.items || [];
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-dossiers:', error);
      return [];
    }
  };
  
  const fetchFolders = async (page = 1) => {
    try {
      setLoading(true);
      const response = await getDMSTrashDocuments(projectId, itemsPerPage, nextToken);
      const items = response?.items || [];
      const token = response?.nextToken || null;
  
      const foldersWithSubfolders = await Promise.all(
        items.map(async (folder) => {
          if (folder.documentType === "FOLDER") {
            const subfolders = await fetchSubfolders(folder.documentId);
            return { ...folder, subfolders };
          }
          return folder;
        })
      );
  
      if (page === 1) {
        setFolders(foldersWithSubfolders);
      } else {
        setFolders((prevFolders) => [...prevFolders, ...foldersWithSubfolders]);
      }
  
      setNextToken(token);
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers:', error);
    } finally {
      setLoading(false);
    }
  }
  const toggleFolder = (folderId) => {
    console.log("Toggle folder", folderId); 
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
};

    const fetchProject = async () => {
      try {
        const response = await getWebpackageOrder(projectId);
        setProject(response?.data?.getWebpackageOrder || {});
      } catch (error) {
        console.error('Erreur lors de la récupération du projet :', error);
      }
    };


    const paginateFolders = (folders, page, itemsPerPage) => {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return folders.slice(startIndex, endIndex);
    };
    const paginatedFolders = paginateFolders(folders, currentPage, itemsPerPage);

    const renderFolders = (subfolders) => {
      return (
        <ul>
          {subfolders.map((subfolder) => (
            <li key={subfolder.documentId}>
              <div
                onClick={() => toggleFolder(subfolder.documentId)}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              >
                <span style={{ marginRight: "10px" }}>
                  {subfolder.documentType === "FOLDER" && (openFolders[subfolder.documentId] ? <FaChevronUp /> : <FaChevronDown />)}
                </span>
                {subfolder.documentType === "FOLDER" ? <FaFolder /> : <FaFile />} {subfolder.documentName}
              </div>
              {subfolder.subfolders && subfolder.subfolders.length > 0 && openFolders[subfolder.documentId] && (
                <div style={{ marginLeft: "20px" }}>
                  {renderFolders(subfolder.subfolders)}
                </div>
              )}
            </li>
          ))}
        </ul>
      );
    };

    const handleSearchDocument = async () => {
      try {
        if (!searchTerm.trim()) {
          setSearchResults([]);
          setNoResults(false);
          return;
        }
  
        setLoading(true);
        setNoResults(false);
  
        const results = await fetchSearchDocument(projectId, searchTerm);
        setSearchResults(results.items || []);
        setNoResults(results.items?.length === 0);
      } catch (error) {
        console.error('Erreur lors de la recherche :', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchProject();
      fetchFolders();
    }, [projectId]);
  
    const handleFolderClick = async (folder) => {
      if (folder.documentType === "FOLDER") {
        setSelectedFolder(folder);
        setNavigationPath((prev) => [...prev, folder]);
    
        const subfolders = await fetchSubfolders(folder.documentId);
        setSelectedFolder((prev) => ({ ...prev, subfolders }));
      }
    };
    const handleNavigationClick = (index) => {
      const newPath = navigationPath.slice(0, index + 1);
      setNavigationPath(newPath);
      if (newPath.length > 0) {
        setSelectedFolder(newPath[newPath.length - 1]);
        setSelectedSubFolder(null);
      } else {
        setSelectedFolder(null);
        setSelectedSubFolder(null);
      }
    };
  
    const handleGoBack = () => {
      if (navigationPath.length > 0) {
        const newPath = navigationPath.slice(0, navigationPath.length - 1);
        setNavigationPath(newPath);
        if (newPath.length > 0) {
          setSelectedFolder(newPath[newPath.length - 1]);
          setSelectedSubFolder(null);
        } else {
          setSelectedFolder(null);
          setSelectedSubFolder(null);
        }
      }
    };
  const renderBreadcrumb = () => {
    return (
      <div className="breadcrumb">
        <button onClick={handleGoBack} style={{ marginRight: '10px' }}><FaArrowLeft size={15} /> 
        </button>
        <span onClick={() => handleNavigationClick(-1)} style={{ cursor: 'pointer' }}>Ma Corbeille</span>
        {navigationPath.map((folder, index) => (
          <span key={index} onClick={() => handleNavigationClick(index)} style={{ cursor: 'pointer' }}>
            {' > '} {folder.documentName}
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className="dashboard-body">
    <div className="position-relative w-100">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
      <h2 className="d-flex flex-column align-items-center main-title h2-titel">
        GED &gt;{project.projectName}
      </h2>

      <div className="row" style={{ height: "90%" }}>
      <div className="sidebar col-3">
          <h2 className="d-flex flex-column main-title h2-titel">Pharos GED</h2>
          
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
              <ClipLoader size={50} color="#FFFFFF" />
            </div>
          ) : (
            <ul className="folder-tree">
              <div>
                <Link to={`/ged/${project.projectId}`} style={{ textDecoration: "none" }}>Ma GED</Link>
                {Array.isArray(folders) && folders.length > 0 ? (
                  <ul>
                    {folders
                      .filter((folder) => folder.documentType === "FOLDER")
                      .map((folder) => (
                        <li key={folder.documentId}>
                          <div
                            onClick={() => toggleFolder(folder.documentId)}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "10px" }}>
                              {openFolders[folder.documentId] ? <FaChevronUp /> : <FaChevronDown />}
                            </span>
                            <FaFolder style={{ marginRight: "5px" }} />
                            {folder.documentName || "cdfg"}
                          </div>
                          {folder.subfolders && folder.subfolders.length > 0 && openFolders[folder.documentId] && (
                            <div style={{ marginLeft: "20px" }}>
                              {renderFolders(folder.subfolders)}
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                ) : (
                  <li>Aucun dossier disponible</li>
                )}
              </div>
            </ul>
          )}
        </div>
        <div className="card content-area col-8">
          <div className="search-form mt-0 mb-20" style={{ position: "relative" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher dans ma GED..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchDocument();
                }
              }}
              style={{ paddingLeft: "35px" }}
            />
            <button
              onClick={handleSearchDocument}
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
              }}
            >
              <img
                src="/assets/dashboard/images/icon/icon_10.svg"
                alt="icon"
                className="lazy-img"
                style={{
                  height: "16px",
                }}
              />
            </button>
          </div>
          {renderBreadcrumb()}
          
          <div className="col-md-12">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                  <ClipLoader size={50} color="#19AF78" />
                </div>
              ) : searchResults.length > 0 ? (
                <div className="row">
                  {searchResults.map((folder, index) => (
                    <div key={index} className="col-md-4 mb-3">
                      <Card>
                        <Card.Body className="position-relative">
                          <Card.Title className="fadeInUp">
                            {folder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {folder.documentName}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              ) : noResults ? (
                <p>Aucun résultat trouvé.</p>
              ) : selectedFolder ? (
                <div className="row">
                  {selectedFolder.subfolders && selectedFolder.subfolders.length > 0 ? (
                    selectedFolder.subfolders.map((subfolder, index) => (
                      <div key={index} className="col-md-4 mb-3" onClick={() => handleFolderClick(subfolder)}>
                        <Card>
                          <Card.Body className="position-relative">
                            <Card.Title className="fadeInUp">
                              {subfolder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {subfolder.documentName}
                            </Card.Title>
                          </Card.Body>
                          <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                            <button
                              className="action-btn dropdown-toggle position-absolute"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ top: '0px', right: '0px' }}
                            >
                              <FaEllipsisV />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to="#" onClick={() => handleShowDeplacer(subfolder.documentId)}>
                                  <img
                                    src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                                    alt="Restaurer icon"
                                    className="lazy-img"
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  Restaurer
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <p>Aucun sous-dossier disponible.</p>
                  )}
                </div>
              ) : (
                <div className="row">
                  {paginatedFolders.map((folder, index) => (
                    <div key={index} className="col-md-4 mb-3" onClick={() => handleFolderClick(folder)}>
                      <Card>
                        <Card.Body className="position-relative">
                          <Card.Title className="fadeInUp">
                            {folder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {folder.documentName}
                          </Card.Title>
                        </Card.Body>
                        <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                          <button
                            className="action-btn dropdown-toggle position-absolute"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ top: '0px', right: '0px' }}
                          >
                            <FaEllipsisV />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                            <li>
                              <Link className="dropdown-item" to="#" onClick={() => handleShowDeplacer(folder.documentId)}>
                                <img
                                  src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                                  alt="Restaurer icon"
                                  className="lazy-img"
                                  style={{ width: '20px', height: '20px' }}
                                />
                                Restaurer
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <CustomPagination
          count={totalPages}  
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
        />
        

      </div>
    </div>
    {showDeplacer && (
      <DeplacerDocument
        showDeplacer={showDeplacer}
        handleCloseDeplacer={handleCloseDeplacer}
        projectId={projectId}
        documentId={selectedDocumentId}
      />
    )}

<style>{`
  .dashboard-body {
    display: flex;
  }
  .sidebar {
    background-color: rgb(25, 175, 120) !important;
    color: white;
    padding: 20px;
  }
  
  .folder-tree {
    list-style: none;
    padding-left: 0;
  }
  
  .content-area {
    flex: 1;
    padding: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .actions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .actions button {
    padding: 10px 15px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .breadcrumb {
    margin-bottom: 20px;
  }
  
  .folder-tree ul {
    list-style-type: none; 
    padding-left: 0;
  }
  
  .folder-tree li {
    margin-bottom: 5px; 
  }
  
  .ma-ged-link {
    color: green; /* Couleur du texte en vert */
    font-weight: bold; /* Optionnel: pour mettre en gras */
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
    }
    .content-area {
      width: 100%;
    }
  
    .breadcrumb {
      font-size: 14px;
    }
  
    .actions button {
      font-size: 12px;
      padding: 10px;
    }
  }
`}</style>
    </div>
  );
};

export default GedCorbeille;
