import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { fetchGetCompanyProject, fetchUpSertCompany } from '../../../../../api/Services/companyService';
import AddAssocie from '../../../../../layouts/AddAssocie';
import DashboardHeader from '../../header';
import Swal from 'sweetalert2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import FicheDirigeant from './FicheDirigeant';
import FicheAssocies from './FicheAssocies';
import FicheEntreprise from './FicheEntreprise';
import FicheDocument from './FicheDocument';

const InformationsSociete = ({ setIsOpenSidebar }) => {
const {projectId}=useParams();

    const project = useSelector((state) => state.projet_communication?.projets?.[projectId]);
    // console.log("project",project);
  
  const [companyData, setCompanyData] = useState(null);
  const [partnerData, setPartnerData] = useState([]);
  const [isLoading, setIsLoading]=useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [updatedProject, setupdatedProject] = useState({
    projectId: '',
    projectName: '',
    legalStatus: '',
    projectStatus: '',
    filesUploadedByAccountancyFirm: [],
  });

  const fetchProjectData = async () => {
    if (!project?.projectId) return;
  
    setIsLoading(true);
    try {
      const result = await fetchGetCompanyProject(project.projectId);
      if (result) {
        setupdatedProject({
          projectId: result.projectId,
          projectName: result.projectName,
          legalStatus: result.legalStatus,
          projectStatus: result.projectStatus,
        });
  
        setCompanyData({
          ...result.companyDetail,
          legalStatus: result.legalStatus,
        });
  
        setPartnerData(result.companyPartners || []);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Impossible de charger les données du projet.',
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchProjectData();
  }, [project?.projectId]);


  const handleCompanyChange = useCallback((updatedData) => {
    setCompanyData(updatedData);
  }, []);
  
  const handlePartnerChange = useCallback((updatedPartners) => {
    setPartnerData(updatedPartners);
  }, []);

  useEffect(() => {
    const isCompanyDataValid =companyData?.companyDetail;
    const isPartnerDataValid = partnerData.every(partner => 
      partner.name && partner.dateOfBirthday && partner.placeOfBirthday && partner.nationality
    );

setIsButtonDisabled(!(isCompanyDataValid && isPartnerDataValid));
  }, [companyData, partnerData]);

const handleSubmit = async () => {
  const input = {
    projectId: updatedProject?.projectId,
    legalStatus: companyData?.legalStatus || '',
    companyDetail: { ...companyData, legalStatus: undefined },
    companyPartners: partnerData.map((partner) => ({
      partnerId: partner.partnerId || `partnerId${Math.random().toString(36).substr(2, 9)}`, 
      name: partner.name,
      isMainLeader: partner.isMainLeader || false, 
      firstNames: Array.isArray(partner.firstNames) ? partner.firstNames : [partner.firstNames],
      dateOfBirthday: partner.dateOfBirthday,
      placeOfBirthday: partner.placeOfBirthday,
      nationality: partner.nationality,
      professionalSituation: partner.professionalSituation,
      socialSecurityNumber: partner.socialSecurityNumber,
      spouseIdentity: partner.spouseIdentity || null,
    })),
  };

  try {
    const result = await fetchUpSertCompany(input);
    Swal.fire({
      icon: 'success',
      title: updatedProject?.projectId ? 'Mise à jour réussie' : 'Création réussie',
      text: 'Les données de l’entreprise ont été soumises avec succès.',
    });
    await fetchProjectData();
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Erreur',
      text: `Échec de la soumission des données : ${error.message}`,
    });
  }
};
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className="main-title h2-title"
        >
          <span className="mb-3">Informations de la société </span>
          <br />
          <Typography
            variant="h4"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            {updatedProject?.projectName || ''}
          </Typography>

        </Typography>
        <div className="bg-white card-box border-20">
          <FicheDirigeant partnerData={partnerData} onChange={handlePartnerChange}/>
         <hr className="mb-30 mt-30" />
          <FicheDocument/>
         <hr className="mb-30 mt-30" />
         <FicheEntreprise companyData={companyData} onChange={handleCompanyChange} />
          <hr className="mb-30 mt-30" />
         <FicheAssocies partnerData={partnerData} onChange={handlePartnerChange}/>
          <div className="d-flex justify-content-center mt-4">
            <button className="dash-btn-two tran3s me-3" type="button" onClick={handleSubmit}        
             disabled={isButtonDisabled}
            >
              Mettre à jour
            </button>
          </div>
      
        </div>

     
      </div>
     
    </div>
  );
};

export default InformationsSociete;