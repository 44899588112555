import axiosInstance from '../axiosConfig';

export const getAccountancyProjectConfigs = async (limit,nextToken) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
                query getAccountancyProjectConfigs($limit: Int, $nextToken: String){
                    getAccountancyProjectConfigs(limit: $limit, nextToken: $nextToken){
                        items{
                            projectId
                            projectName
                        }
                        nextToken
                    }

                }
          `
        ,
        variables: {
            limit,nextToken
        }
  
      });
  
      return response?.data?.data?.getAccountancyProjectConfigs;
    } catch (error) {
      console.error("error reuerations des projets factures :", error);
      throw error;
    }
};

export const getAccountancyIssuerConfig = async (projectId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
             query getAccountancyIssuerConfig($projectId: String!){
        getAccountancyIssuerConfig(projectId: $projectId){
            projectId
            companyName
            socialStatus
            siret
            contactName
            address
            phoneNumber
            email
            vatNumber
            nafNumber
            capitalStockAmount
            capitalStockCurrency
            iban
            bicSwift  
            generalCondition
                bankName
                bankAddress
                bankAccountName
                
        }
}
        `
      ,
      variables: {
        projectId
      }

    });

    console.log(response?.data?.data?.getAccountancyIssuerConfig);
    return response?.data?.data?.getAccountancyIssuerConfig;
  } catch (error) {
    console.error("error reuerations des  info projets factures :", error);
    throw error;
  }
};
export const putAccountancyIssuerConfig = async (input) => {
  try {

    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation putAccountancyIssuerConfig($input: AccountancyIssuerInput) {
          putAccountancyIssuerConfig(input: $input)
        }
      `,
      variables: { input },
    });

    console.log("Réponse brute du serveur:", response.data);


    const responseData = response?.data?.data?.putAccountancyIssuerConfig;
 return responseData;
  } catch (error) {
    console.error('Erreur lors de la modification :', error.message);
    throw error;
  }
};



 /*upoad files*/
 export const getLogoUpload = async (projectId,fileName) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation generateS3AccountancyLogoPreSignedUploadUrl($projectId: String!, $fileName: String!) {
            generateS3AccountancyLogoPreSignedUploadUrl(projectId: $projectId, fileName: $fileName) {
                    url
                    fields {
                        key
                        xAmzAlgorithm
                        xAmzCredential
                        xAmzDate
                        xAmzSecurityToken
                        policy
                        xAmzSignature
                    }
            }
        }
      `,
      variables: {
        projectId,fileName,
      },
    });
    return response.data.data.generateS3AccountancyLogoPreSignedUploadUrl;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};
export const uploadLogoToS3 = async (projectId,file) => {
  try {
    const data = await getLogoUpload(projectId,file.name);
    console.log("datafrg", data.fields.key);
   const  s3Key=data.fields.key

    const response = await axiosInstance.post('/graphql', {
      query: `
              mutation updateAccountancyLogo($projectId: String!, $s3Key: String!) {
              updateAccountancyLogo(projectId: $projectId, s3Key: $s3Key)
          }
      `,
      variables: {
        projectId,s3Key,
      },
    });
    return response.data.data.updateAccountancyLogo;
  

  
  } catch (error) {
    console.error("Erreur lors du chargement du logo :", error);
    throw error;
  }
};

//recuperere logo
export const VisualiserLogo = async (projectId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
          query getAccountancyLogo($projectId: String!){
            getAccountancyLogo(projectId: $projectId){
                s3Key
            } 
        }      
        `,
        variables: {
          projectId,
        },
    });
    return response.data.data.getAccountancyLogo.s3Key;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};

export const telechargerUrl = async (s3Key) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
          mutation generateAccountancyS3PreSignedDownloadUrl($s3Key: String!,) {
              generateAccountancyS3PreSignedDownloadUrl(s3Key: $s3Key) {
                      url
              }
          }      
        `,
        variables: {
          s3Key,
        },
    });
    console.log(response.data?.data?.generateAccountancyS3PreSignedDownloadUrl);

    return response.data?.data?.generateAccountancyS3PreSignedDownloadUrl;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};

export const createPrjectWithoutProjectId = async (projectName) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
                mutation initAccountancyProjectConfig($projectId: String, $projectName: String!) {
            initAccountancyProjectConfig(projectId: $projectId, projectName: $projectName)
        }     
        `,
        variables: {
          projectName,
        },
    });
    return response.data.data.initAccountancyProjectConfig;
  } catch (error) {
    console.error('Erreur lors de creation du projet:', error);
    throw error;
  }
};
export const initAccountancyProjectConfig = async (projectName,projectId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
         mutation initAccountancyProjectConfig($projectId: String, $projectName: String!) {
            initAccountancyProjectConfig(projectId: $projectId, projectName: $projectName)
        }     
        `,
        variables: {
          projectName,projectId,
        },
    });
    return response.data.data.initAccountancyProjectConfig;
  } catch (error) {
    console.error('Erreur lors de creation du projet:', error);
    throw error;
  }
};

// list factures
export const listAccountancyInvoices = async (projectId,limit,nextToken) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
           query listAccountancyInvoices($projectId: String!, $limit: Int, $nextToken: String){
              listAccountancyInvoices(projectId: $projectId, limit: $limit, nextToken: $nextToken){
                  items {
                      projectId
                      logoS3Key
                      title
                      identifier
                      numberId
                      creationDate
                      accountancyIssuer {
                          companyName
                          socialStatus
                          siret
                          contactName
                          address
                          phoneNumber
                          email
                          vatNumber
                          nafNumber
                          capitalStockAmount
                          capitalStockCurrency
                          iban
                          bicSwift
                      }
                      accountancyClient {
                          companyName
                          siret
                          contactName
                          address
                          phoneNumber
                          email
                      }
                      products {
                          reference
                          designation
                          quantity
                          unitPrice
                          vatInPercentage
                          amountExcludingTax
                          currency
                          image
                      }
                      condition
                      status
                      pdfS3Key
                  }
                  nextToken
              }
          }    
        `,
        variables: {
          projectId,limit,nextToken
        },
    });
    return response.data.data.listAccountancyInvoices;
  } catch (error) {
    console.error('Erreur lors de recccurepartion de liste facture :', error);
    throw error;
  }
};


//update facture
export const putAccountancyInvoice = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation putAccountancyInvoice($input: InvoiceInput) {
          putAccountancyInvoice(input: $input)
        }
      `,
      variables: { input },
    });

    console.log("Réponse du serveur:", response);

    if (response?.data?.errors?.length > 0) {
      throw new Error(response.data.errors[0].message);
    }

    const responseData = response?.data?.data?.putAccountancyInvoice;
    return responseData ? JSON.parse(responseData) : null;
  } catch (error) {
    console.error('Erreur lors de modification de facture :', error.message);
    throw error;
  }
};
// get une facture specifique 
export const getAccountancyInvoice = async (projectId,identifier) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        query getAccountancyInvoice($projectId: String!, $identifier: String!){
                getAccountancyInvoice(projectId: $projectId, identifier: $identifier){
                    projectId
                    logoS3Key
                    title
                    identifier
                    numberId
                    creationDate
                    accountancyIssuer {
                        companyName
                        socialStatus
                        siret
                        contactName
                        address
                        phoneNumber
                        email
                        vatNumber
                        nafNumber
                        capitalStockAmount
                        capitalStockCurrency
                        iban
                        bicSwift
                    }
                    accountancyClient {
                        companyName
                        siret
                        contactName
                        address
                        phoneNumber
                        email
                    }
                    products {
                        reference
                        designation
                        quantity
                        unitPrice
                        vatInPercentage
                        amountExcludingTax
                        currency
                        image
                    }
                    condition
                    status
                    pdfS3Key
                } 
              }
      `,
      variables: { projectId,identifier },
    });
  const responseData = response?.data?.data?.getAccountancyInvoice;
    return responseData;
  } catch (error) {
    console.error('Erreur lors de modification de facture :', error.message);
    throw error;
  }
};

