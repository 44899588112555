import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const CustomButton = ({ to, onClick, children, variant = "contained", sx = {}, ...props }) => {
    return (
        <Button
            variant={variant}
            component={to ? Link : "button"}
            to={to}
            onClick={onClick}
            sx={{
                textTransform: "none",
                backgroundColor: "rgb(25, 175, 120)",
                color: "white",
                "&:hover": {
                    backgroundColor: "rgb(20, 140, 90)",
                    color: "white",
                },
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
