import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";


const CandidateV3Area = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >Récapitulatif</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 text-center mt-4">
              <div className="card p-4 mb-4">
                <p>
                  Vous avez bien pris rendez-vous le {selectedDate?.toLocaleDateString("fr-FR")} à {selectedDate?.toLocaleTimeString("fr-FR")}.
                  Un mail récapitulatif vous sera envoyé.
                </p>
                <p>
                  Nous nous chargerons de vous appeler et de répondre à toutes vos questions.
                </p>
              </div>
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/statut-juridique">Retourner à la page d’accueil</Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
