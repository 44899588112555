import React, { useState } from "react";
import { Link } from "react-router-dom";


import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [textareaValue, setTextareaValue] = useState("");

    const handleCheckboxChange = (option) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
        );
    };

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>
>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >Récapitulatif</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="candidates-profile bg-color pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80">
                <div className="container">
                    <div className="text-center"></div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <div className="card">
                                <div className="card-header bg-success text-white text-center">
                                    <h5 className="mb-0" style={{ color: 'white' }}>Récapitulatif</h5>
                                </div>
                                <div className="card-body d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <span style={{ fontSize: '1.2rem' }}>Page avec tous les montants à payer</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 text-center mt-4">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com14" className="d-block">
                                    Payer
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
