import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ModifierConditionsModal = ({ show, handleClose, handleAdd, mantant, formData, setFormData }) => {
  const [selectedCondition, setSelectedCondition] = useState('');
  const [customText, setCustomText] = useState('');

  useEffect(() => {
    const conditionFromText = formData.condition || `• 100 % soit ${mantant} € à payer le : 30/11/2024.`;
    setCustomText(conditionFromText);

    const conditionsList = ['COMPTANT', 'A RECEPTION', 'A 30 JOURS FIN DE MOIS', 'A 45 JOURS FIN DE MOIS'];
    const selected = conditionsList.find(condition => conditionFromText.includes(condition));
    
    setSelectedCondition(selected || ''); 
  }, [formData.condition, mantant]); 

  const handleConditionChange = (event) => {
    const condition = event.target.value;
    setSelectedCondition(condition);
    setCustomText(`• 100 % soit ${mantant} € à payer le : 30/11/2024. (${condition}).`);
  };

  const handleTextChange = (event) => {
    setCustomText(event.target.value);
  };

  const handleAddClick = () => {
    setFormData(prevData => ({
      ...prevData,
      condition: customText, 
    }));
    handleAdd(customText);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Conditions générales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <h4 className="d-flex flex-column align-items-center mb-4">
            <Link to='/ma-facturation' className="text-reset">
              <u>Gérez vos échéances et paiements</u>
            </Link>
          </h4>

          <div className="row">
            <div className="col-md-5">
              <h5 className="mb-4"><u>Conditions</u></h5>
              {['COMPTANT', 'A RECEPTION', 'A 30 JOURS FIN DE MOIS', 'A 45 JOURS FIN DE MOIS'].map((condition, index) => (
                <div className="form-check mb-2" key={index}>
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="condition" 
                    id={`condition${index}`} 
                    value={condition}
                    checked={selectedCondition === condition}  
                    onChange={handleConditionChange}
                  />
                  <label className="form-check-label" htmlFor={`condition${index}`}>
                    {condition}
                  </label>
                </div>
              ))}
            </div>

            <div className="col-md-7">
              <h5><u>Texte apparaissant sur la facture</u></h5>
              <textarea
                className="col-md-12 invoice-text-box"
                rows="5"
                value={customText}  
                onChange={handleTextChange}  
              />
              <div className="button-group d-inline-flex align-items-center justify-content-center mt-4">
                <Link className="text-reset me-3" to="#" onClick={handleClose} style={{ textDecoration: 'none' }}>
                  Annuler
                </Link>
                <Button
                  variant="success"
                  color="success"
                  onClick={handleAddClick}
                  disabled={!selectedCondition}  
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModifierConditionsModal;
