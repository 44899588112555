import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import { composeWithDevTools } from 'redux-devtools-extension';

import packageReducer from './packageSlice';
import statutReducer from './statutJuridiqueSlice'; 
// import authReducer from './authSlice'; 
import projetReducer from './projetCommunication';
import invoiceReducer from './invoice';
import entrepriseReducer from './entrepriseSlice'; 


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [, 'statut_juridique','package','projet_communication','invoice','entrepriseReducer']
};

const rootReducer = combineReducers({
  package: packageReducer,
  statut_juridique: statutReducer,
  // auth: authReducer,
  projet_communication :projetReducer,
  invoice : invoiceReducer,
  entreprise: entrepriseReducer,
  
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools() 
);

const persistor = persistStore(store);

export { store, persistor };
