import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setParagraph, setProject } from "../../../redux/packageSlice";
import { TextareaAutosize, TextField, Typography} from "@mui/material";

const CandidateV3Area = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
    const [selectedParagraph, setSelectedParagraph] = useState(projectData.paragraph);
    useEffect(() => {
            setSelectedParagraph(projectData.paragraph);
        
    }, [projectData]);

    const handleTextareaChange = (event) => {
        const newValue = event.target.value;
        setSelectedParagraph(newValue);
         dispatch(
            setProject({
              projectId,
              data: {
                website: {
                    about: {
                        paragraph: newValue
                    }
                }
            }
            })
          );
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two ">
                <div className="container">
                     <div className="d-flex mb-3">
                                                                <Link to={`/package-com8/${projectId}`} 
                                                                        style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                                        className="d-flex justify-content-around">
                                                                    <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                                                </Link>
                                                                <Link to="#"
                                                                        style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                                        className="d-flex justify-content-center">
                                                    
                                                                    <Typography component="span" className="d-flex justify-content-center text-center">11/15</Typography>
                                                                </Link>
                                                                <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                                             </div>
                  
                    <div className="title-one text-center mb-2 lg-mb-2

 ">
                        <h3 className="fw-600">Ajoutez votre texte concernant votre activité</h3>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <div className="text-center">
                                 <TextareaAutosize minRows={4} className="form-control"
                                     value={selectedParagraph}
                                    placeholder="Entrez vos commentaires ici"
                                    
                                />
                                
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com9/${projectId}`} className="d-block">
                                    Validez
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
