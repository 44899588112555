import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FicheDocument = () => {
    const [showModal, setShowModal] = useState(false);
   
    const [selectedFile, setSelectedFile] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

   
    

    const handleIconClick1 = (iconClass) => {
        if (iconClass.includes("text-success")) {
            setShowPdf(true);
        }
    };

    const handleIconClick = (status) => {
    };

    const handleUpload = () => {
        if (selectedFile) {
        }
        setShowModal(false);
    };

    const handleFileChange = (event) => {
        setShowPdf(true);
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setPdfUrl(fileURL);
            setShowPdf(true);
        }
    };

    const handleDownloadClick = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = selectedFile.name;
        link.click();
    };

    return (
        <div className="row">
            <div style={{ cursor: "pointer" }}>
                <Accordion>
                    <AccordionSummary>
                        <h4>
                            <ExpandMoreIcon style={{ marginRight: "8px" }} />
                            Document à fournir
                        </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="table-responsive mt-20 mb-20" id="liste-table">
                            <table className="table job-alert-table">
                                <tbody>
                                    <tr>
                                        <td className="text-left p-2">nom pièce 1</td>
                                        <td className="text-center p-2">
                                            <i
                                                className="fas fa-file-alt text-success fs-4"
                                                onClick={() => handleIconClick1("text-success")}
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>
                                        <td className="text-center p-2">En attente/validé/rejeté</td>
                                        <td className="text-center p-2">message du cabinet comptable</td>
                                        <td className="text-center p-2">
                                            <button onClick={() => setShowModal(true)}>Charger</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-left p-2">nom pièce 2</td>
                                        <td className="text-center p-2">
                                            <i
                                                className="fas fa-file-alt text-danger fs-4"
                                                onClick={() => handleIconClick("text-danger")}
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>
                                        <td className="text-center p-2">En attente/validé/rejeté</td>
                                        <td className="text-center p-2">message du cabinet comptable</td>
                                        <td className="text-center p-2">
                                            <button onClick={() => setShowModal(true)}>Charger</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload un nouveau fichier</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input type="file" onChange={handleFileChange} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        Annuler
                                    </Button>
                                    <Button variant="primary" onClick={handleUpload}>
                                        Charger
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {showPdf && pdfUrl && (
                                <div className="pdf-viewer">
                                    <div className="viewer-header">
                                        <button onClick={() => setShowPdf(false)} className="close-button">
                                            x
                                        </button>
                                        <button onClick={handleDownloadClick} className="download-button">
                                            Télécharger
                                        </button>
                                    </div>
                                    <iframe
                                        src={pdfUrl}
                                        title="PDF Viewer"
                                        width="100%"
                                        height="500px"
                                    ></iframe>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
      `}</style>
        </div>
    );
};

export default FicheDocument;