
const initialState = {
    selectedEntreprise: null, 
  };
  
  const entrepriseSlice = (state = initialState, action) => {
   
     
    switch (action.type) {
        case 'SET_ENTREPRISE':
          return { ...state, selectedEntreprise: action.payload };
        default:
          return state;
      }
  };
  
  export const setEntreprise = (entreprise) => ({ type: 'SET_ENTREPRISE', payload: entreprise });
  
  export default entrepriseSlice;
  