import React from 'react';
import "../css/footer.css";
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <>
            <div className="footer-one">
                <div className="container">
                    <div className="inner-wrapper">
                    <div className="row justify-content-evenly">
                        {/* <div className="col-xl-2 col-lg-3 footer-intro mb-15">
                            <div className="logo mb-25">
                                <a href="index.html" class="d-flex align-items-center">
                                    <img src="/assets/images/logo/logo-pharos.png" alt="" />
                                </a>
                            </div>                       
                        </div> */}
                        <div className="col-lg-4 col-sm-4 mb-20">
                        <h5 className="footer-title text-light">Pharos</h5>                        
                        <p className='para_footer'>
                        Pharos est une solution 100% digitale qui répond à l'intégralité des besoins des entrepreneurs : création d’entreprise, création de site web, facturation, comptabilité, etc. Grâce à l’accompagnement d’experts juridiques dédiés, les professionnels disposent d’une offre complète, fiable et rassurante, au meilleur prix.
                        </p>                           
                        <ul className="style-none d-flex order-lg-last justify-content-start social-icon gap-3 mb-4"
>
                            <li>
                                <Link to="#">
                                    <i className="bi bi-facebook" style={{ fontSize: "24px" }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <i className="bi bi-twitter" style={{ fontSize: "24px" }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <i className="bi bi-instagram" style={{ fontSize: "24px" }}></i>
                                </Link>
                            </li>
                        </ul>             
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-20">
                            <h5 className="footer-title text-light">Solutions</h5>
                            <ul className="footer-nav-link style-none">
                                <li><Link className="text-light link" to="/statut-juridique">Création d'entreprise</Link></li>
                                <li><Link className="text-light link" to="/package-com">Création d'un paquet Web</Link></li>
                                <li><Link className="text-light link" to="#">Outils de gestion</Link></li>        
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-4 mb-20">
                            <h5 className="footer-title text-light">À propos</h5>    

                            <p className='para_footer'> </p> 
                                
                                <div>Pharos Tech</div>                                 
                                <div> Paris </div> 
                                <div>75001 Paris</div>
                              
                            <p><Link className='text-light link' to="/contact">Contact</Link></p>                          
                            <p className='para_footer'>Contactez-nous <br /> +33 6 50 00 00 00</p>  
                            
                            <p className='para_footer'></p>  
                             <div className='text-nowrap'> Du lundi au vendredi de 9h à 19h</div>
                             <div> Le samedi de 10h à 18h </div>
                                                                                          
                        </div>
                    </div>
                    </div>
                </div>                
            </div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 order-lg-3 mb-15">
                        <ul className="style-none d-flex order-lg-last justify-content-center justify-content-lg-end social-icon">                            
                        </ul>
                        </div>
                        <div className="col-lg-4 order-lg-1 mb-15">
                        <ul className="d-flex style-none bottom-nav justify-content-center justify-content-lg-start">
                            
                        </ul>
                        </div>
                        <div className="col-lg-4 order-lg-2">              
                        <p className="text-center text-white mb-15">@{new Date().getFullYear()} Tous droits réservés - Pharos SAS</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;