import React from 'react';
import Header from "../Header";
import Footer from '../Footer';
import ScrollToTopButton from '../../Components/Theme/Theme';
import RendezVous1 from '../../Components/frontend/rendez-vous/rendez-vous1';



function Rendez_vous1() {
    return (
        <>
            <Header></Header>
            <RendezVous1></RendezVous1>
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default Rendez_vous1;
