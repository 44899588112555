import React, { useState } from 'react';
import { useNavigate ,useLocation, Link } from 'react-router-dom';
import CustomButton from '../../../layouts/CustomButton';

const Create_soc2 = () => {
  const navigate = useNavigate();
  const [isDomiciliationSelected, setIsDomiciliationSelected] = useState(false);
  const location= useLocation();
  const selectedService = location.state?.selectedService || 'Minimal'; 
  console.log(selectedService);
  const handleAddDomiciliation = () => {
    setIsDomiciliationSelected(true);
    console.log(isDomiciliationSelected);

    navigate('/create_soc3', { state: { isDomiciliationSelected: true ,selectedService} });
  };
  
  const handleContinueWithoutDomiciliation = () => {
    setIsDomiciliationSelected(false);
    navigate('/create_soc3', { state: { isDomiciliationSelected: false ,selectedService } });
  };
  

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '169px', paddingBottom: '5px' }}
>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className=" text-center" >CRÉATION D'XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-center">
        <div className="text-center">
          <h2 className="fw-600" >
            Ajoutez d’autres services Pharos pour une commande plus complète
          </h2>
          </div>
           <div className="d-flex mb-1">
                                  <Link to="/create_soc1"
                                          style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                          className="d-flex justify-content-around">
                                      <span className="precedent-text">{"< Précédent"}</span>
                                  </Link>
                                  <Link to="#"
                                          style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                          className="d-flex justify-content-center">
                      
                                      <span className="d-flex justify-content-center text-center">2/4</span>
                                  </Link>
                                  <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                              </div>
         
        </div>
        <div className="title-one text-center mb-1 lg-mb-2" style={{ paddingTop: '3px', paddingBottom: '1px' }}>
          <h3 className="fw-600">Domiciliation avec Pharos Legal xx € HT</h3>
          <p className="fw-600 mt-4">
            Afin de ne pas utiliser votre propre adresse personnelle<br />
            pour la domiciliation de votre future société, Pharos Legal vous propose<br />
            des adresses partenaires pour plus de sécurité.
          </p>
        </div>
        <div className="row mb-2">
          <div className="col-12 text-center  mb-5">
             <CustomButton  onClick={handleAddDomiciliation}>
                 Ajouter DOMICILIATION et poursuivre la commande →
                    </CustomButton >
          
          </div>
          
        
          <div className="col-12 text-center mb-5 mt-3">
            <span
              onClick={handleContinueWithoutDomiciliation}
              style={{ cursor: 'pointer' }}
            >
              Poursuivre la commande →
            </span>
          </div>
         
        </div>
        
      </div>
    </>
  );
};

export default Create_soc2;
