"use client"
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { Box, TextField, Typography, MenuItem, Grid, Container, selectClasses, Button } from "@mui/material";

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchClients } from '../../../../../api/Services/clientService';
import { getAccountancyIssuerConfig, putAccountancyInvoice } from '../../../../../api/Services/invoiceService';
import AddClientFact from './AddClientFact';
import ConditionsModal from './ConditionGenal';
import Swal from 'sweetalert2';
import ModalDateFact from './ModalDateFact';

const Factures = ({ setIsOpenSidebar }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [showClient, setShowClient] = useState(false);
  const [montantTotal,setMontantTotal]= useState(0);
  const [showConditionGeneral, setShowConditionGeneral] = useState(false);
  const [customText, setCustomText] = useState("");

  const handleShowClient = () => setShowClient(true);
  const HandleCloseClient = () => setShowClient(false);
  const handleShowConditionGeneral = () => setShowConditionGeneral(true);
  const handleCloseConditionGeneral = () => setShowConditionGeneral(false);
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [totals, setTotals] = useState({ TVA:0 ,totalTVA: 0, totalTTC: 0 });
  

  const [configData, setConfigData] = useState({
    companyName: '',
    socialStatus: '',
    siret: '',
    contactName: '',
    address: '',
    phoneNumber: '',
    email: '',
    vatNumber: '',
    nafNumber: '',
    capitalStockAmount: '',
    capitalStockCurrency: '',
    iban: '',
    bicSwift: '',
    generalCondition: '',
    bankName: '',
    bankAddress: '',
    bankAccountName: ''
  });
  const [formDataClient, setFormDataClient] = useState({
    companyName: '',
    siret: '',
    name: '',
    firstName: '',
    telephoneMobile: '',
    email: '',
    address: '',
  });
  const [formData, setFormData] = useState({

    logoS3Key: null,
    title: '',
    // identifier: 'id00000000',
    numberId: '',
    condition: '',
    status: '',
    date: new Date(),
  })

  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);

  const projet = useSelector((state) => state.invoice.projets[projectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAccountancyIssuerConfig(projectId);
        setConfigData(data || {});
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };
    fetchData();
  }, [projectId]);


  const handleClick = () => {
    handleShowClient();
  };

  const [dataproduit, seDataProduit] = useState([

    { reference: '', designation: '', quantity: '', price: '', tva: '', amount: '', image: '' },
  ]);


 
  

  const handleAddRow = () => {
    seDataProduit([
      ...dataproduit,
      { reference: dataproduit.length + 1, designation: '', quantity: '', price: '', tva: '', amount: '', image: '' },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...dataproduit];
    updatedData[index][field] = value;

    if (field === 'quantity' || field === 'price') {
      const quantity = parseFloat(updatedData[index].quantity) || 0;
      const price = parseFloat(updatedData[index].price) || 0;
      updatedData[index].amount = quantity * price;
    }

    seDataProduit(updatedData);
  };


  useEffect(() => {
    let totalHT = 0;
    let totalTVA = 0;
    let TVA =0;
  
    dataproduit.forEach((item) => {
      const montantHT = parseFloat(item.amount) || 0;
      const tvaRate = parseFloat(item.tva) || 0;
      const tvaAmount = (montantHT * tvaRate) / 100;
  
      totalHT += montantHT;
      totalTVA += tvaAmount;
      TVA +=tvaRate;
    });
  
    setTotals({
      totalTVA: totalTVA.toFixed(2),
      totalTTC: (totalHT + totalTVA).toFixed(2),
      TVA : TVA

    });
    setMontantTotal(totalHT);
    console.log('montantTotal',montantTotal);
  }, [dataproduit]);
  
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleDelete = (reference) => {
    seDataProduit(dataproduit.filter((item) => item.reference !== reference));
  };
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await fetchClients(projectId, 100, 0);
        const clients = response.data.data.listAccountancyClients;
      } catch (error) {
        // console.error('Erreur lors de la récupération des clients:', error);
      }
    };

    fetchClient();
  }, [projectId]);

  const handleSetStatusAndSubmit = (status) => {
    setFormData((prevFormData) => ({ ...prevFormData, status }));
    handleSubmit(status);
  };


  const handleSubmit = async (status) => {
    try {


      if (!projectId || !formData || !formDataClient || !configData) {
        console.error("Données manquantes, soumission annulée");
        return;
      }
      const products = dataproduit.map(item => ({
        reference: item.reference,
        designation: item.designation,
        quantity: item.quantity,
        unitPrice: item.price,
        vatInPercentage: item.tva,
        amountExcludingTax: item.amount,
        currency: "€",
        image: item.image ? item.image.name : ""
      }));
      const inputData = {

        projectId: projectId,
        logoS3Key: null,
        title: formData.title,
        identifier: formData.numberId,
        numberId: formData.numberId,
        date: formData.date.toISOString().split("T")[0],
        condition: customText,
        status: status,
        accountancyIssuer: {
          projectId,
          companyName: configData.companyName,
          socialStatus: configData.socialStatus,
          siret: configData.siret,
          contactName: configData.contactName,
          address: configData.address,
          phoneNumber: configData.phoneNumber,
          email: configData.email,
          vatNumber: configData.vatNumber,
          nafNumber: configData.nafNumber,
          capitalStockAmount: configData.capitalStockAmount,
          capitalStockCurrency: configData.capitalStockCurrency,
          iban: configData.iban,
          bicSwift: configData.bicSwift,
          generalCondition: configData.generalCondition,
          bankName: configData.bankName,
          bankAddress: configData.bankAddress,
          bankAccountName: configData.bankAccountName
        },
        accountancyClient: {
          companyName: formDataClient.companyName,
          siret: formDataClient.siret,
          contactName: `${formDataClient.name} ${formDataClient.firstName}`,
          phoneNumber: formDataClient.telephoneMobile,
          email: formDataClient.email,
          address: formDataClient.address,
        },
        products,



      };
      const response = await putAccountancyInvoice(inputData);

      if (response && response.message === "Successfully upserted item") {
        Swal.fire({
          icon: "success",
          title: "Envoyé",
          text: "Facture sauvegardée avec succès.",
        });
        navigate(`/ma-facturation-factures/${projet.projectId}`);
      }
    } catch (error) {
      const errorMessage = error.message || "Une erreur est survenue.";

      if (errorMessage === "Document can not be modified because already validated") {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Le document ne peut pas être modifié car il est déjà validé.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: errorMessage,
        });
      }
    }

  };


  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className="main-title text-center h2-titel">Ma facturation &gt;  {projet.projectName} &gt; Créer une facture</h2>
        <div className="bg-white card-box border-20 mb-20">
        <div className="row">
            <div className="col-md-7">
              <div className="d-flex flex-column align-items-start">
                <div className="user-avatar-setting d-flex flex-column align-items-start mb-3 ">
                  <img src="/assets/images/logo/logo-pharos.png" alt="logo" width="200" />
                </div>
              </div>

              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} md={7}> 
                  <Box p={3} border={2} borderColor="lightgray" borderRadius="5px" width="97%">
                    {[ 
                      { label: "Nom d'entreprise", value: configData.companyName },
                      { label: "Adresse", value: configData.address },
                      { label: "Statut légal", value: configData.socialStatus },
                      { label: "Nom du contact", value: configData.contactName },
                      { label: "Email", value: configData.email },
                      { label: "Numéro de téléphone", value: configData.phoneNumber },
                      { label: "Capital social", value: configData.capitalStockAmount },
                      { label: "Siret", value: configData.siret },
                      { label: "NAF", value: configData.nafNumber },
                      { label: "N° TVA Intracommunautaire", value: configData.vatNumber },
                      { label: "Devise du capital social", value: configData.capitalStockCurrency },
                      { label: "IBAN", value: configData.iban },
                      { label: "BIC/SWIFT", value: configData.bicSwift },
                      { label: "Condition générale", value: configData.generalCondition },
                      { label: "Nom de Banque", value: configData.bankName },
                      { label: "Adresse de la Banque", value: configData.bankAddress },
                      { label: "Nom du Compte", value: configData.bankAccountName },
                    ].map((item, index) => (
                      <Box key={index} display="flex" mb={1}>
                        <Typography variant="body1" fontWeight="bold">
                          {item.label} :
                        </Typography>
                        <Typography variant="body1" ml={1}>
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </div>

            <div className="col-md-5">
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <TextField
                    label="Titre"
                    fullWidth
                    name="title"
                    value={formData.title}
                    onChange={handleChangeFormData}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    p={3}
                    border={2}
                    borderColor="lightgray"
                    borderRadius="5px"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        bgcolor: "lightgray",
                      },
                    }}
                    onClick={handleClick}
                  >
                    <Typography variant="body1" fontWeight="bold" color="textSecondary">
                      Info société client
                    </Typography>
                  </Box>
                </Grid>

                {showClient && (
                  <AddClientFact
                    show={handleShowClient}
                    onClose={HandleCloseClient}
                    handleAdd={setFormDataClient}
                  />
                )}
              </Grid>
            </div>
          </div>

          <div className="row col-7 mt-3">
            <div className="col-md-7">
              <Grid item xs={12} md={7} mb={2}> 
                <TextField
                  label="Numéro de facture"
                  fullWidth
                  name="numberId"
                  value={formData.numberId}
                  onChange={handleChangeFormData}
                />
              </Grid>

              <TextField
                label="Date"
                fullWidth
                value={`Le ${formattedDate}`}
                onClick={() => setOpen(true)}
                sx={{ mb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {open && (
                <ModalDateFact open={open} handleClose={() => setOpen(false)} date={date} setDate={setDate} />
              )}
            </div>
          </div>


          <div className="col-md-12 mt-10 mb-30">
            <div className="position-relative" id="liste-table">
              <table className="table job-alert-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th scope="col" className="text-center">Réference</th>
                    <th scope="col" className="text-center">Désignation</th>
                    <th scope="col" className="text-center">Quantité</th>
                    <th scope="col" className="text-center">PU Vente</th>
                    <th scope="col" className="text-center">TVA</th>
                    <th scope="col" className="text-center">Montant HT</th>
                    <th scope="col" className="text-center">Image</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {dataproduit.map((item, index) => (
                    <tr key={item.reference} style={{ position: 'relative' }}>
                      <td className="text-center">
                        <input
                          type="text"
                          value={item.reference}
                          onChange={(e) => handleChange(index, 'reference', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="text"
                          value={item.designation}
                          onChange={(e) => handleChange(index, 'designation', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          value={item.quantity}
                          onChange={(e) => handleChange(index, 'quantity', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          value={item.price}
                          onChange={(e) => handleChange(index, 'price', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          value={item.tva}
                          onChange={(e) => handleChange(index, 'tva', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          value={item.amount}
                          onChange={(e) => handleChange(index, 'amount', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="text"
                          value={item.image}
                          onChange={(e) => handleChange(index, 'image', e.target.value)}
                          className="form-control"
                        />
                      </td>
                      <td className="text-center">
                          <i
                            className="bi bi-x-circle-fill text-danger"
                            style={{ cursor: 'pointer', fontSize: '15px' }}
                            onClick={() => handleDelete(item.reference)}
                          ></i>
                        </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ marginTop: '10px', textAlign: 'left' }}>
                <Button
                  to="#"
                  variant="outlined"
                  onClick={handleAddRow}
                  style={{
                    color: 'rgb(25, 175, 120)',
                    borderColor: 'rgb(25, 175, 120)',
                    display: 'inline-block',
                    marginLeft: 'auto',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      borderColor: 'rgb(25, 175, 120)',
                      color: 'rgb(25, 175, 120)',
                    },
                  }}
                >
                  <i
                    className="bi bi-plus-circle-fill text-success"
                    style={{ cursor: 'pointer', fontSize: '15px', marginRight: '5px' }}
                  ></i>
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
          
         <Grid item xs={12} md={3} sx={{ marginTop: '237px' }}>
              <Link
                to="#"
                onClick={handleShowConditionGeneral}
                className="text-reset"
                style={{ textDecoration: 'none' }}
              >
                <Box
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 border={2}
                 borderColor="lightgray"
                 borderRadius="5px"
                 width="300px"
                 height="105px"
                 p={1} 
                 sx={{
                   transition: 'background-color 0.3s',
                   '&:hover': {
                     bgcolor: 'lightgray',
                   },
                 }}
                >
                  <Typography variant="body1" fontWeight="bold" color="textSecondary">
                    Conditions générales: {customText || 'Aucune sélection'}
                  </Typography>
                </Box>
              </Link>
              {showConditionGeneral && (
                <ConditionsModal
                  show={showConditionGeneral}
                  handleClose={handleCloseConditionGeneral}
                  handleAdd={setCustomText}
                  mantant={montantTotal}
                />
              )}
            </Grid>

            <Grid container spacing={2} alignItems="center" justifyContent="space-between" mt={3}>
  <Grid item xs={12} md={3}>
    <Box
      display="flex"
      border={2}
      borderColor="lightgray"
      borderRadius="5px"
      width="300px"
      height="105px"
      p={1}
    >
      <Typography variant="body1" fontWeight="bold" color="textSecondary">
        Coordonnées bancaires :<br />
        IBAN : {configData.iban} <br />
        BIC/SWIFT : {configData.bicSwift}
      </Typography>
    </Box>
  </Grid>

  <Grid item xs={12} md={3}>
    <Box
      p={1}
      border={2}
      borderRadius="5px"
      width="250px"
      height="105px"
      borderColor="lightgray"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {[
        { label: "TVA", value: totals.TVA + " €" },
        { label: "Total TVA", value: totals.totalTVA + " €" },
        { label: "Total TTC", value: totals.totalTTC + " €" },
      ].map((item, index) => (
        <Box key={index} display="flex" justifyContent="space-between">
          <Typography variant="body1" fontWeight="bold">{item.label} :</Typography>
          <Typography variant="body1">{item.value}</Typography>
        </Box>
      ))}
    </Box>
  </Grid>
</Grid>

          

        </div>

        <div className="button-group d-flex justify-content-center mt-30">
            <Link
              className="dash-btn-two tran3s me-3"
              onClick={() => handleSetStatusAndSubmit("DRAFTED")}
            >
              Enregistrer le brouillon
            </Link>

            <Link
              className="dash-btn-two tran3s me-3"
              onClick={() => handleSetStatusAndSubmit("VALIDATED")}
            >
              Valider et générer la facture
            </Link>
          </div>

      </div>


    </div>

  );
};

export default Factures;