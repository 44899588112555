import axiosInstance from '../axiosConfig';


export const getWebpackageOrders = async () => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        query getWebpackageOrders {
          getWebpackageOrders {
            items {
              projectId
              projectName
              projectStatus
              mailBoxEmailAddresses
              domainName
              logo {
                logoType
                logoToCreate {
                  icon
                  iconS3Key
                  s3ImageKey
                  fontTitle
                  fontSlogan
                  colorForeground
                  colorBackground
                  title
                  slogan
                  logoS3Key
                }
                logoAlreadyCreated {
                  logoS3Key
                }
              }
              website {
                globalDetails {
                  companyName
                  language
                  backgroundColor
                  textColor
                  fontFamily
                  address
                  phoneNumber
                  email
                  socialNetworks {
                    facebook
                    twitter
                    instagram
                    youtube
                    snapchat
                    linkedin
                  }
                  days {
                    first
                    second
                    third
                    fourth
                    fifth
                    sixth
                    seventh
                  }
                }
                navbar {
                  backgroundColor
                  textColor
                  items
                }
                header {
                  title
                  subtitle
                  s3Key
                }
                services {
                  text
                  items
                }
                gallery {
                  s3Key
                  title
                }
                about {
                  paragraph
                  why
                  why2
                  s3Key
                }
                footer {
                  backgroundColor
                  textColor
                }
              }
            }
            nextToken
          }
        }
      `,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
 
export const getWebpackageOrder =async (projectId)=>{

    try {
        const response = await axiosInstance.post('/graphql', {
          query: `
           query getWebpackageOrder($projectId: String!) {
            getWebpackageOrder(projectId: $projectId) {
                projectId
                projectName
                projectStatus
                mailBoxEmailAddresses
                domainName
                logo {
                    logoType
                    logoToCreate {
                        icon
                        iconS3Key
                        s3ImageKey
                        fontTitle
                        fontSlogan
                        colorForeground
                        colorBackground
                        title
                        slogan
                        logoS3Key
                    }
                    logoAlreadyCreated {
                        logoS3Key
                    }
                }
                website {
                    globalDetails {
                        companyName
                        language
                        backgroundColor
                        textColor
                        fontFamily
                        address
                        phoneNumber
                        email
                        socialNetworks {
                            facebook
                            twitter
                            instagram
                            youtube
                            snapchat
                            linkedin
                        }
                        days{
                            first
                            second
                            third
                            fourth
                            fifth
                            sixth
                            seventh
                        }
                            }
                            navbar {
                                backgroundColor
                                textColor
                                items
                            }
                            header{
                                title
                                subtitle
                                s3Key                       
                            }
                            services{
                                text
                                items
                            }
                            gallery {
                                s3Key
                                title
                            }
                            about{
                                paragraph
                                why
                                why2
                                s3Key
                            }
                            footer{
                                backgroundColor
                                textColor
                            }
                        }
                    }
                }
          `,
          variables: {projectId},
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }


};

export const upsertWebpackageOrder = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation upsertWebpackageOrder($input: OrderWebpackageInput!) {
          upsertWebpackageOrder(input: $input)
        }
      `,
      variables: { input },
    });

    console.log("Mutation resultat:", response.data.data?.upsertWebpackageOrder); 

    if (response.data.errors) {
      console.error("API Errors:", response.data.errors);
      throw new Error(response.data.errors[0].message || "Mutation failed");
    }

    if (!response.data || !response.data.data || !response.data.data.upsertWebpackageOrder) {
      throw new Error("Invalid response structure from the API");
    }

    return response.data.data.upsertWebpackageOrder;
  } catch (error) {
    console.error('Error performing mutation:', error);
    throw error;
  }
};

export const generateS3PreSignedUploadUrl = async (projectId, fileName) => {
  try {
    const response = await axiosInstance.post("/graphql", {
      query: `
        mutation generateWebpackageOrderS3PreSignedUploadUrl($projectId: String!, $fileName: String!) {
          generateWebpackageOrderS3PreSignedUploadUrl(projectId: $projectId, fileName: $fileName) {
            url
            fields {
              key
              xAmzAlgorithm
              xAmzCredential
              xAmzDate
              xAmzSecurityToken
              policy
              xAmzSignature
            }
          }
        }
      `,
      variables: { projectId, fileName },
    });
console.log("response",response);
    if (!response.data?.data?.generateWebpackageOrderS3PreSignedUploadUrl) {
      throw new Error("Aucune URL pré-signée générée.");
    }

    return response.data.data.generateWebpackageOrderS3PreSignedUploadUrl;
  } catch (error) {
    console.error("Error fetching pre-signed URL:", error);
    throw error;
  }
};




// export const uploadToS3 = async (file, preSignedData) => {
//   const formData = new FormData();

//   formData.append('key', preSignedData.fields.key);
//   formData.append('file', file);  
//   formData.append('xAmzAlgorithm', preSignedData.fields.xAmzAlgorithm);
//   formData.append('xAmzCredential', preSignedData.fields.xAmzCredential);
//   formData.append('xAmzDate', preSignedData.fields.xAmzDate);
//   formData.append('xAmzSecurityToken', preSignedData.fields.xAmzSecurityToken);
//   formData.append('policy', preSignedData.fields.policy);
//   formData.append('xAmzSignature', preSignedData.fields.xAmzSignature);

//   formData.forEach((value, key) => {
//     console.log(key, value);
//   });

//   try {
//     const response = await axiosInstance.post(preSignedData.url, formData);
//     console.log('File uploaded successfully:', response.data);
//   } catch (error) {
//     console.error('Error uploading file:', error.response || error.message);
//     if (error.response) {
//       console.error('Response data:', error.response.data);
//     }
//     throw error;
//   }
  
// };




export const generateText = async (keyWords) => {
    const query = `
        mutation generateText($keyWords: [String!]!) {
            generateText(keyWords: $keyWords) {
                text
            }
        }
    `;

    try {
        const response = await axiosInstance.post("/graphql", {
            query,
            variables: { keyWords },
        });
        return response.data.data.generateText.text;
    } catch (error) {
        throw new Error(
            error.response?.data?.errors?.[0]?.message || "Une erreur s'est produite."
        );
    }
};
export const fetchImages = async () => {
  const query = {
    query: `
      query getListOfImages {
        getListOfImages {
          items
          nextToken
        }
      }
    `,
  };

  try {
    const response = await axiosInstance.post("/graphql", query);

    return response.data.data.getListOfImages.items || [];
  } catch (error) {
    throw new Error(error.response?.data?.errors?.[0]?.message || "Erreur de requête");
  }
};

 export const fetchDownloafile=async (s3Key)=>{
  try {
    const response = await axiosInstance.post('/graphql', {
        query: `
            mutation generateWebpackageOrderS3PreSignedDownloadUrl($s3Key: String!) {
            generateWebpackageOrderS3PreSignedDownloadUrl(s3Key: $s3Key) {
                url
            }
        }
        `,
        variables: { s3Key },
    });
    return response.data;
} catch (error) {
    console.error('Error performing mutation:', error);
    throw error;
}
};
