import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import RechecherSociete from '../factures/rechercheSociete';
import { useSelector } from 'react-redux';
import { fetchClients } from '../../../../../api/Services/clientService';

const UpdateClientFact = ({ show, onClose, handleAdd, dataClient }) => {
  const [ShowSearchModal, setShowModal] = useState(false);
  const handleCloseSearcheModal = () => setShowModal(false);
  const handleShowSearchModal = () => setShowModal(true);
  const selectedEntreprise = useSelector((state) => state.entreprise.selectedEntreprise);


  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const projectId = '';

  const [formDataClient, setFormDataClient] = useState(dataClient);
  const contactName= formDataClient.contactName
console.log("contactName",contactName);
  useEffect(() => {
    setFormDataClient(dataClient);
  }, [dataClient]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDataClient({
      ...formDataClient,
      [name]: value,
    });
  };

  const handleAddClick = () => {
    handleAdd(formDataClient);
    onClose(); 
  };

  useEffect(() => {
    if (selectedEntreprise) {
      setFormDataClient({
        companyName: selectedEntreprise.companyName || '',
        siret: selectedEntreprise.siret || '',
        name: selectedEntreprise.name || '',
        firstName: selectedEntreprise.firstName || '',
        telephoneMobile: selectedEntreprise.telephoneMobile || '',
        email: selectedEntreprise.email || '',
        address: selectedEntreprise.address || '',
      });
    }
  }, [selectedEntreprise]);

  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const result = await fetchClients(projectId, 100, null);
        setClients(result || []);
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des clients.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchClientList();
  }, []);

 

  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal show={show} onHide={onClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Les coordonnées de votre fournisseur/Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {ShowSearchModal ? (
      <RechecherSociete
        ShowSearchModal={ShowSearchModal}
        handleCloseModalSearch={handleCloseSearcheModal}
      />
    ) : (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <u>L'entreprise</u>
                </Typography>
                <TextField
                    select
                    label="Raison Sociale*"
                    fullWidth
                    variant="outlined"
                    value={formDataClient.companyName || ""} 
                    onChange={(e) => {
                        const selectedCompany = e.target.value;
                        setFormDataClient({
                        ...formDataClient,
                        companyName: selectedCompany, 
                        });
                    }}
                    InputLabelProps={{ shrink: true }}
                    >
                    {clients?.items?.length > 0 ? (
                        clients.items.map((client) => (
                        <MenuItem key={client.id} value={client.companyName}>
                            {client.companyName}
                        </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Aucune entreprise trouvée</MenuItem>
                    )}
                    <MenuItem
                        value=""
                        onClick={handleShowSearchModal}
                        sx={{ borderTop: "1px solid #ccc", color: "blue" }}
                    >
                        🔎 Recherche Avancée
                    </MenuItem>
                    </TextField>



                <TextField
                  fullWidth
                  margin="normal"
                  label="Siret*"
                  name="siret"
                  value={formDataClient.siret}
                  onChange={handleInputChange}
                />
              </Grid>

             

              <Grid item xs={6} md={7} container justifyContent="center">
                <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                  <u>Contact</u>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Civilité*"
                      fullWidth
                      variant="outlined"
                      value={formDataClient.civilite || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="client1"></MenuItem>
                      <MenuItem value="client2"></MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Fonction*"
                      fullWidth
                      variant="outlined"
                      value={formDataClient.civilite || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="client1"></MenuItem>
                      <MenuItem value="client2"></MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Prénom*"
                      name="name"
                      value={formDataClient.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Nom*"
                      name="firstName"
                      value={formDataClient.firstName}
                      onChange={handleInputChange}
                       variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Téléphone mobile*"
                      name="phoneNumber"
                      value={formDataClient.phoneNumber}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true, 
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Téléphone Fixe*"
                      name="telephoneFixe"
                      value={formDataClient.telephoneFixe || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true, 
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email*"
                      name="email"
                      value={formDataClient.email}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true, 
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={5}>
                <Typography variant="h6">
                  <u>L'adresse</u>
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Adresse*"
                  name="address"
                  value={formDataClient.address}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true, 
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Code Postal*"
                  name="postalCode"
                  value={formDataClient.postalCode || ''}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true, 
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Ville*"
                  name="ville"
                  value={formDataClient.ville || ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                component={Link}
                to="#"
                onClick={handleClose}
                variant="outlined"
                sx={{
                  mx: 2,
                  color: 'rgb(25, 175, 120)',
                  borderColor: 'rgb(25, 175, 120)',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: 'rgb(25, 175, 120)',
                    color: 'rgb(25, 175, 120)',
                  },
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={handleAddClick}
                variant="contained"
                sx={{
                  backgroundColor: 'rgb(25, 175, 120)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgb(25, 175, 120)',
                    color: 'white',
                  },
                }}
              >
                Ajouter
              </Button>
            </Box>
          </Box>
    )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateClientFact;