import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { getDMSDocuments, getDMSTrashDocuments, moveDocument } from "../../../../../api/Services/gedService";
import { FaFolder } from "react-icons/fa";
import Select from "react-select";

const DeplacerDocument = ({ showDeplacer, handleCloseDeplacer, projectId, documentId,onUpdate }) => {
  const [newParentDocumentId, setNewParentDocumentId] = useState(null);
  const [newParentDocumentLabel, setNewParentDocumentLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingFolders, setLoadingFolders] = useState(true); 
  const [error, setError] = useState(null);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    setLoadingFolders(true); 
    try {
      const fetchAllFolders = async (parentId) => {
        const response = await getDMSDocuments(projectId, parentId, 100, null);
        const items = response?.items || [];
        const folders = await Promise.all(
          items
            .filter((item) => item.documentType === "FOLDER")
            .map(async (item) => ({
              name: item.documentName,
              type: item.documentType,
              id: item.documentId,
              subfolders: await fetchAllFolders(item.documentId),
            }))
        );
        return folders;
      };
      const structuredFolders = await fetchAllFolders("root");
      setFolders(structuredFolders);
      console.log("Dossiers hiérarchiques :", structuredFolders);
    } catch (error) {
      console.error("Erreur lors de la récupération des documents :", error);
      setError("Impossible de charger les dossiers. Veuillez réessayer.");
    } finally {
      setLoadingFolders(false); 
    }
  };

  const renderFolderOptions = (folders, level = 0) => {
    const options = [];
  
    if (level === 0) {
      options.push({
        value: "root",
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaFolder style={{ marginRight: "5px" }} />
            Racine 
          </div>
        ),
      });
    }
  
    folders.forEach((folder) => {
      if (folder.type === "FOLDER") {
        options.push({
          value: folder.id,
          label: (
            <div style={{ display: "flex", alignItems: "center", marginLeft: level * 10 }}>
              <FaFolder style={{ marginRight: "5px" }} />
              {folder.name}
            </div>
          ),
        });
      }
      if (folder.subfolders && folder.subfolders.length > 0) {
        options.push(...renderFolderOptions(folder.subfolders, level + 1));
      }
    });
  
    return options;
  };

  const handleMove = async () => {
    if (!newParentDocumentId) {
      setError("Veuillez sélectionner un dossier de destination.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      console.log(projectId, documentId, newParentDocumentId);

      await moveDocument(projectId, documentId, newParentDocumentId);
      console.log("Document déplacé avec succès.");
      
      await fetchFolders();
      if (onUpdate) onUpdate();
      handleCloseDeplacer();
    } catch (err) {
      console.error("Erreur lors du déplacement :", err);
      setError("Une erreur est survenue lors du déplacement.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (selectedOption) => {
    setNewParentDocumentId(selectedOption ? selectedOption.value : "");
    setNewParentDocumentLabel(selectedOption ? selectedOption.label : "");
  };

  return (
    <Modal show={showDeplacer} onHide={handleCloseDeplacer} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Déplacer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form.Group controlId="folderSelect">
          <Form.Label>Sélectionnez un dossier de destination</Form.Label>
          {loadingFolders ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Spinner animation="border" role="status" size="sm" />
              <span style={{ marginLeft: "10px" }}>Chargement des dossiers...</span>
            </div>
          ) : (
            <Select
              options={renderFolderOptions(folders)}
              value={renderFolderOptions(folders).find((opt) => opt.value === newParentDocumentId)}
              onChange={(selectedOption) => handleChange(selectedOption)}
              isDisabled={loadingFolders || loading} 
              placeholder="Choisissez un dossier"
            />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDeplacer} disabled={loading}>
          Annuler
        </Button>
        <Button
          variant="success"
          onClick={handleMove}
          disabled={loading || !newParentDocumentId || loadingFolders}
        >
          {loading ? "Déplacement..." : "Déplacer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeplacerDocument;
