import axios from 'axios';
import axiosInstance from '../axiosConfig';


export const getListemailAdress = async () => {


    try {
        const response = await axiosInstance.post('/graphql', {
            query: `
                query getEmailAddresses($limit: Int, $nextToken: String) {
                    getEmailAddresses(limit: $limit, nextToken: $nextToken) {
                        items {
                            emailAddress
                            projectId
                        }
                        nextToken
                    }
                }
            `,
            variables: {
                limit: 10, 
                nextToken: null, 
            },
        });
        return response.data;
    } catch (error) {
        console.error("Erreur lors de l'appel GraphQL :", error);
        throw error; 
    }
};
export const getNumberOfMails = async(sourceEmailAddress) =>{
try{
    const response= await axiosInstance.post('/graphql',{
    query:
    `
    query getNumberOfMails($sourceEmailAddress: AWSEmail!) {
        getNumberOfMails(sourceEmailAddress: $sourceEmailAddress) {
                totalEmailsCount
                unreadEmailsCount
        }
    }`,
    variables: {sourceEmailAddress}
})
return response.data.data?.getNumberOfMails;
}catch(error){
    console.error("errror lors de recupration de nombre des emails ",error);
}
};

export const getReceivedMails = async(sourceEmailAddress) =>{
    try{
        const response= await axiosInstance.post('/graphql',{
        query:
        `
            query getReceivedMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                getReceivedMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                        items {
                            itemId
                            subject
                            sender
                            body
                            time
                            attachments {
                                name
                                attachmentId
                            }
                        }
                }
            }`,
        variables: {
            sourceEmailAddress,
            nextToken :0
        }
    })
  
    return response.data?.data?.getReceivedMails;
    
    }catch(error){
        console.error("errror lors de recupration de nombre des emails ",error);
    }
    };
    

    export const saveDraftMails = async(sourceEmailAddress,input) =>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
              mutation saveDraftMail($sourceEmailAddress: AWSEmail!, $input: SaveDraftMailInput!) {
                    saveDraftMail(sourceEmailAddress: $sourceEmailAddress, input: $input)
                }`,
            variables: {
                sourceEmailAddress, input
            }
        }) 
      
        return response.data;
        
        }catch(error)
        {
            console.error("errror lors de save draft des emails ",error);
        }
        };

export const getDraftMails = async(sourceEmailAddress) =>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
                query getDraftMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                        getDraftMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                                items {
                                    itemId
                                    subject
                                    sender
                                    body
                                    time
                                    attachments {
                                        name
                                        attachmentId
                                    }
                                }
                        }
                    }`,
                variables: 
                {
                sourceEmailAddress,
                nextToken:0
            }
        })
      
        return response.data.data?.getDraftMails;
        
        }catch(error){
            console.error("errror lors de recupration de getDraftMails ",error);
        }
        };

export const getSentMails = async(sourceEmailAddress) =>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               query getSentMails($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                    getSentMails(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                            items {
                                itemId
                                subject
                                sender
                                body
                                time
                                attachments {
                                    name
                                    attachmentId
                                }
                            }
                    }
                }`,
                variables: 
                {
                sourceEmailAddress,
                nextToken:0
            }
        })
      
        return response.data.data?.getSentMails;
        
        }catch(error){
            console.error("errror lors de recupration de sentEmail ",error);
        }
        };
     export const getAllContact =async(sourceEmailAddress)=>{
            try{
                const response= await axiosInstance.post('/graphql',{
                query:
                `
                   query getContacts($sourceEmailAddress: AWSEmail!, $nextToken: String) {
                        getContacts(sourceEmailAddress: $sourceEmailAddress, nextToken: $nextToken) {
                                items {
                                    itemId
                                    name
                                    emails
                                    surname
                                    phoneNumbers
                                }
                        }
                    }`,
                    variables: 
                    {
                    sourceEmailAddress,
                    nextToken:0
                }
            })
          
            return response.data.data?.getContacts;
            
            }catch(error){
                console.error("errror lors de recupration de contacts ",error);
            }       
                
     };
     export const geAttachements =async(sourceEmailAddress,itemIdn,attachmentId)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               mutation getAttachmentDownloadPresignedUrl($sourceEmailAddress: AWSEmail!, $itemId: String!, $attachmentId: String!) {
                getAttachmentDownloadPresignedUrl(sourceEmailAddress: $sourceEmailAddress, itemId: $itemId, attachmentId: $attachmentId) {
                        url
                }
            }`,
                variables: 
                {
                sourceEmailAddress,
                itemIdn,
                attachmentId
              
            }
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de recupration de contacts ",error);
        }       
            
    };

     export const deleteEmail =async(sourceEmailAddress,itemIds)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
               mutation deleteMails($sourceEmailAddress: AWSEmail!, $itemIds: [String!]!) {
                    deleteMails(sourceEmailAddress: $sourceEmailAddress, itemIds: $itemIds)
                }`,
                variables: 
                {
                sourceEmailAddress,
                itemIds
                
              
            }
        })
      
        return response.data;
        
        }catch(error){
            console.error("errror lors de supression de email ",error);
        }       
            
    };
           
 export const mutationSentEmail=async(input)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
              mutation sendMail($sourceEmailAddress: AWSEmail!, $input: SendMailInput!) {
                    sendMail(sourceEmailAddress: $sourceEmailAddress, input: $input)
                }`,
                variables:{
                        sourceEmailAddress: input.sourceEmailAddress, 
                        input: input.input
                        }
                
        })
        console.log(response.data);
        return response.data;
       
        }catch(error){
            console.error("errror lors de envoi de email ",error);
        }

    }
    /* add contact*/
    export const addContact =async (sourceEmailAddress,input)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
             mutation addContact($sourceEmailAddress: AWSEmail!, $input: AddContactInput!) {
                addContact(sourceEmailAddress: $sourceEmailAddress, input: $input)
            }`,
                variables:{
                sourceEmailAddress,
                input}
                
        })
        console.log('response.data',response.data);
        return response.data;
        
        }catch(error){
            console.error("errror lors de ajout de nouveau contact  ",error);
        }
    }
    /*supression contat*/
    export const deleteContact =async (sourceEmailAddress,itemIds)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
            mutation deleteContacts($sourceEmailAddress: AWSEmail!, $itemIds: [String!]!) {
            deleteContacts(sourceEmailAddress: $sourceEmailAddress, itemIds: $itemIds)
        }`,
                variables:{
                            sourceEmailAddress,
                            itemIds
                        }
                
        })
        console.log('response.data',response.data);
        return response.data;
        
        }catch(error){
            console.error("errror lors de supression du contact ",error);
        }
    }

    /* Partie Agenda*/
    export const getEventsInCalendar= async(sourceEmailAddress,startDate)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
            `
           query getEventsInCalendar($sourceEmailAddress: AWSEmail!, $startDate: AWSDate!) {
                        getEventsInCalendar(sourceEmailAddress: $sourceEmailAddress, startDate: $startDate) {
                                items {
                                    itemId
                                    subject
                                    start
                                    end
                                    location
                                    attachments {
                                        name
                                        attachmentId
                                    }
                                    remindeIsSet
                                    reminderMinutesBeforeStart
                                    inviteAttendees {
                                        name
                                        email
                                    }
                                }
                        }
                    }`,
                variables:{
                            sourceEmailAddress,
                            startDate
                        }
                
        })
        console.log('response.data',response.data);
        return response.data;
        
        }catch(error){
            console.error("errror lors de recuperation  du events ",error);
        }
    }

    export const addEventsInCalendar= async(sourceEmailAddress,input)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
             `
            mutation addEventInCalendar($sourceEmailAddress: AWSEmail!, $input: AddEventInCalendarInput!) {
                addEventInCalendar(sourceEmailAddress: $sourceEmailAddress, input: $input)
            }`,
                variables:{
                            sourceEmailAddress,
                            input
                        }
                
        })
        console.log('response.data',response.data.data.addEventInCalendar);
        return response.data.data.addEventInCalendar;
        
        }catch(error){
            console.error("errror lors de l'ajout  du events ",error);
        }
    }
    export const deleteEventInCalendar= async(sourceEmailAddress,itemIds)=>{
        try{
            const response= await axiosInstance.post('/graphql',{
            query:
             `
             mutation deleteEventsInCalendar($sourceEmailAddress: AWSEmail!, $itemIds: [String!]!) {
                deleteEventsInCalendar(sourceEmailAddress: $sourceEmailAddress, itemIds: $itemIds)
            }`,
                variables:{
                            sourceEmailAddress,
                            itemIds
                        }
                
        })
        console.log('response.data',response.data);
        return response.data;
        
        }catch(error){
            console.error("errror lors de l'ajout  du events ",error);
        }
    }
    /*upoad files*/
    export const getAttachmentUpload = async (fileName) => {
        try {
          const response = await axiosInstance.post('/graphql', {
            query: `
              mutation getAttachmentUploadPreSignedUrl($fileName: String!) {
              getAttachmentUploadPreSignedUrl(fileName: $fileName) {
                      url
                      fields {
                          key
                          xAmzAlgorithm
                          xAmzCredential
                          xAmzDate
                          xAmzSecurityToken
                          policy
                          xAmzSignature
                      }
              }
            }
            `,
            variables: {
              fileName,
            },
          });
          return response.data.data.getAttachmentUploadPreSignedUrl;
        } catch (error) {
          console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
          throw error;
        }
      };
      export const uploadFilesToS3 = async (file) => {
        try {
          const data = await getAttachmentUpload(file.name);
          console.log("data", data);
      
          const creds = {
            url: data.url,
            key: data.fields.key,
            xAmzAlgorithm: data.fields.xAmzAlgorithm,
            xAmzCredential: data.fields.xAmzCredential,
            xAmzDate: data.fields.xAmzDate,
            xAmzSecurityToken: data.fields.xAmzSecurityToken,
            policy: data.fields.policy,
            xAmzSignature: data.fields.xAmzSignature,
          };
      
          const formData = new FormData();
          formData.append("key", creds['key']);
          formData.append("policy", creds['policy']);
          formData.append("x-amz-algorithm", creds['xAmzAlgorithm']);
          formData.append("x-amz-credential", creds['xAmzCredential']);
          formData.append("x-amz-date", creds['xAmzDate']);
          formData.append("x-amz-security-token", creds['xAmzSecurityToken']);
          formData.append("x-amz-signature", creds['xAmzSignature']);
          formData.append("file", file);
      
          const response = await axios.post(creds['url'], formData);
      
          if (response.status === 204) {
            console.log("Fichier chargé avec succès !");
            return data.url + creds['key'];  
          } else {
            throw new Error('Upload failed with status: ' + response.status);
          }
        } catch (error) {
          console.error("Erreur lors du chargement du fichier :", error);
          throw error;
        }
      };
      
      /* doawloands file*/
    export  const fetchDownloadAttachment = async (sourceEmailAddress, itemId, attachmentId) => {
        try {
          const response = await axiosInstance.post('/graphql', {
            query: `
              mutation getAttachmentDownloadPresignedUrl($sourceEmailAddress: AWSEmail!, $itemId: String!, $attachmentId: String!) {
                getAttachmentDownloadPresignedUrl(sourceEmailAddress: $sourceEmailAddress, itemId: $itemId, attachmentId: $attachmentId) {
                  url
                }
              }
            `,
            variables: { sourceEmailAddress, itemId, attachmentId },
          });
      
          const presignedUrlData = response?.data?.data?.getAttachmentDownloadPresignedUrl;
      
          if (!presignedUrlData) {
            console.error("La réponse de l'API est invalide :", response.data);
            throw new Error("URL pré-signée introuvable.");
          }
      
          return presignedUrlData.url;
        } catch (error) {
          console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
          throw error;
        }
      };
      

      
      export  const moveAttachmentToDMS = async (sourceEmailAddress,projectId, parentDocumentId,itemId, attachmentId) => {
        try {
          const response = await axiosInstance.post('/graphql', {
            query: `
             mutation moveAttachmentToDMS($sourceEmailAddress: AWSEmail!, $projectId: String!, $parentDocumentId: String!, $itemId: String!, $attachmentId: String!) {
                    moveAttachmentToDMS(sourceEmailAddress: $sourceEmailAddress, projectId: $projectId, parentDocumentId: $parentDocumentId, itemId: $itemId, attachmentId: $attachmentId)
                }
            `,
            variables: { sourceEmailAddress,projectId,parentDocumentId, itemId, attachmentId },
          });
      
          
      console.log("reponse serveur ",response?.data?.data?.moveAttachmentToDMS);
          return response?.data?.data?.moveAttachmentToDMS;
        } catch (error) {
          console.error("Erreur lors departage de attachements vers dms  :", error);
          throw error;
        }
      };