import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { generateS3PreSignedUploadUrl } from "../../../api/Services/webPackageService";
import { setProject } from '../../../redux/packageSlice';
import { Box, Typography} from "@mui/material";
import CustomButton from "../../../layouts/CustomButton";


const CandidateV3Area = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const imgLogo = useSelector((state) => state.package.projects?.[projectId].s3ImageKey || {});
  console.log("imgLogo",imgLogo);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadError(null); 
  };
 
  const uploadFileToS3 = async () => {
    try {
      const response = await generateS3PreSignedUploadUrl(projectId, file.name);
      console.log("Réponse de l'API pour l'URL pré-signée :", response);
  
      const { url, fields } = response;
      console.log("URL pré-signée :", url);
      console.log("Champs pré-signés :", fields);
  
      const fileData = new FormData();
      fileData.append("key", fields.key);
      fileData.append("policy", fields.policy);
      fileData.append("x-amz-algorithm", fields.xAmzAlgorithm);
      fileData.append("x-amz-credential", fields.xAmzCredential);
      fileData.append("x-amz-date", fields.xAmzDate);
      fileData.append("x-amz-security-token", fields.xAmzSecurityToken);
      fileData.append("x-amz-signature", fields.xAmzSignature);
      fileData.append("file", file);

      console.log("Données FormData envoyées dans la requête POST :");
      for (let pair of fileData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
              console.log("file", file.name);

      }
      console.log("Clé S3 stockée :", fields.key);

      const uploadResponse = await axios.post(url, fileData);
      console.log("Réponse du téléversement :", uploadResponse);
      dispatch(
                 setProject({
                          projectId,
                          data: {
                            s3ImageKey: fields.key,
                          },
                      })
                  );
      console.log("Clé S3 stockée :", fields.key);
    } catch (error) {
      console.error("Erreur lors de la génération de l'URL pré-signée ou du téléversement :", error);
      if (error.response) {
        console.error("Réponse de l'API :", error.response.data);
        console.error("Statut de l'API :", error.response.status);
      } else {
        console.error("Erreur réseau ou problème de connectivité", error);
      }
    } finally {
      navigate(`/package-com4N2/${projectId}`); 
    }
  };
  

  return (
    <>
      <div className="container">
      <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>

          <div className="row w-100">
            <div className="col-12">
              <h2 className=" text-center">
                CRÉATION PACKAGE COMMERCIAL
              </h2>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two ">
        <div className="container">
        <div className="d-flex mb-1">
                                            <Link to={`/package-com3/${projectId}`}
                                                    style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                                    className="d-flex justify-content-around">
                                                <Typography component="span" className="precedent-text">{"< Précédent"}</Typography>
                                            </Link>
                                            <Link to="#"
                                                    style={{margin:'10px auto',width: 'auto', paddingRight: '100px'}} 
                                                    className="d-flex justify-content-center">
                                
                                                <Typography component="span" className="d-flex justify-content-center text-center">4/15</Typography>
                                            </Link>
                                            <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                                        </div>
        
          <div className="title-one text-center mb-2 lg-mb-2

 ">
            <h3 className="fw-600">Chargez votre logo</h3>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="upload-btn position-relative d-flex align-items-center justify-content-between mb-25 ml-3">
                <span className="fw-500 text-dark me-1">Chargez</span>
                <i className="bi bi-file-earmark-arrow-up"></i>
                <input
                  type="file"
                  id="uploadCV"
                  name="uploadCV"
                  className="form-control"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {uploadError && (
              <div className="col-12 text-center text-danger mb-3">
                {uploadError}
              </div>
            )}
            {uploadMessage && (
              <div className="col-12 text-center text-success mb-3">
                {uploadMessage}
              </div>
            )}
            <Box mt={4}>
        <div className="col-12 text-center mb-4">
          <CustomButton
          
          onClick={uploadFileToS3} 
          disabled={isUploading || !file}
            sx={{
              minWidth:"114px",
              '&:disabled': {
                backgroundColor: '#b0b0b0',
                color: '#fff',
                cursor: 'not-allowed',
                opacity: 0.6,
              },
            }}
          >
           {isUploading ? "Téléversement en cours..." : "Continuez"}
          </CustomButton>
        </div>
      </Box>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;


