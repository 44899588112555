"use client";
import React from "react";
import { Link } from "react-router-dom"; 
import { FaCheckCircle } from 'react-icons/fa'; 
import CustomButton from "../../../layouts/CustomButton";

const CandidateV3Area = () => {
  return (
    <>

<div className="hero-banner-two position-relative">
                <div className="container">
                <div className="position-relative d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '1px' }}>

                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className=" text-center" >Choisir son statut</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <section className="blog-section-two ">
        <div className="container">
          <div className="d-flex mb-1">
              <Link to="/activite-statut-s3p1"
                                style={{margin:'10px auto',width: 'auto', paddingLeft: '65px'}} 
                                className="d-flex justify-content-around">
                            <span className="precedent-text">{"< Précédent"}</span>
                        </Link>
                        <Link to="#"
                                style={{margin:'10px auto',width: 'auto', paddingRight: '150px'}} 
                                className="d-flex justify-content-center">
            
                        </Link>
                        <a style={{margin:'10px auto',width: 'auto'}} className="d-flex justify-content-around"><span className="precedent-text">&nbsp;</span></a>
                    </div>
          <div className="text-center">
           
            <div className="title-one text-center mb-1 lg-mb-2

 ">
              <h3 className="fw-600">EURL</h3>
            </div>
          </div>

          <div className="row mt-5 justify-content-center">
            <div className="col-md-8">
              <div className="info-card text-center">
                <div className="info-icon">
                  <FaCheckCircle />
                </div>
                <h3>Informations complémentaires</h3>
                <p>Icon</p>
              </div>
            </div>
          </div>

          <div className="row mt-5 ">
            <div className="col-12 text-center mt-20">
            <CustomButton to="/create_soc" >
               Créer ma EURL
            </CustomButton >
            </div>
          </div>

          <br />
          <div className="title-one text-center ">
            <p className="fw-600">texte résumé du statut</p>
          </div>

          <div className="row ">
            <div className="col-12 text-center mt-18">
            <CustomButton to="/" >
            En savoir plus
            </CustomButton >
            </div>
           <div className="col-12 text-center mt-20 mb-4">
            <CustomButton to="/" >
            Faire une autre simulation
            </CustomButton >
             
            
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .info-card {
          background: #fff;
          padding: 40px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        .info-icon {
          font-size: 70px;
          color: #28a745;
          margin-bottom: 20px;
        }
        .info-card h3 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .info-card p {
          font-size: 20px;
          font-weight: 500;
        }
      `}</style>
    </>
  );
};

export default CandidateV3Area;
